import React from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import ProjectIntro from "../../Components/case-studies/project-intro";
import { crunchyrollData } from "../../../data/case-studies/data";
import Screenshots from "../../Components/Screenshots/screenshots";
import CompareSlider from "../../Components/carousel/comparisonCarousel";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import HomeServices4 from "../../Components/Services4/product-design2";

const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const gallery = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/01.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/02.png",
    },

    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/04.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/03.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/05.png",
    },

    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/07.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/08.png",
    },

    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/06.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/10.png",
    },
  ];
  const gallery2 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/1.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/2.png",
    },

    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/6.png",
    },

    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/9.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/10.png",
    },
    {
      alt: "11",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/10.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/5.png",
    },
  ];
  const gallery3 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/001.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/002.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/003.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/004.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/005.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/007.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/008.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/009.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/0091.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/0092.png",
    },
  ];

  const videoGallery = [
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
  ];

  const videoMobileGallery = [
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
  ];

  const team = [
    "3 Designers",
    <br key="team-br1" />,
    "2 PMs",
    <br key="team-br2" />,
    "3 Directors",
    <br key="team-br2" />,
    "5 Engineering",
    <br key="team-br2" />,
    "2 Marketing",
    <br key="team-br2" />,
    "2 Project Managers",
  ];

  const products = [
    "Accessible Design System /",
    <br key="team-br1" />,
    "tvOS Player /",
    <br key="team-br2" />,
    "Amazon FireTV /",
    <br key="team-br3" />,
    "SamsungTV /",
    <br key="team-br4" />,
    "GoogleTV /",
    <br key="team-br5" />,
    "Remote Hardware",
  ];

  const tools = [
    "Paper and Pencil",
    <br key="skills-br1" />,
    "Sketch",
    <br key="skills-br2" />,
    "Adobe CC",
    <br key="skills-br3" />,
    "Abstract",
    <br key="skills-br4" />,
    "Zeplin",
    <br key="skills-br5" />,
    "Miro",
    <br key="skills-br6" />,
    "Lottie",
  ];

  const skills = [
    "Research /",
    <br key="skills-br1" />,
    "Information Architecture /",
    <br key="skills-br2" />,
    "Wireframe / Prototyping /",
    <br key="skills-br3" />,
    "Visual Design /",
    <br key="skills-br4" />,
    "Interaction Design /",
    <br key="skills-br5" />,
    "Design Systems /",
    <br key="skills-br6" />,
    "Iconography / Illustrations /",
    <br key="skills-br7" />,
    "Motion Graphics",
  ];

  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/crunchy-roll/img-row/1.gif",
      title: "WHAT",
      content: [
        "M&A",
        <br key={`br-3-1`} />,
        "Unification",
        <br key={`br-3-2`} />,
        "Best Practices",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/crunchy-roll/img-row/2.gif",
      title: "WHO",
      content: [
        "New Leadership",
        <br key="br1-2" />,
        "Distributed Teams",
        <br key="br1-3" />,
        "Growth Culture",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/crunchy-roll/img-row/3.gif",
      title: "WHY",
      content: [
        "New tvOS Player",
        <br key="br2-1" />,
        "Greater Control",
        <br key="br2-2" />,
        "More Options",
      ],
    },
  ];

  // const threeBoxData2 = [
  //   {
  //     id: 3,

  //     title: "Best Portal Experience",
  //     content: [
  //       "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
  //     ],
  //   },
  //   {
  //     id: 1,

  //     title: "Best Innovative Team",
  //     content: [
  //       "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
  //     ],
  //   },
  //   {
  //     id: 2,

  //     title: "Five Star of Excellence",
  //     content: [
  //       "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
  //     ],
  //   },
  // ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "120M users",
      content: ["Enhanced user experience and engagement"],
    },
    {
      id: 1,

      title: "5.3%",
      content: ["Improved content discoverability with VUI"],
    },
    {
      id: 2,

      title: "Team of 3",
      content: ["Designed streaming apps and TV players"],
    },
  ];

  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        style={{
          backgroundColor: mode == true ? "black" : "black",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#f4f4f4"}
          projectHeaderData={ProjectDate}
          headerVideo={"/videos/case-studies/CRUNCHY%20ROLL/cruncyroll.mp4"}
          company="CRUNCHYROLL, INC."
          role="Senior Product Designer"
          tenure="2022"
          team={team}
          products={products}
          skills={skills}
          tools={tools}
          mode={mode}
        />
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <HomeServices4
          data={threeBoxData}
          cardColor={mode == true ? "#1b1d21" : "white"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#252532" : "#f4f4f4"}
        />

        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#252532" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Voice UI Visual Designs"
          gallery={gallery}
          size="1000px"
        />
        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Voice UI Wireframes"
          gallery={gallery2}
          size="1000px"
        />

        <CompareSlider
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#252532" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Delightful Enchancements"
          img1="/assets/case-studies/case-studies/crunchy-roll/gallery/1/before.png"
          img2="/assets/case-studies/case-studies/crunchy-roll/gallery/1/after.png"
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/CRUNCHY%20ROLL/crunchyroll-wall.mp4"
        />

        {crunchyrollData.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#252532" : "white"}
            color2={mode == true ? "#1b1d21" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            image={item.image}
            image2={item.image2 ? item.image2 : null}
            imageCaption={item.caption}
            video={item.video}
          />
        ))}

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

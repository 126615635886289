export const videoSrc = [{ videoSrc: "/videos/case-studies/vmw-1.mp4" }];

export const twoColumnVideos = [
  {
    src: "https://www.dropbox.com/scl/fi/xzyc35gxef7nr62354tdd/zabihah-promo2.mp4?rlkey=i4p2tc9kuz152v4k6hw3m68ff&st=ici8fg27&dl=0",
  },
  {
    src: "https://www.dropbox.com/scl/fi/uz69mcdaptern1m7mxma9/ramadan-zabihah-2.mp4?rlkey=zotnphbd42u0wrschac8i2x1r&st=cgcwx88b&dl=0",
  },
];

export const threeColumnVideos = [
  { src: "/videos/vertical-video-1.mp4" },
  { src: "/videos/vertical-video-2.mp4" },
  { src: "/videos/vertical-video-3.mp4" },
];

export const verticalVideoGallery = [
  { src: "/videos/vertical-gallery-1.mp4" },
  { src: "/videos/vertical-gallery-2.mp4" },
  { src: "/videos/vertical-gallery-3.mp4" },
  { src: "/videos/vertical-gallery-4.mp4" },
  { src: "/videos/vertical-gallery-5.mp4" },
];

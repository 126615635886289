import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const handleDragStart = (e) => e.preventDefault();

const VideoCarousel = ({
  items,
  size,
  heading,
  subheading,
  color,
  textColor,
  mode, // Add this prop`
  bgcolor,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (src) => {
    setCurrentImage(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const styles = {
    body: {
      paddingTop: "120px",
      paddingBottom: "120px",
      backgroundColor: bgcolor ? bgcolor : mode ? "#1b1d21" : "#F4F4F4",
    },
    container: {
      width: "100%",
      maxWidth: "1110px",
      margin: "0 auto",
      position: "relative",
      padding: "0 20px",
    },
    carousel: {
      width: "100%",
      height: "auto",
      marginTop: "30px",
    },
    img: {
      width: "100%",
      height: "100%",
      margin: "0 20px",
      cursor: "pointer",
    },
    prevBtn: {
      position: "absolute",
      top: "40%",
      transform: "translateY(-50%)",
      padding: "10px",
      cursor: "pointer",
      fontSize: "24px",
      border: "none",
      outline: "none",
      zIndex: 1,
      background: "transparent",
      color: "#1fc1f2", // Changed to #1fc1f2
      transition: "background-color 0.3s ease",
      left: "-50px",
    },
    nextBtn: {
      position: "absolute",
      top: "40%",
      transform: "translateY(-50%)",
      padding: "10px",
      cursor: "pointer",
      fontSize: "24px",
      border: "none",
      outline: "none",
      zIndex: 1,
      background: "transparent",
      color: "#1fc1f2", // Changed to #1fc1f2
      transition: "background-color 0.3s ease",
      right: "-50px",
    },
    dots: {
      position: "absolute",
      bottom: "110%",
      left: "50%",
      transform: "translateX(-50%)",
      listStyle: "none",
      padding: 0,
      textAlign: "center",
      zIndex: 1,
    },
    dotsItem: {
      display: "inline-block",
      margin: "0 5px",
      cursor: "pointer",
      fontSize: "14px",
      transition: "opacity 0.3s ease",
      color: "#ff6d90", // Add this line to change the dot color
    },
    dotsItemActive: {
      opacity: 0.8,
    },
    dotsItemHover: {
      opacity: 0.8,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      zIndex: 1000,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    modalContent: {
      display: "block",
      width: "100%",
      maxWidth: "1200px",
    },
    close: {
      position: "absolute",
      top: "15px",
      right: "35px",
      color: "#fff",
      fontSize: "40px",
      fontWeight: "bold",
      transition: "0.3s",
    },
    closeHover: {
      color: "#bbb",
      textDecoration: "none",
      cursor: "pointer",
    },
    // Media queries for responsiveness
    "@media (max-width: 768px)": {
      container: {
        padding: "0 15px",
      },
      img: {
        margin: "0 10px",
        height: "auto",
      },
      prevBtn: {
        left: "-30px",
      },
      nextBtn: {
        right: "-30px",
      },
      close: {
        fontSize: "30px",
        top: "10px",
        right: "20px",
      },
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10">
            <div className="sec-head text-center">
              <h6
                style={{
                  color: "#FF6690",
                  textTransform: "lowercase",
                  fontSize: "20px",
                  fontWeight: "500",
                  fontFamily: "machina",
                }}
                className="wow fadeIn"
                data-wow-delay=".5s"
              >
                {heading ? `{ ${heading} }` : "{ deliverables }"}
              </h6>
              <h3
                style={{ fontFamily: "machina bold", color: textColor }}
                className="wow "
              >
                {subheading ? ` ${subheading} ` : "Customer Onboarding"}
              </h3>
            </div>
          </div>
        </div>
        <br />
        <br />

        <AliceCarousel
          mouseTracking
          items={items?.map((item) => (
            <div key={item.src} style={{ padding: "0 20px" }}>
              <video
                src={item.src}
                alt={item.alt}
                onDragStart={handleDragStart}
                role="presentation"
                onClick={() => openModal(item.src)}
                style={styles.img}
                controls
                autoPlay
                muted
                loop
              />
            </div>
          ))}
          autoPlay
          autoPlayInterval={3000}
          animationDuration={1000}
          disableButtonsControls={false}
          infinite
          disableDotsControls={false}
          responsive={{
            0: { items: 1 },
            768: { items: 1 },
          }}
          renderPrevButton={() => (
            <div style={styles.prevBtn}>
              <i className="fa-solid fa-arrow-left"></i>{" "}
            </div>
          )}
          renderNextButton={() => (
            <div style={styles.nextBtn}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          )}
          renderDotsItem={({ isActive }) => (
            <span
              style={{
                ...styles.dotsItem,
                opacity: isActive ? 1 : 0.5,
              }}
            >
              ●
            </span>
          )}
        />
        {isModalOpen && (
          <div className="modal" onClick={closeModal} style={styles.modal}>
            <span className="close" onClick={closeModal} style={styles.close}>
              &times;
            </span>
            <img
              className="modal-content"
              src={currentImage}
              alt="Preview"
              style={styles.modalContent}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCarousel;

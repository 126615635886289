import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "./carousel.css"; // Import our custom CSS
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome

const handleDragStart = (e) => e.preventDefault({});

const Carousel = ({ items, size }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const openModal = (src) => {
    setCurrentImage(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const styles = {
    body: {
      // backgroundColor: "cadetblue",
    },
    container: {
      width: "100%",
      margin: "0 auto",
      position: "relative",
      padding: "0 30px",
    },
    carousel: {
      width: "100%",
      height: "300px",
      marginTop: "30px",
    },
    img: {
      width: "100%",
      height: "100%",
      cursor: "pointer",
    },
    imgContainer: {
      padding: "0 5px", // Adjust padding to create space between images
    },
    prevBtn: {
      position: "absolute",
      top: "40%",
      transform: "translateY(-50%)",
      padding: "10px",
      cursor: "pointer",
      fontSize: "24px",
      border: "none",
      outline: "none",
      zIndex: 1,
      background: "transparent",
      color: "#1fc1f2",
      transition: "background-color 0.3s ease",
      left: "-50px",
    },
    nextBtn: {
      position: "absolute",
      top: "40%",
      transform: "translateY(-50%)",
      padding: "10px",
      cursor: "pointer",
      fontSize: "24px",
      border: "none",
      outline: "none",
      zIndex: 1,
      background: "transparent",
      color: "#1fc1f2",
      transition: "background-color 0.3s ease",
      right: "-50px",
    },
    dots: {
      position: "absolute",
      bottom: "110%",
      left: "50%",
      transform: "translateX(-50%)",
      listStyle: "none",
      padding: 0,
      textAlign: "center",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
    },
    dotsItem: {
      display: "inline-block",
      margin: "0 4px",
      cursor: "pointer",
      width: "6px",
      height: "6px",
      borderRadius: "50%",
      backgroundColor: "#FF6D90",
      transition: "opacity 0.3s ease",
    },
    dotsItemActive: {
      opacity: 1,
    },
    dotsItemInactive: {
      opacity: 0.5,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      zIndex: 1000,
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    modalContent: {
      display: "block",
      width: "100%",
      maxWidth: "1200px",
    },
    close: {
      position: "absolute",
      top: "15px",
      right: "35px",
      color: "#fff",
      fontSize: "40px",
      fontWeight: "bold",
      transition: "0.3s",
    },
    closeHover: {
      color: "#bbb",
      textDecoration: "none",
      cursor: "pointer",
    },
  };

  const renderDotsItem = ({ isActive }) => {
    return (
      <span
        style={{
          ...styles.dotsItem,
          ...(isActive ? styles.dotsItemActive : styles.dotsItemInactive),
        }}
      />
    );
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <AliceCarousel
          mouseTracking
          items={items?.map((item) => (
            <div key={item.src} style={styles.imgContainer}>
              <img
                src={item.src}
                alt={item.alt}
                onDragStart={handleDragStart}
                role="presentation"
                // onClick={() => openModal(item.src)}
                style={styles.img}
              />
            </div>
          ))}
          autoPlay
          autoPlayInterval={3000}
          animationDuration={1000}
          disableButtonsControls={false}
          infinite
          disableDotsControls={false}
          responsive={{
            0: { items: 1 },
            768: { items: 1 },
          }}
          renderPrevButton={() => (
            <div style={styles.prevBtn}>
              <i className="fa-solid fa-arrow-left"></i>{" "}
            </div>
          )}
          renderNextButton={() => (
            <div style={styles.nextBtn}>
              <i className="fa-solid fa-arrow-right"></i>
            </div>
          )}
          renderDotsItem={renderDotsItem}
        />
        {isModalOpen && (
          <div className="modal" onClick={closeModal} style={styles.modal}>
            <span className="close" onClick={closeModal} style={styles.close}>
              &times;
            </span>
            <img
              className="modal-content"
              src={currentImage}
              alt="Preview"
              style={styles.modalContent}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Carousel;

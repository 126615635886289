import React from "react";

const ProjectIntroduction = ({ projectIntroductionData, textColor, theme }) => {
  return (
    <section
      style={{ paddingTop: "120px" }}
      className="intro-section section-padding"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {projectIntroductionData.title && (
              <div className="htit text-center">
                <h4
                  style={{
                    fontSize: "100px",
                    fontWeight: "100",
                    lineHeight: "1.0em",
                    textTransform: "capitalize",
                    fontFamily: "machina",
                  }}
                  className="color-font nomobile"
                >
                  {projectIntroductionData.title}
                </h4>
                <h4
                  style={{
                    fontSize: "45px",
                    fontWeight: "900",
                    overflowWrap:
                      "break-word" /* Ensure long words break to the next line */,
                    wordWrap: "break-word" /* Older browsers support */,
                    lineHeight: "1.25em",
                    textAlign: "center",
                    textTransform: "capitalize",
                    fontFamily: "machina",
                  }}
                  className="color-font nodesktop"
                >
                  {projectIntroductionData.title}
                </h4>
                <br />
              </div>
            )}
            <div
              style={{ marginTop: "60px" }}
              className="text js-scroll__content"
            >
              <p
                style={{
                  fontWeight: 300,
                  color: textColor,
                  fontSize: "22px",
                  lineHeight: "2.0em",
                }}
                className="extra-text nomobile"
              >
                {projectIntroductionData.content}
              </p>
              <p
                style={{
                  fontWeight: 300,
                  color: textColor,
                  fontSize: "22px",
                  lineHeight: "2.0em",
                }}
                className="extra-text nodesktop"
              >
                {projectIntroductionData.content}
              </p>
              {projectIntroductionData.spmList && (
                <>
                  <ul
                    style={{ marginLeft: "64px" }}
                    className="smp-list mt-60 nomobile"
                  >
                    {projectIntroductionData.spmList.map((item) => (
                      <li
                        className="custom-bullet-item"
                        style={{
                          fontWeight: 300,
                          color: textColor,
                          lineHeight: "2.0em",
                          listStyleType: "none",
                          fontSize: "22px",
                        }}
                        key={item.id}
                      >
                        <span style={{ fontWeight: 600, color: textColor }}>
                          {item.start}
                        </span>{" "}
                        <span
                          style={{
                            fontWeight: 400,
                            color: theme === "dark" ? "#6a6a6a" : "#999",
                          }}
                        >
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <ul
                    style={{ marginLeft: "32px" }}
                    className="smp-list mt-60 nodesktop"
                  >
                    {projectIntroductionData.spmList.map((item) => (
                      <li
                        className="custom-bullet-item"
                        style={{
                          fontWeight: 300,
                          color: textColor,
                          lineHeight: "2.0em",
                          listStyleType: "none",
                          fontSize: "22px",
                        }}
                        key={item.id}
                      >
                        <span style={{ fontWeight: 600, color: textColor }}>
                          {item.start}
                        </span>{" "}
                        <span
                          style={{
                            fontWeight: 400,
                            color: theme === "dark" ? "#6a6a6a" : "#999",
                          }}
                        >
                          {item.name}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
          .custom-bullet-item::before {
            content: '↳'; /* Custom bullet character */
            color: #FF6690; /* Custom bullet color */
            font-weight: bold; /* Custom bullet weight */
            display: inline-block; 
            width: 1.5em; /* Increase space between bullet and text */
            margin-left: -1.5em; /* Adjust position */
          }
        `}
      </style>
    </section>
  );
};

export default ProjectIntroduction;

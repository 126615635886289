import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome

const MobileCarousel = ({ items, heading, subheading, color, textColor }) => {
  const [slidePercentage, setSlidePercentage] = useState(33.33);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSlidePercentage(mobile ? 100 : 33.33);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const renderCarouselItem = (item) => {
    const itemContent =
      item.type === "img" ? (
        <img
          key={item.src}
          src={item.src}
          alt={item.alt}
          style={styles.carouselItem}
        />
      ) : (
        <video
          key={item.src}
          src={item.src}
          alt={item.alt}
          style={styles.carouselItem}
          controls
          autoPlay
          muted
        />
      );

    return (
      <div style={styles.carouselItemWrapper} key={item.src}>
        {itemContent}
      </div>
    );
  };

  const styles = {
    body: {
      paddingTop: "120px",
      paddingBottom: "120px",
      backgroundColor: `${color}`,
      position: "relative", // Add this
    },
    container: {
      width: "100%",
      margin: "0 auto",
      position: "relative",
      padding: "0 15px",
      maxWidth: "1110px",
    },
    carouselItemWrapper: {
      padding: "10px", // Add padding to separate items
      boxSizing: "border-box", // Ensure padding is included in the width
    },
    carouselItem: {
      width: "100%",
      height: "auto",
      cursor: "pointer",
    },
    carouselWrapper: {
      position: "relative",
      zIndex: 1,
    },
    arrowButton: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1000,
      background: "transparent",
      border: "none",
      color: "#1fc1f2",
      fontSize: "30px",
      cursor: "pointer",
      padding: "10px",
      outline: "none", // Add this line to remove the default outline
    },
    prevBtn: {
      left: isMobile ? "-70px" : "-50px",
    },
    nextBtn: {
      right: isMobile ? "-70px" : "-50px",
    },
    // paginationContainer: {
    //   marginTop: "20px", // Add this new style for the pagination container
    // },
    paginationContainer: {
      position: "absolute",
      bottom: "-30px",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "center",
      zIndex: 1000,
    },
    paginationDot: {
      backgroundColor: "#FF6D90",
      cursor: "pointer",
      width: "6px", // Reduced from 10px to 6px
      height: "6px", // Reduced from 10px to 6px
      borderRadius: "50%",
      display: "inline-block",
      margin: "0 4px", // Slightly reduced margin
    },
  };

  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10">
            <div className="sec-head text-center nomobile">
              <h6
                style={{
                  color: "#FF6690",
                  textTransform: "lowercase",
                  fontSize: "20px",
                  fontWeight: "500",
                  fontFamily: "machina",
                }}
                className="wow fadeIn"
                data-wow-delay=".5s"
              >
                {heading ? `{ ${heading} }` : " { DELIVERABLES }"}
              </h6>
              <h3
                style={{ fontFamily: "machina bold", color: textColor }}
                className="wow "
              >
                {subheading ? subheading : "Email Campaigns and Templates"}
              </h3>
            </div>
            <div
              style={{ marginBottom: "80px" }}
              className="sec-head text-center nodesktop"
            >
              <h6
                style={{
                  color: "#FF6690",
                  textTransform: "lowercase",
                  fontSize: "20px",
                  fontWeight: "500",
                  fontFamily: "machina",
                }}
                className="wow fadeIn"
                data-wow-delay=".5s"
              >
                {heading ? `{ ${heading} }` : " { DELIVERABLES }"}
              </h6>
              <h3
                style={{ fontFamily: "machina bold", color: "#a4a7b1" }}
                className="wow "
              >
                {subheading ? subheading : "Email Campaigns and Templates"}
              </h3>
            </div>
          </div>
        </div>
        <div style={styles.carouselWrapper}>
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            interval={3000}
            transitionTime={1000}
            centerMode
            centerSlidePercentage={slidePercentage}
            renderArrowPrev={() => null}
            renderArrowNext={() => null}
            renderIndicator={() => null}
            onChange={(index) => setCurrentSlide(index)}
            selectedItem={currentSlide}
          >
            {items?.map(renderCarouselItem)}
          </Carousel>
          <div style={styles.paginationContainer}>
            {items?.map((_, index) => (
              <span
                key={index}
                style={{
                  ...styles.paginationDot,
                  opacity: index === currentSlide ? 1 : 0.5,
                }}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </div>
          <button
            type="button"
            onClick={() =>
              setCurrentSlide(
                (prev) => (prev - 1 + items.length) % items.length
              )
            }
            style={{ ...styles.arrowButton, ...styles.prevBtn }}
            className="focus-visible-outline" // Add this class
          >
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <button
            type="button"
            onClick={() => setCurrentSlide((prev) => (prev + 1) % items.length)}
            style={{ ...styles.arrowButton, ...styles.nextBtn }}
            className="focus-visible-outline" // Add this class
          >
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileCarousel;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const DarkTheme = ({ children, useSkin, mobileappstyle }) => {
  useEffect(() => {
    window.theme = "dark";
  }, []);

  return (
    <>
      <Helmet>
        {/* Use absolute paths for assets in the public folder */}
        <link rel="stylesheet" href="/css/dark.css" />
        {useSkin && <link rel="stylesheet" href="/css/arch-skin-dark.css" />}
        {mobileappstyle && (
          <link rel="stylesheet" href="/css/mobile-app-dark.css" />
        )}
      </Helmet>
      {children}
    </>
  );
};

export default DarkTheme;

export const VideoData = [
  {
    intro: {
      listNumber: "01",
      title:
        "Comprehensive Product Storytelling with Visual Excellence", // Updated title
      content: (
        <>
         I create powerful visual narratives that simplify complex products and resonate with diverse audiences. By combining product explainer videos with stunning UI showcases, I bring concepts to life through clear and compelling visuals. My approach to product concept videos integrates innovative thinking with engaging storytelling, making sure every detail aligns with the brand’s vision. For product marketing videos, I blend creativity with strategic messaging, ensuring that the product stands out and connects emotionally with potential customers, driving engagement and conversions. By merging these four elements—explainer videos, UI design, concept visualization, and marketing—I ensure that your product communicates its value, functionality, and benefits effectively, transforming viewers into loyal users.







          
        </>
      ),
      
    },
  },
];
export const LottieData = [
  {
    intro: {
      listNumber: "01",
      title:
        "Elevating User Experiences with Lottie Animations and Motion Design", // Updated title
      content: (
        <>
          I specialize in transforming digital interfaces into captivating experiences that delight users at every touchpoint by leveraging the power of Lottie animations, advanced motion design, and strategic UX enhancements to bring digital products to life.
        </>
      ),
      
    },
  },
];

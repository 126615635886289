import React, { useState, useEffect, useCallback, memo, Suspense } from "react";
import { Typography, Box, Grid, useMediaQuery, useTheme } from "@mui/material";

const Video2 = React.lazy(() => import("./video2.js"));

// Separate styles object
const styles = {
  mainTitle: (isMobile) => ({
    fontSize: isMobile ? "1.8em" : "55px",
    fontFamily: "machina bold",
    marginTop: isMobile ? "" : "50px",
    lineHeight: "1",
    background: "linear-gradient(90deg, #FF6690 0%, #00CDFF 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    display: "inline-block",
    animation: "fadeInUp 0.8s ease-out forwards",
    opacity: 0,
  }),
  subtitle: (isMobile) => ({
    mb: 1,
    padding: isMobile ? "10px 0px" : "10px",
    fontSize: isMobile ? "20px" : "33px",
    lineHeight: isMobile ? "1.2" : "1",
    fontFamily: "machina",
    marginBottom: "20px",
    color: "#A4A7B1",
    animation: "fadeInUp 0.8s ease-out forwards",
    animationDelay: "0.3s",
    opacity: 0,
  }),
  description: (isMobile, mode) => ({
    mb: 4,
    color: mode ? "white" : "#4a4a4a",
    fontFamily: "Montserrat",
    textAlign: "center",
    width: isMobile ? "100%" : "45%",
    margin: "0 auto",
    lineHeight: "1.5em",
    fontSize: isMobile ? "0.9rem" : "20px",
    animation: "fadeInUp 0.8s ease-out forwards",
    animationDelay: "0.6s",
    opacity: 0,
  }),
  videoContainer: (isMobile, perspective) => ({
    borderRadius: "15px 15px 0 0",
    padding: "0px",
    marginBottom: "0px",
    textAlign: "center",
    width: isMobile ? "100%" : "65%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: perspective,
    transition: "transform 0.8s ease-out",
    zIndex: "999",
  }),
};

// Animations defined once
const animations = `
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const IntroCopy = memo(({ mode }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const videoSrc = "/videos/javed.io-demo.mp4";

  const calculatePerspective = useCallback(() => {
    const maxScroll = 10;
    const scrollPercentage = Math.min(scrollY / maxScroll, 1);
    const rotateX = 10 * (1 - scrollPercentage);
    return `perspective(300px) rotateX(${rotateX}deg)`;
  }, [scrollY]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 }
    );

    const currentElement = document.getElementById("intro-section");
    if (currentElement) {
      observer.observe(currentElement);
      return () => observer.unobserve(currentElement);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        setScrollY(window.scrollY);
      });
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      id="intro-section"
      sx={{
        backgroundColor: mode === true ? "black" : "white",
        textAlign: "center",
        padding: isMobile ? "30px 20px 0px 20px" : "60px 60px 0px 20px",
      }}
    >
      <style>{animations}</style>
      <div style={{ height: "5rem" }} />

      <div>
        <Typography component="h1" sx={styles.mainTitle(isMobile)}>
          Product Designer . Software Developer
        </Typography>

        <Typography
          variant="subtitle1"
          component="p"
          sx={styles.subtitle(isMobile)}
        >
          B2B Vertical SaaS . Web + Mobile UI / UX . Emotive Branding
        </Typography>

        <Typography variant="subtitle1" sx={styles.description(isMobile, mode)}>
          Over 20 years of experience building human-centered products and
          leading high-performing design and engineering teams
        </Typography>

        <div style={{ height: "2rem" }} />

        <Box sx={styles.videoContainer(isMobile, calculatePerspective())}>
          <Grid
            container
            spacing={0}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              animation: "fadeInUp 1s ease-out forwards",
              opacity: 0,
              animationDelay: "0.9s",
            }}
          >
            <Video2
              videoHeight="100%"
              modalStyle={{ marginBottom: "-7px" }}
              videoSrc={videoSrc}
            />
          </Grid>
        </Box>
      </div>
    </Box>
  );
});

IntroCopy.displayName = "IntroCopy";

export default IntroCopy;

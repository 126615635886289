export default function mouseEffect() {
  function mousecursor() {
    const cursorInner = document.querySelector(".cursor-inner"),
      cursorOuter = document.querySelector(".cursor-outer");

    // Initially hide cursors
    if (cursorInner) cursorInner.style.visibility = "hidden";
    if (cursorOuter) cursorOuter.style.visibility = "hidden";

    let n,
      i = 0,
      o = false;

    window.onmousemove = function (e) {
      // Show cursors on first mouse movement
      if (cursorInner) cursorInner.style.visibility = "visible";
      if (cursorOuter) cursorOuter.style.visibility = "visible";

      o ||
        (cursorOuter.style.transform =
          "translate(" + e.clientX + "px, " + e.clientY + "px)");
      cursorInner.style.transform =
        "translate(" + e.clientX + "px, " + e.clientY + "px)";
      n = e.clientY;
      i = e.clientX;
    };

    if (document.querySelector(".cursor-pointer")) {
      document.querySelectorAll(".cursor-pointer").forEach(function (item) {
        item.addEventListener("mouseenter", function () {
          cursorInner.classList.add("cursor-hover");
          cursorOuter.classList.add("cursor-hover");
          this.style.cursor = "none"; // Remove default cursor
        });

        item.addEventListener("mouseleave", function () {
          cursorInner.classList.remove("cursor-hover");
          cursorOuter.classList.remove("cursor-hover");
          this.style.cursor = ""; // Reset cursor to default
        });
      });
    }

    // Add this new section for case study cards
    document.querySelectorAll(".item-img.item-imgmin").forEach((item) => {
      item.addEventListener("mouseenter", function () {
        cursorInner.classList.add("cursor-hover");
        cursorOuter.classList.add("cursor-hover");
        this.style.cursor = "none"; // Remove default cursor
      });

      item.addEventListener("mouseleave", function () {
        cursorInner.classList.remove("cursor-hover");
        cursorOuter.classList.remove("cursor-hover");
        this.style.cursor = ""; // Reset cursor to default
      });
    });
  }

  mousecursor();
}

import React, { useState, useRef, useEffect, Suspense, lazy } from "react";
import Navbar from "../Components/Navbar/navbar";
import Services3 from "../Components/Services3/services3";
import AffirmServices from "../Components/Services4/affservices";
import CallToAction from "../Components/Call-to-action/call-to-action";
import DarkTheme from "../layouts/Dark";
import IntroCopy from "../Components/Intro2/HomepageCopy";
import Cursor from "../Components/cursor";
import Footer from "../Components/Footer/footer";
import ScrollToTop from "../Components/scrollToTop";

const CaseStudy = lazy(() => import("../Components/Works-style4/affirm"));
const Testimonials = lazy(() =>
  import("../Components/Full-testimonials/full-testimonials")
);
const Clients = lazy(() => import("../Components/Clients3/clients3"));

const HomeCopyPage = () => {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const [mode, setMode] = useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <DarkTheme>
      <div
        style={{
          backgroundColor: mode ? "black" : "white",
          minHeight: "100vh",
          margin: "0px",
          overflowX: "hidden",
        }}
      >
        <ScrollToTop />
        <Cursor />
        <Navbar
          dark={handleTheme}
          nr={navbarRef}
          lr={logoRef}
          isHomePage={true}
        />
        <IntroCopy mode={mode} />
        <CaseStudy mode={mode} />
        <Services3 mode={mode} />
        <AffirmServices mode={mode} />
        <Clients mode={mode} />
        <Testimonials mode={mode} />
        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default HomeCopyPage;

import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
const Footer = ({ hideBGCOLOR }) => {
  return (
    <footer className={`${!hideBGCOLOR ? "sub-bg" : ""} hide`}>
      <div className="container">
        <div className="row hide">
          <div className="col-6 col-lg-3 col-md-6">
            <div className="item md-mb50">
              <div className="title">
                <h6>Info</h6>
              </div>
              <ul className="mb-25">
                <li>
                  <Link to="/about">
                    <a>
                      <p>About Me</p>
                    </a>
                  </Link>
                </li>
                {/* <li>                                     
                    <Link href="/agency-ethos">
                      <a>
                        <p>
                          Design Ethos
                        </p>
                      </a>
                    </Link> 
                </li> */}
                <li>
                  <Link href="/industry-honors">
                    <a>
                      <p>Industry Honors</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/minority-founder">
                    <a>
                      <p>Minority Founder</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/technology-partners">
                    <a>
                      <p>Technology Partners</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/design-for-good">
                    <a>
                      <p>Design For Good</p>
                    </a>
                  </Link>
                </li>
              </ul>
              <div className="title hide">
                <h6>Services</h6>
              </div>
              <ul className="hide">
                <li>
                  <Link href="/ai-integration">
                    <a>
                      <p>AI Integration</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/software-development">
                    <a>
                      <p>Software Development</p>
                    </a>
                  </Link>
                </li>

                <li>
                  <Link href="/product-design">
                    <a>
                      <p>Product Design</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/content-marketing">
                    <a>
                      <p>Content Marketing</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/web-3-0">
                    <a>
                      <p>Web 3.0</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/zero-to-one">
                    <a>
                      <p>Zero To One</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/world-class-talent">
                    <a>
                      <p>World-class Talent</p>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="item md-mb50">
              <div className="title">
                <h6>Blog</h6>
              </div>
              <ul>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>Design</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>Engineering</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>Motion</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>Stories</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>DEI</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="https://xventures.blog/">
                    <a target="_blank">
                      <p>ADA</p>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="item md-mb50">
              <div className="title">
                <h6>Portfolio</h6>
              </div>
              <ul>
                <li>
                  <Link href="/case-studies">
                    <a>
                      <p>Case Studies</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/fortune500">
                    <a>
                      <p>Fortune 500</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/startup">
                    <a>
                      <p>Start Up</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/agencies">
                    <a>
                      <p>Agency</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/non-profit">
                    <a>
                      <p>Non-Profit</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/web3.0">
                    <a>
                      <p>Web 3.0</p>
                    </a>
                  </Link>
                </li>
                <li className="hide">
                  <Link href="/hackthons">
                    <a>
                      <p>Hackthon</p>
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/dei">
                    <a>
                      <p>DEI Initiatives</p>
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-6 col-lg-3 col-md-6">
            <div className="item">
              <div className="item md-mb50">
                <ul>
                  <li className="mb-25">
                    <div className="cont">
                      <h6>Contact</h6>
                      <p className="mt-10">
                        Irvine, California
                        <br />
                        (949) 379-9266
                      </p>
                      <Link href={`https://calendly.com/xdstudios-io/15min`}>
                        <a className="btn_13 small gradient pulse_bt book_a_meeting text-white">
                          Hire Me
                        </a>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center"> */}
        {/* <div className="col-12 col-lg-12 col-md-12 text-center"> */}
        {/* <div className="item">
              <h1 className="urbantxt">JAVED A.</h1>
            </div> */}
        {/* <div className="item">
              <img src={appData.lightLogo} className="bottomLogo" alt="logo" />
            </div> */}
        {/* </div> */}
        {/* </div> */}
      </div>
    </footer>
  );
};
export default Footer;

import React, { useEffect, useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper";
import { Navigation, Pagination, Parallax } from "swiper/modules";
import ReactCompareImage from "react-compare-image";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/parallax";

SwiperCore.use([Navigation, Pagination, Parallax]);

const CompareSlider = ({
  heading,
  subheading,
  color,
  img1,
  img2,
  textColor,
}) => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
  }, [load]);
  const paginationRef = React.useRef(null);
  return (
    <section
      style={{ backgroundColor: color ? color : "#1b1d21", padding: "120px 0" }}
      className="secreen-shots section-padding"
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-10">
            <div className="sec-head text-center nomobile">
              <h6
                style={{
                  fontFamily: "machina",
                  color: "#FF6690",
                  textTransform: "lowercase",
                  fontSize: "20px",
                  fontWeight: "500",
                }}
                className="wow fadeIn"
                data-wow-delay=".5s"
              >
                {heading ? `{ ${heading} }` : "{ test }"}
              </h6>
              <h3
                style={{ fontFamily: "machina bold", color: textColor }}
                className="wow "
              >
                {subheading ? subheading : "test2"}
              </h3>
            </div>
            <div
              style={{ marginBottom: "80px" }}
              className="sec-head text-center nodesktop"
            >
              <h6
                style={{
                  fontFamily: "machina",
                  color: "#FF6690",
                  textTransform: "lowercase",
                  fontSize: "20px",
                  fontWeight: "500",
                }}
                className="wow fadeIn"
                data-wow-delay=".5s"
              >
                {heading ? `{ ${heading} }` : "{ test }"}
              </h6>
              <h3
                style={{ fontFamily: "machina bold", color: "#4a4a4a" }}
                className="wow "
              >
                {subheading ? subheading : "test2"}
              </h3>
            </div>
          </div>
        </div>

        <ReactCompareImage leftImage={img1} rightImage={img2} />
      </div>
    </section>
  );
};

export default CompareSlider;

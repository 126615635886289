import React, { useEffect, useState, memo } from "react";
import UnifiedStyles from "../home/unifiedstyles";

// Animation styles
const fadeInAnimation = `
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

// Styles object
const styles = {
  container: (isMobile) => ({
    padding: "30px 30px",
    marginLeft: isMobile ? "22px" : "0px",
    width: isMobile ? "82vw" : "auto",
  }),
  heading: {
    textAlign: "center",
    fontFamily: "machina",
  },
  description: {
    lineHeight: "1.7em",
    fontSize: "19px",
    textTransform: "capitalize",
    textAlign: "center",
  },
  animatedItem: (delay) => ({
    opacity: 0,
    animation: `fadeInUp 0.8s ease-out forwards ${delay}s`,
  }),
};

// Awards data
const awards = [
  {
    icon: "/assets/honors/13.png",
    title: "USER EXPERIENCE",
    organization: "The Webby Awards",
    className: "col-lg-2 col-md-6 items active md-mb30",
  },
  {
    icon: "/assets/honors/11.png",
    title: "CUSTOMER EXPERIENCE",
    organization: "The channel company",
    className: "col-lg-2 col-md-6 items sm-mb30",
  },
  {
    icon: "/assets/honors/15.png",
    title: "DIVERSITY, EQUITY, INCLUSION",
    organization: "The anthem awards",
    className: "col-lg-2 col-md-6 items md-mb30",
  },
  {
    icon: "/assets/honors/5.png",
    title: "DIGITAL NEWSROOM",
    organization: "Radio television digital news assosiation",
    className: "col-lg-2 col-md-6 items",
  },
  {
    icon: "/assets/honors/6.png",
    title: "AMERICAN JOURNALISM",
    organization: "National press foundation",
    className: "col-lg-2 col-md-6 items",
  },
  {
    icon: "/assets/honors/2.png",
    title: "GLOBAL ECONOMY",
    organization: "World islamic economic forum",
    className: "col-lg-2 col-md-6 items",
  },
];

// Updated Award component with fadeInUp
const Award = memo(({ award, isMobile, index }) => (
  <div style={styles.container(isMobile)} className={award.className}>
    <div className="item" style={styles.animatedItem(0.2 + index * 0.1)}>
      <span className="icon">
        <img
          src={award.icon}
          alt={award.title}
          className="front honorsopac"
          loading="lazy"
        />
      </span>
      <h5 style={styles.heading}>{award.title}</h5>
      <p style={styles.description}>{award.organization}</p>
    </div>
  </div>
));

Award.displayName = "Award";

const Services3 = memo(({ mode }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    const handleResize = () => {
      requestAnimationFrame(checkMobile);
    };

    checkMobile();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.2 }
    );

    const section = document.querySelector(".feat");
    if (section) {
      observer.observe(section);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const elements = document.querySelectorAll(".feat .items");
    if (elements.length) {
      import("../../../common/cardMouseEffect").then((module) => {
        module.default(elements);
      });
    }
  }, []);

  return (
    <>
      <style>{fadeInAnimation}</style>
      <UnifiedStyles
        mode={mode}
        bgColor="#141518"
        title="industry.honors"
        subtitle="Recognized with excellence in design, technology and creative storytelling"
        subtitleColor="white"
        description="Design & Tech"
      >
        <section style={{ position: "relative" }} className="feat sub-bg">
          <div className="container">
            <div className="row">
              {awards.map((award, index) => (
                <Award
                  key={index}
                  award={award}
                  isMobile={isMobile}
                  index={index}
                />
              ))}
            </div>
          </div>
        </section>
      </UnifiedStyles>
    </>
  );
});

Services3.displayName = "Services3";

export default Services3;

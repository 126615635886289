export const crunchyrollData = [
  {
    image: "/assets/crunchyroll/crunchyroll-1.jpg",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Enhancing the Living Room Experience for 120 Million Fans with Streaming Apps", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          At Crunchyroll, anime wasn't just content — it was culture. With a
          passionate global community exceeding 120 million fans, we delivered
          an immersive, seamless streaming experience reflecting the dynamic and
          diverse world of anime. Our small but mighty team create a TV player
          app that not only met the high expectations of anime enthusiasts but
          set new standards for streaming services in terms of viewer experience
          and engagement.
          <br />
          <br />
          As a key team member, and one of three designers, I played a pivotal
          role in enhancing the streaming experience and content discoverability
          for TV platforms across multiple devices. This journey involved
          reimagining how viewers interact with our apps across Smart TVs,
          ensuring the design catered to their needs. I helped lay the
          foundation for the flagship tvOS player app, which became a
          cornerstone of our presence on Apple TV devices, as well as the
          viewing experiences across other TV apps.
          <br />
          <br />
          One of my most significant contributions was the design and
          development of the Voice User Interface (VUI), integrating voice
          interactions between hardware remotes and the app. This feature
          empowered viewers to search, select, and navigate using simple voice
          commands. By reducing friction in content discovery and giving viewers
          more control over their viewing journey, I helped elevate the overall
          experience. These efforts not only boosted viewer engagement but also
          supported Sony's $1.2 billion acquisition of Crunchyroll and its
          merger with Funimation.
          <br />
          <br />
          Crunchyroll encountered a series of complex challenges spanning
          technical, design, and operational aspects. The rapidly evolving
          streaming landscape demanded innovation and agility to meet viewer
          expectations and stay ahead in a competitive market. We needed to
          addressed these challenges head-on to deliver a product that resonated
          with our vast and diverse audience.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "New tvOS Player", // Updated start
          name: "Launching a new player with greater controls and more options for viewers presented both design and technical hurdles. We had to ensure that the player was intuitive and viewer-friendly while leveraging advanced capabilities across Smart TV and tvOS ecossytems. Balancing rich functionality with simplicity required careful planning and a deep understanding of viewer behavior.", // Updated name
        },
        {
          id: 2,
          start: "Distributed Teams", // Updated start
          name: "Our teams were geographically dispersed across California, Texas, and Moldova, which required a unified design approach and clear communication channels. Time zone differences and cultural nuances added complexity to project coordination. Establishing effective teamwork was essential to overcome the barriers posed by distance and to maintain productivity and alignment across all teams.", // Updated name
        },
        {
          id: 3,
          start: "M&A Transition", // Updated start
          name: "During this period, Crunchyroll was undergoing a significant transformation — a $1.2 billion acquisition by Sony and a merger with Funimation. Navigating this M&A transition added layers of operational complexities. We needed to integrate and adapt different corporate cultures, technologies, and processes while maintaining our focus on delivering a superior viewer experience.", // Updated name
        },
      ],
    },
  },

  {
    image: "/assets/crunchyroll/crunchyroll-2.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01", // List number based on format
      title: "Mastering Smart TV App Design", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Designing for Smart TVs isn't just about scaling an app; it's an
          entirely different landscape that demands a unique approach. Viewers
          interact with TV apps from the comfort of their couches, often several
          feet away from the screen, using remote controls with limited input
          options. This unique interaction model required me to rethink
          traditional interface paradigms and focus on creating experiences that
          are intuitive from a distance.
          <br />
          <br />
          Understanding the living room environment significantly influences
          viewer behavior and expectations. Factors such as ambient lighting,
          viewing distance, and the relaxed posture of viewers necessitate a
          design that is both accessible and engaging from afar. Unlike personal
          devices, TVs are often shared among multiple viewers, adding another
          layer of complexity to the design process.
          <br />
          <br />I began by immersing myself in the living room setting where
          viewers lounge on their sofas with dimmed lights, seeking an
          effortless and enjoyable viewing experience. This context shaped every
          design decision, from the size of the text to the placement of
          navigation elements and included the following key considerations and
          approaches.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Viewing Distance", // Updated start
          name: "Recognizing that viewers typically sit about 10-foot away from their TVs, I ensured that all text and UI elements were legible at a distance. This meant using larger fonts and clear, bold visuals that stood out even in less-than-ideal lighting conditions to prevent eye strain and make content easily accessible without causing frustration.", // Updated name
        },
        {
          id: 2,
          start: "Simplified Interfaces", // Updated start
          name: "I prioritized minimalism in design by creating clean, uncluttered interfaces, to make it easier for viewers to focus on their content without distractions. This simplicity was crucial for viewers who might be multitasking or unwinding after a long day, reducing cognitive load and enhancing viewer satisfaction.", // Updated name
        },
        {
          id: 3,
          start: "Eye-Friendly Colors", // Updated start
          name: "To enhance comfort, especially during evening viewing, I opted for dark color schemes with high-contrast elements. I meticulously tested these colors across different TV models to ensure consistent brightness and color reproduction. This approach reduced eye strain and enhanced readability, contributing to a more pleasant viewing experience.", // Updated name
        },
        {
          id: 4,
          start: "Intuitive Remote Navigation", // Updated start
          name: "Understanding the limitations of remote controls, I designed navigation to be straightforward and predictable, avoiding complex gestures or diagonal movements, and relying instead on simple directional inputs that matched the natural use of a remote. This design choice made the app more accessible to viewers of all ages and technological proficiencies.", // Updated name
        },
        {
          id: 5,
          start: "Performance Optimization", // Updated start
          name: "I avoided resource-intensive animations and effects that could slow down the app on lower-end TVs. By leveraging standard platform capabilities and optimizing assets, I ensured that the app was responsive and smooth, providing a seamless viewing experience across all devices, regardless of hardware limitations.", // Updated name
        },
      ],
    },
  },

  {
    image: "/assets/crunchyroll/crunchyroll-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01", // List number based on format
      title: "Diving Deep: Research and Insights", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To ensure that the new player and VUI met the diverse needs of
          Crunchyroll's audience, I conducted ethnographic research. I immersed
          myself into the lives of our viewers to understand how they interact
          with voice-enabled devices, natural language processing, remote
          hardware, and TV interfaces in their living rooms.
          <br />
          <br />I recognized that the living room is a unique environment where
          viewers often multitask, engage with content socially, and expect a
          seamless and intuitive experience. By observing viewers in their
          natural settings, I could identify subtle nuances and patterns that
          wouldn't be apparent through surveys or lab studies.
          <br />
          <br />
          The goal was to gain a holistic understanding of their behaviors,
          preferences, and pain points to inform our design decisions. This
          hands-on approach allowed me to gather rich qualitative data that was
          invaluable in crafting a viewer-centric design.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Observational Studies", // Updated start
          name: "I watched viewers interact with their Smart TVs and voice-enabled remotes in their natural environments to see how they navigated interfaces, the gestures they used, and the points where they encountered challenges.", // Updated name
        },
        {
          id: 2,
          start: "Competitive Analysis", // Updated start
          name: "I performed a thorough analysis of 5-7 leading competitors, including major streaming services and tech companies known for innovative voice technologies to identify best practices and areas where Crunchyroll could differentiate itself, offering a superior viewing experience tailored specifically to anime fans. ", // Updated name
        },
      ],
    },
  },

  {
    image: "/assets/crunchyroll/crunchyroll-4.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01", // List number based on format
      title: "Designing Intuitive Voice Interactions", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The design of the VUI focuses on three key factors: intent, utterance,
          and slot. By understanding viewer intent and crafting voice commands
          that aligned with natural speech patterns, I aimed to create a voice
          interaction system that felt natural and intuitive. This was
          especially important given the diversity of Crunchyroll's audience,
          which includes viewers with varying levels of familiarity with voice
          technology.
          <br />
          <br />I mapped out the end-to-end viewer experience for voice
          interactions, from launching the app to completing tasks using voice
          commands. I created detailed screen flows and state diagrams to
          visualize each interaction step, ensuring a logical and intuitive
          progression that was easy for viewers to follow. This comprehensive
          approach allowed us to identify potential friction points and optimize
          the system for seamless interactions.
          <br />
          <br />
          Crunchyroll's VUI designs are grounded in the following design
          principles:
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Clarity & Conciseness", // Updated start
          name: "Use short, simple prompts to aid understanding and rapid task completion. Clear messaging minimizes confusion and improves efficiency.", // Updated name
        },
        {
          id: 2,
          start: "Context Awareness", // Updated start
          name: "Consider previous interactions to make responses more personal and seamless. This approach makes the voice interface feel more conversational and adaptive.", // Updated name
        },
        {
          id: 3,
          start: "Error Handling", // Updated start
          name: "Offer clear feedback and guidance to enable viewers to correct mistakes easily. Providing helpful suggestions prevents frustration and encourages continued use of voice features.", // Updated name
        },
      ],
    },
  },

  {
    image: "/assets/crunchyroll/crunchyroll-5.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01", // List number based on format
      title: "Bringing the Design to Life", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I crafted the user flows, wireframes, visual designs, and motion
          designs with meticulous attention to detail and close collaboration
          with various stakeholders. Working alongside the interaction designer
          and principal designer, we aligned on brand guidelines and created new
          assets and animation patterns. Every aspect of the viewer experience
          was carefully considered, from the initial interface to the nuanced
          interaction elements that contribute to a seamless and engaging
          experience.
          <br />
          <br />
          Understanding that consistency is key to viewer familiarity and ease
          of navigation, I ensured that visual and interactive elements were
          coherent across different devices and platforms. This included
          adapting designs to accommodate the unique characteristics of various
          TV apps, while maintaining alignment with Crunchyroll's overall brand
          identity. I contributed new styles, components, and animations to
          Crunchyroll's design system.
          <br />
          <br />
          This unified approach ensured that the design language was consistent
          across the entire product ecosystem, making it easier for viewers to
          navigate and enjoy the platform. By enriching the design system, we
          also streamlined the development process, enabling faster
          implementation and easier maintenance.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "End-to-End Designs", // Updated start
          name: "Contributed all aspects, from the initial interface to detailed interaction elements, ensuring final designs were both on-brand and user-friendly according to the established visual language.", // Updated name
        },
        {
          id: 2,
          start: "Asset Creation and Documentation", // Updated start
          name: "Ensured that all visual assets were consistent with the design system and thoroughly documented for the development team. I also contributed new components and styles to the Crunchyroll design system.", // Updated name
        },
        {
          id: 3,
          start: "Engineering Collaboration", // Updated start
          name: "Provided detailed engineering documentation and specs to guide the implementation of the design, ensuring that the final product accurately reflected the design vision. I maintained a close partnership with the engineering team to ensure the designs were feasible and implemented correctly.", // Updated name
        },
      ],
    },
  },
  {
    image: "/assets/crunchyroll/crunchyroll-6.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "02", // Incremented list number
      title: "Mic Icon Placement Testing", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          A crucial aspect of my design was integrating the voice interaction
          feature seamlessly into the user interface. I recognized that the
          placement of the mic icon would significantly impact its
          discoverability and usability. My objective was to encourage viewers
          to utilize the voice search feature without disrupting their natural
          interaction flow or overwhelming the interface.
          <br />
          <br />
          To determine the optimal placement, I worked with the Research team to
          conduct usability testing. We tested the mic icon in three different
          locations: to the left of the search bar, to the right of the search
          bar, and on the on-screen keyboard. Each placement was evaluated based
          on viewer feedback and any observed friction during the interaction.
          <br />
          <br />
          Based on the insights, I chose to place the mic icon to the right of
          the search bar. This placement was less intrusive, did not interfere
          with the natural reading and navigation flow, and enhanced
          discoverability. It aligned with common UI patterns where action
          buttons are placed on the right and resulted in higher engagement with
          the voice search feature.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Left of the Search Bar", // Updated start
          name: "Viewers perceived this placement as somewhat intrusive. It pushed them toward voice search in an unnatural way, conflicting with their mental models of how search functionality operates. It also disrupted the natural left-to-right scanning flow, making the interface feel less intuitive.", // Updated name
        },
        {
          id: 2,
          start: "Right of the Search Bar", // Updated start
          name: "This placement felt intuitive and less intrusive. Viewers associated the right side with action buttons, aligning with their expectations for submitting input. It empowered viewers to choose the voice feature more readily, resulting in higher engagement.", // Updated name
        },
        {
          id: 3,
          start: "On the On-Screen Keyboard", // Updated start
          name: "While convenient for viewers already typing, it was less discoverable otherwise. Integrating the mic icon here presented significant technical challenges, requiring deep modification of the keyboard API. Given the complexity and limited benefits, this option was less favorable.", // Updated name
        },
      ],
    },
  },
  {
    image: "/assets/crunchyroll/crunchyroll-7.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Dealing with Constraints",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Throughout the development process, I fostered a culture of open
          feedback and collaboration as we faced several constraints, including
          technical limitations, time pressures, and resource availability.
          Coordinating a globally distributed team was further complicated by
          the COVID-19 pandemic and the onset of the Russia-Ukraine war, which
          affected team members' availability, well-being, and productivity.
          <br />
          <br />
          Communication challenges arose due to the shift to remote work and
          geopolitical tensions, disrupting our usual communication channels.
          Time zone differences limited real-time interactions, and external
          stressors required increased empathy and support within the team. We
          leveraged asynchronous communication tools and adjusted timelines to
          accommodate varying circumstances.
          <br />
          <br />
          Moreover, we faced technical constraints such as integrating new
          hardware capabilities, higher screen resolutions, and updated
          features. Adapting our designs to leverage these advancements while
          maintaining consistency across platforms was complex. The design
          system needed to accommodate new components and patterns without
          disrupting existing ones. Through innovative problem-solving and
          teamwork, we navigated these constraints successfully.
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Bridging Technology and Human Experience",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          This project was more than a professional milestone; it was a journey
          that reaffirmed my belief in the power of design to bridge gaps
          between technology and human experience. By centering my approach on
          the viewer's needs and contexts, I created solutions that not only met
          but exceeded expectations.
          <br />
          <br />
          Designing with empathy led to solutions that genuinely met viewer
          needs. Working across disciplines and geographies enriched the design
          process, providing diverse perspectives that resulted in more robust
          and inclusive designs. The collaborative environment fostered
          innovation and allowed us to tackle complex challenges effectively.
          <br />
          <br />
          Leading design initiatives honed my ability to guide teams and make
          strategic decisions, fostering a collaborative and growth-oriented
          environment. This experience allowed me to deepen my understanding of
          voice technologies and Smart TV platforms, navigating technical
          constraints without compromising on viewer experience. It underscored
          the importance of adaptability, communication, and viewer-centered
          design in achieving successful outcomes.
        </div>
      ),
    },
  },
];

export const dockerData = [
  {
    image: "/assets/docker/docker-1.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01", // Adjust list number as needed
      title:
        "Boosting Revenue with Dynamic Campaigns and Brand Refresh for 2M+ Technical Partners", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          At Docker, a pioneering force in cloud infrastructure and container
          platform technology, I was part of the team critical to our growth
          phase supporting Docker's $70M Series D funding round. With a thriving
          community of over 2 million technical partners and 750 enterprise
          customers, Docker sought to elevate its market presence and streamline
          its revenue generation processes.
          <br />
          <br />
          As the Senior Product/Brand Designer, my objective was to redesign the
          company's brand communications and lead nurturing processes through a
          comprehensive Marketo strategy, coupled with a brand refresh that
          would enhance marketing efficiency and brand cohesion.
          <br />
          <br />
          This initiative would boost Docker's competitive edge in the
          enterprise container platform market. By integrating Cialdini's
          principles of persuasion into our email sales and marketing funnels,
          we sought to create dynamic campaigns that not only attracted but also
          retained and converted leads, driving significant revenue growth.
          <br />
          <br />
          There was a clear need for a more user-centric approach that could
          deliver targeted, relevant content at each stage of the buyer's
          journey. Streamlining these processes would not only enhance
          engagement but also build stronger relationships with our partners,
          ultimately driving higher conversion rates and increased revenue.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Brand Consistency",
          name: "As Docker experienced growth, maintaining brand consistency across all communications became increasingly challenging. I observed different teams were creating their own variations of brand elements, resulting in a lack of coherence. This disjointed approach made it difficult to present a unified front to our diverse partner ecosystem, which ranged from individual developers to large enterprise clients. Ensuring that every touchpoint reflected Docker's core values and visual identity was essential to fostering a strong, recognizable brand.",
        },
        {
          id: 2,
          start: "Lead Nurturing",
          name: " Effective lead nurturing was critical to Docker's strategy for driving revenue growth. The goal was to engage potential buyers throughout their journey, from initial awareness to final purchase, ensuring a seamless and supportive experience. Partners were often lost in the sales funnel due to generic communication and inadequate follow-up strategies.",
        },
        // {
        //   id: 3,
        //   start: "Design Scalability:",
        //   name: "With a growing developer community of over 2 million users and 750 enterprise customers, Docker required a scalable design system to maintain consistency across all marketing materials and customer touchpoints.",
        // },
      ],
    },
  },

  {
    image: "/assets/docker/docker-2.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Crafting the Strategy",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To optimize Docker's lead nurturing processes, it was imperative to
          gain a deep understanding of buyer behavior. I conducted comprehensive
          research to analyze the critical stages in the buying cycle, from
          awareness to decision-making.
          <br />
          <br />
          Leveraging Marketo, I designed a comprehensive funnel strategy that
          captured leads and guided them through the pipeline. The funnel was
          structured to address each stage of the buyer's journey, ensuring that
          partners received the right information at the right time. This
          involved creating targeted email campaigns, dedicated landing pages,
          and automated workflows that nurtured leads from initial contact to
          conversion.
          <br />
          <br />
          The Marketo funnel was meticulously mapped to include various
          touchpoints, such as awareness campaigns, consideration emails, and
          decision-making support. This strategic approach ensured that our
          marketing efforts were both efficient and impactful, driving sustained
          revenue growth.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Understanding Buyer Behavior",
          name: "Analyzed the critical stages in the buying cycle, from awareness to decision-making, to tailor communications effectively.",
        },
        {
          id: 2,
          start: "Marketo Funnel",
          name: " To capture leads and track lifecycles, driving them into the sales pipeline.",
        },
        {
          id: 3,
          start: "Automated Campaigns",
          name: "Implemented a multi-phase lead nurturing program that generated interest and moved leads through the sales funnel.",
        },
      ],
    },
  },
  {
    image: "/assets/docker/docker-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      title: "Integrating Cialdini's Principles of Persuasion",
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To enhance the effectiveness of our email campaigns, I integrated
          Cialdini's principles of persuasion into our marketing funnels. These
          principles provided a psychological framework to craft messages that
          resonated deeply with our partners, encouraging them to take desired
          actions.
          <br />
          <br />
          By layering the principles into our email and marketing strategies, we
          were able to create more persuasive and engaging campaigns. This
          approach not only increased email open and click-through rates but
          also significantly boosted lead conversion rates, driving higher
          revenue and fostering stronger partnerships.
        </div>
      ),
      spmList: [
        {
          id: 4,
          start: "Authority and Social Proof",
          name: "Highlighted Docker's industry leadership and showcased testimonials from reputable partners to reinforce credibility and trust.",
        },
        {
          id: 5,
          start: "Scarcity and Urgency",
          name: " Introduced time-sensitive offers and limited availability notices to motivate partners to act quickly.",
        },
        {
          id: 7,
          start: "Consistency and Commitment",
          name: " Personalized follow-up emails built on previous interactions, reinforcing initial commitments and guiding partners toward purchase decisions.",
        },
      ],
    },
  },

  {
    image: "/assets/docker/docker-4.png",
    caption: "Streamlining Email Templates", // Updated caption
    intro: {
      listNumber: "04", // Adjust list number as needed
      title: "Streamlining Email Templates", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To maximize engagement, I focused on simplifying email designs by
          adopting a minimalist approach. This involved removing unnecessary
          elements and ensuring that each communication had a clear purpose and
          call-to-action (CTA). The minimalist design not only made the emails
          more visually appealing but also improved readability and user
          experience across different devices.
          <br />
          <br />
          By prioritizing essential elements, I reduced clutter and emphasized
          the key messages, making it easier for partners to understand and act
          upon the content. The responsive design ensured that emails looked and
          functioned well on both desktop and mobile devices, catering to the
          varied preferences and habits of our partner base.
          <br />
          <br />
          Each email was designed with a singular focus, whether it was driving
          signups, downloads, or product trials. This targeted approach ensured
          that the messaging was clear and direct, eliminating confusion and
          enhancing the likelihood of desired actions.
          <br />
          <br />
          By clearly defining the objective of each email, we were able to craft
          more effective CTAs and streamline the partner's decision-making
          process. This focus not only improved engagement but also made it
          easier to measure the success of each campaign, allowing for more
          precise
        </div>
      ),
      // spmList: [
      //   {
      //     id: 1,
      //     start: "Minimalist Responsive Design:",
      //     name: "Simplified email templates by removing design and code waste, ensuring they were lightweight and easily adaptable to different devices.",
      //   },
      //   {
      //     id: 2,
      //     start: "One Purpose Per Email:",
      //     name: "Each email had a singular focus, whether it was driving signups, downloads, or product trials, making it clear and effective for the recipients.",
      //   },
      //   {
      //     id: 3,
      //     start: "Email Modularity:",
      //     name: "Designed modular templates that allowed teams to create and manage campaigns with minimal effort, ensuring consistency and ease of use across various marketing initiatives.",
      //   },
      // ],
    },
  },
  {
    image: "/assets/docker/docker-5.png",
    caption: "Streamlining Email Templates", // Updated caption
    intro: {
      listNumber: "04", // Adjust list number as needed
      title: "Implementing Email Modularity", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To facilitate efficient campaign management, I implemented an email
          modularity system. This involved creating reusable components and
          templates that could be easily customized for different campaigns and
          purposes. The modular design allowed teams to create and manage
          campaigns with minimal effort, ensuring consistency and reducing the
          time spent on email creation.
          <br />
          <br />
          This approach also enabled rapid scaling of marketing efforts, as new
          campaigns could be quickly assembled using pre-built modules. The
          consistency maintained through modularity ensured that all
          communications adhered to Docker's brand standards, enhancing brand
          recognition and trust across all touchpoints.
        </div>
      ),
      // spmList: [
      //   {
      //     id: 1,
      //     start: "Signups and Downloads:",
      //     name: "The new Marketo strategy significantly improved lead conversion rates, driving more signups and downloads for Docker Enterprise Edition.",
      //   },
      //   {
      //     id: 2,
      //     start: "Enhanced Brand:",
      //     name: "The revamped brand communications supported Docker's successful $70M Series D funding round by presenting a consistent and compelling narrative to investors and stakeholders.",
      //   },
      //   {
      //     id: 3,
      //     start: "Scalability and Efficiency:",
      //     name: "The system approach allowed Docker to compete against far larger competitors by enabling rapid campaign development and deployment.",
      //   },
      // ],
    },
  },
  {
    image: "/assets/docker/docker-6.png",
    caption: "Streamlining Email Templates", // Updated caption
    intro: {
      listNumber: "04", // Adjust list number as needed
      title: "Collaboration with Engineering", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Effective collaboration with the engineering team was crucial to the
          successful implementation of our design system. Regular sync meetings
          were established to discuss design specifications, address technical
          constraints, and gather feedback from developers. This open dialogue
          ensured that our design vision was accurately translated into code,
          and any potential challenges were identified and addressed promptly.
          <br />
          <br />
          We provided developers with detailed design documentation, including
          annotations and style guides, to facilitate the accurate
          implementation of components. By maintaining a continuous feedback
          loop and fostering a culture of mutual respect and understanding, we
          ensured that our design system was not only visually consistent but
          also functionally robust and technically feasible.
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "Streamlining Email Templates", // Updated caption
    intro: {
      listNumber: "04", // Adjust list number as needed
      title: "Scale Docker's Marketing Efforts", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Automated campaigns qualify sales opportunities, allowing Docker to
          focus more time and effort on meeting customers' needs. Campaigns used
          to take days and weeks to develop but is now executed in a few hours.
          This end-to-end marketing campaign process allows Docker to compete
          against far larger competitors.
          <br />
          <br />A key component of this project was the standardization of the
          visual and interaction elements, making it easier to maintain brand
          consistency across all communications and allowing Docker to scale its
          outreach efforts efficiently.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Signups and Downloads",
          name: "The new Marketo strategy significantly improved lead conversion rates, driving more signups and downloads for Docker Enterprise Edition.",
        },
        {
          id: 2,
          start: "Enhanced Brand",
          name: "The revamped brand communications supported Docker's successful $70M Series D funding round by presenting a consistent and compelling narrative to investors and stakeholders.",
        },
        {
          id: 3,
          start: "Scalability and Efficiency",
          name: "The system approach allowed Docker to compete against far larger competitors by enabling rapid campaign development and deployment.",
        },
      ],
    },
  },
];

export const lunchboxData = [
  {
    image: "/assets/lunchbox/lunchbox-1.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Breaking Barriers into Ethnic and Halal SMB Markets",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Lunchbox Technologies is a leader in the restaurant industry with
          innovative digital ordering solutions. As the Head of Halal / Ethnic
          SMB Restaurants, I spearheaded the initiative to expand Lunchbox's
          market share among ethnic SMB restaurants with the launch of a B2B
          Restaurant SaaS platform.
          <br />
          <br />
          Expanding into new markets focused on ethnic and halal cuisines
          presented several formidable challenges. Entering these specialized
          markets required not only access to a diverse range of restaurants but
          also the ability to maintain a seamless experience tailored to their
          unique needs.
          <br />
          <br />
          Additionally, the traditional onboarding process was a significant
          hurdle, often taking more than 30 days to bring a new merchant on
          board. This delay severely hindered our ability to rapidly acquire and
          engage new merchants, limiting our growth potential. Compounding these
          issues were resource constraints, as we had to work within tight
          technical limitations, budget constraints, and strict timelines to
          deliver a high-quality product that met the diverse needs of SMB
          restaurants.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Market Expansion",
          name: `Entering new markets focused on ethnic and halal cuisines required access while maintaining a seamless and intuitive user experience.`,
        },
        {
          id: 2,
          start: "Merchant Acquisition and Onboarding",
          name: `The traditional onboarding process was lengthy and cumbersome, taking more than 30 days, which hindered rapid merchant acquisition and engagement.`,
        },
        {
          id: 31,
          start: "Resource Constraints",
          name: `The team needed to work within technical limitations, budget constraints, and tight timelines to deliver a high-quality product that met the diverse needs of SMB restaurants.`,
        },
      ],
    },
  },

  {
    image: "/assets/lunchbox/lunchbox-2.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Tailored B2B SaaS Platform",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          In my role as Head of Ethnic SMB Restaurants, I was responsible for
          driving the expansion strategy, optimizing the onboarding process, and
          designing an automated sales and marketing process. Leading the
          initiative to increase our market share among ethnic SMB restaurants,
          I spearheaded the launch of a tailored B2B Restaurant SaaS platform.
          <br />
          <br />
          To address the lengthy onboarding process, I streamlined it from 30
          days to just 30 minutes by developing optimized pricing models,
          implementing drip campaigns, creating effective landing pages, and
          refining UX strategies.
        </div>
      ),
      // spmList: [
      //   {
      //     id: 1,
      //     start: "Market Expansion and Strategy:",
      //     name: "Leading the initiative to increase market share among ethnic SMB restaurants by launching a tailored B2B Restaurant SaaS platform.",
      //   },
      //   {
      //     id: 2,
      //     start: "Sales and Marketing Process Design:",
      //     name: " Researching and designing an automated sales and marketing process that boosted merchant acquisition by 150%.",
      //   },
      //   {
      //     id: 31,
      //     start: "Onboarding Optimization:",
      //     name: "Streamlining the onboarding process from 30 days to 30 minutes through the development of optimized pricing models, drip campaigns, landing pages, and refined UX strategies.",
      //   },
      //   {
      //     id: 4,
      //     start: "Financial Management:",
      //     name: "Managing a $4.3 million P&L to ensure the financial success of the initiative during Lunchbox's $75 million Series B funding round.",
      //   },
      // ],
    },
  },

  {
    image: "/assets/lunchbox/lunchbox-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "User Research and Market Insights",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Understanding the unique needs of ethnic SMB restaurants was crucial
          for our success. I conducted comprehensive user research, including
          surveys and interviews with restaurant owners and managers, to gather
          insights into their operational challenges and customer engagement
          strategies, particularly within the context of ethnic and halal
          cuisines.
          <br />
          <br />
          This research revealed several key pain points: the existing
          onboarding process was too time-consuming and complex, ethnic SMB
          restaurants required more hand-on digital outreach and onboarding
          processes, and many struggled with inconsistent customer engagement
          across digital platforms.
          <br />
          <br />
          Additionally, market analysis highlighted gaps and opportunities for
          Lunchbox to differentiate itself and better serve ethnic restaurants.
          By identifying these critical areas, I was able to tailor our platform
          to address the specific needs of our target audience effectively.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Lengthy Onboarding Process",
          name: "The existing onboarding process was time-consuming and complex, deterring potential merchants from adopting the platform.",
        },
        {
          id: 2,
          start: "Lack of Personalization",
          name: "Ethnic SMB restaurants needed more personalized digital outreach and onboarding processes that catered to their specific needs and cultural contexts.",
        },
        {
          id: 3,
          start: "Inconsistent Customer Engagement",
          name: "Many restaurants struggled to maintain consistent engagement with their customers across digital platforms, which impacted their ability to build loyalty and drive repeat business.",
        },
      ],
    },
  },
  {
    image: "/assets/lunchbox/lunchbox-4.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Boosting Merchant Acquisition",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To streamline and scale our sales and marketing efforts, I implemented
          a sophisticated, automated approach that significantly enhanced
          merchant acquisition. Utilizing data-driven insights, I segmented
          leads based on customer tiers and integration stacks, allowing for
          more targeted and effective outreach.
          <br />
          <br />
          I developed automated drip campaigns that provided personalized and
          timely communication to prospects, improving engagement and
          accelerating the sales cycle. Furthermore, I designed a
          self-onboarding process that reduced the time required to onboard new
          merchants from 30 days to just 30 minutes.
          <br />
          <br />
          This transformation not only improved the customer journey but also
          significantly increased merchant acquisition rates, enabling us to
          rapidly expand our network of ethnic SMB restaurants and enhance
          Lunchbox's market presence.
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Driving Growth for Lunchbox Technologies",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The journey of expanding Lunchbox Technologies into the ethnic SMB
          restaurant market was one of passion, strategic planning, and
          relentless innovation. I navigated the challenges of market expansion,
          streamlined onboarding processes, and optimized our sales and
          marketing strategies to drive significant growth.
          <br />
          <br />
          Our strategic efforts led to remarkable growth and a positive impact
          on both users and the broader community. We successfully expanded our
          market share to over 20,000 prospective ethnic SMB restaurants,
          significantly enhancing Lunchbox's presence in this niche market.
          <br />
          <br />
          By reducing the onboarding process from 30 days to 30 minutes, we
          greatly improved the customer experience and accelerated platform
          adoption, allowing us to onboard merchants more efficiently and
          effectively.
          <br />
          <br />
          These achievements not only demonstrated the effectiveness of our
          strategies but also solidified Lunchbox Technologies as a leader in
          providing tailored digital solutions for ethnic and halal SMB
          restaurants.
          <br />
          <br />
          This experience underscored the importance of understanding and
          addressing the unique needs of niche markets, demonstrating how
          targeted innovation can lead to substantial business growth and
          community impact.
        </div>
      ),
    },
  },
];

export const vmwareData = [
  {
    image: "/assets/vmware/vmware-1.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Bringing digital transformation to large-scale enterprise for half a million partner users",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          VMware, a global leader in cloud infrastructure and digital workspace
          technology, I was part of the Partner Experience team that led the
          transformation of digital experiences for over half a million partner
          users. As the Senior UI/UX Designer, I spearheaded the initiative to
          redesign VMware's partner portals, including Partner Central and
          related platforms.
          <br />
          <br />
          80% of VMware's revenue came from its extensive partner ecosystem, and
          enhancing the partner experience was essential to boost engagement,
          productivity, and ultimately drive revenue growth. These were not just
          visual updates, but a comprehensive overhaul aimed at implementing
          user-centric designs, introducing intelligent search capabilities, and
          incorporating gamification strategies to improve partner engagement.
          Our goal was to transform a fragmented experience into an intuitive,
          efficient, and enjoyable platform for partners worldwide.
          <br />
          <br />
          This journey taught me invaluable lessons about empathy-driven design,
          collaboration, and the profound impact of thoughtful UX on business
          outcomes. The initiatives not only enhanced the tools our partners
          used but also deepened the relationships between VMware and its
          partner community.
        </div>
      ),
      // spmList: [
      //   {
      //     id: 1,
      //     start: "Fragmented User Experience ",
      //     name: `Partners were struggling with disjointed and outdated portal experiences. The existing platforms were not only visually unappealing but also lacked coherence, making it difficult for users to navigate and accomplish their tasks efficiently. This fragmentation led to decreased productivity and engagement, ultimately affecting VMware's bottom line.`,
      //   },
      //   {
      //     id: 2,
      //     start: "Complex Ecosystem",
      //     name: `VMware's partner ecosystem is vast, encompassing a multitude of resources, tools, and information spread across various portals. Partners often found it overwhelming to locate the specific content or functionality they required. Understanding the intricacies of this complex ecosystem was a significant challenge. It required a deep dive into the existing structures, content, and user behaviors to identify where the bottlenecks and pain points were most acute. `,
      //   },
      //   // {
      //   //   id: 31,
      //   //   start: "Distributed Teams:",
      //   //   name: `Collaboration across geographically dispersed teams required a unified design approach and clear communication channels.`,
      //   // },
      // ],
    },
  },

  {
    image: "/assets/vmware/vmware-2.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Navigating Complexity and Fragmentation",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          When I joined the PEX team, I quickly realized that partners were
          struggling with disjointed and outdated portal experiences. The
          existing platforms were not only visually unappealing but also lacked
          coherence, making it difficult for partners to navigate and accomplish
          their tasks efficiently. The fragmentation led to decreased
          productivity and engagement, which ultimately affected VMware's bottom
          line.
          <br />
          <br />
          I witnessed firsthand how partners grappled with multiple logins,
          inconsistent interfaces, and a lack of clear pathways to the resources
          they needed. Partners often felt overwhelmed when trying to locate
          specific content or functionality. Understanding the intricacies of
          this complex ecosystem required a deep dive into existing structures,
          content, and user behaviors.
          <br />
          <br />A significant transformation was necessary to meet their needs
          and expectations, by creating unified, intuitive, and engaging
          platforms that would empower partners rather than hinder them.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Fragmented User Experience",
          name: "Partners were struggling with disjointed and outdated portal experiences leading to decreased productivity and engagement, ultimately affecting VMware's bottom line.",
        },
        {
          id: 2,
          start: "Complex Ecosystem",
          name: "VMware's partner ecosystem is vast, encompassing a multitude of resources, tools, and information spread across various portals. Partners often found it overwhelming to locate the specific content or functionality they required.",
        },
        // {
        //   id: 31,
        //   start: "Engineering Collaboration:",
        //   name: "Providing detailed engineering documentation and specs to guide the implementation of the design, ensuring that the final product accurately reflected the design vision.",
        // },
      ],
    },
  },

  {
    image: "/assets/vmware/vmware-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "1", // Adjust list number as needed
      title: "User Research and Personas", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          We began by identifying the seven partner types and 20 sub-personas
          within VMware's ecosystem, each with distinct roles, goals, and
          challenges. This understanding was crucial in designing solutions that
          catered specifically to their needs.
          <br />
          <br />
          To ensure VMware's partner experience was competitive, we audited the
          partner ecosystems of leading tech companies. This helped identify
          best practices in navigation, information architecture, and user
          engagement that could be applied to VMware's platforms.
          <br />
          <br />
          Based on the research, I developed a framework that prioritized the
          top tasks partners needed to accomplish. This framework was
          instrumental in simplifying the user experience and reducing
          complexity across VMware's partner portals.
        </div>
      ),
      spmList: [
        {
          id: 3,
          start: "User Research",
          name: "Captured opinions through surveys and interviews to understand their pain points and identify critical tasks. Partners desired a more personalized experience that catered to their specific roles and objectives.",
        },
        {
          id: 3,
          start: "Competitive Analysis",
          name: "Audited the partner experiences of 5-7 comparable companies to benchmark best practices. Evaluated navigation structures, information architecture, visual design, and engagement strategies. Identified best practices and features that resonated with users.",
        },
      ],
    },
  },
  {
    image: "/assets/vmware/vmware-4.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Crafting a User-Centric Design",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To streamline discoverability across platforms, I worked with the
          Partner web marketing team to revamp multiple portals and design a
          search experience providing contextually relevant knowledge and
          resources at the moment of need, significantly reducing the time
          partners spent searching for information and increasing their overall
          productivity.
          <br />
          <br />
          I employed responsive design principles to ensure that portals looked
          and functioned well on various devices. I integrated high-quality
          visuals and icons to aid comprehension and add visual interest. I also
          ensured compliance with WCAG guidelines to make the portal accessible
          to users with disabilities.
          <br />
          <br />
          I introduced gamification into the partner portals using Bunchball
          Nitro Gamification Software to boost engagement, incorporating
          mechanics such as points and badges, partners earned rewards for
          completing training modules, participating in events, or achieving
          sales milestones. Leaderboards were implemented to encourage healthy
          competition among partners, while challenges and quests offered
          specific goals with rewards upon completion.
          <br />
          <br />
          These features leveraged intrinsic motivations like achievement,
          recognition, and social interaction, providing a sense of progression
          and accomplishment. As a result, we observed increased participation
          in training programs and events, and an overall enhancement in
          engagement with the portal's features.
          <br />
          <br />
          I also contributed to VMware's design system with a seat on the Design
          Counsel representing the partner world, and also by developing new
          patterns and styles. I crafted intuitive visual and interaction
          designs that aligned with VMware's brand and user expectations,
          ensuring a consistent and cohesive experience across all touchpoints,
          from Partner Central to the Developer Center and others.
          <br />
          <br />
          Working closely with engineers, I ensured that the design vision was
          implemented accurately and maintained design integrity and achieved
          the desired outcomes. Held regular meetings to discuss design
          specifications and address technical constraints. Provided detailed
          design documentation, including annotations and style guides. Worked
          closely during the development process to ensure the final product
          matched the design vision and participated in user acceptance testing
          to identify and rectify any discrepancies.
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "1", // Adjust list number as needed
      title: "Transforming Partner Experience", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The comprehensive redesign and strategic enhancements led to
          significant positive outcomes for VMware and its partners. This
          project demonstrated how strategic design and collaboration can
          transform challenges into opportunities, delivering solutions that
          resonate deeply with partners and drive significant business results.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 3,
          start: "Increased Partner Engagement",
          name: "The integration of gamification and a simplified user experience led to higher engagement levels, with partners spending more time on the portals and completing more tasks.",
        },
        {
          id: 3,
          start: "Reduced Time to Transact",
          name: "The streamlined processes and intelligent search capabilities reduced the time to complete key tasks by 57 days, significantly improving partner productivity.",
        },
        {
          id: 3,
          start: "Revenue Growth",
          name: " The improvements in partner experience directly contributed to increased revenue, with partners more effectively driving sales and closing deals.",
        },
        {
          id: 3,
          start: "Enhanced Partner Satisfaction",
          name: "Surveys conducted post-implementation showed a marked increase in partner satisfaction, with the CSAT score reaching 80%.",
        },
      ],
    },
  },
];

export const clomr = [
  {
    // image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    video: "/videos/case-studies/CLOMR/clomr-5.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Supercharging Anesthesia productivity with ai-powered CRM and automated workflows",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Clomr is revolutionizing case coordination and revenue management for
          distributed medical teams. In an industry where unplanned case
          cancellations and inefficient claim filing can lead to significant
          revenue losses, Clomr aimed to provide a unified solution that
          streamlines operations and enhances productivity.
          <br />
          <br />
          The platform enhances coordination, streamlines communication, and
          improves efficiency for distributed medical teams, including
          anesthesiologists, surgeons, sales representatives, and ambulatory
          surgery centers (ASCs).
          <br />
          <br />
          As the Head of Product Design, I understood that maintaining
          consistency and efficiency across our platform required a robust
          design system. I adopted Atomic Design principles, ensuring that our
          interface was broken down into manageable, reusable components. This
          approach allowed us to scale our design efforts without compromising
          on quality or user experience. Additionally, integrating Material UI
          provided us with a comprehensive library of pre-built components that
          we could customize to align with Clomr's unique branding and
          accessibility standards.
          <br />
          <br />
          The goal was to build a solid, accessible design system that could
          evolve alongside our product, support both web and mobile platforms
          through React/React Native components, and adhere to WCAG
          accessibility guidelines. By focusing on design tokens, variables, and
          auto layouts, I ensured that our design system was not only consistent
          and scalable but also flexible enough to accommodate future
          enhancements and user needs.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Conducting Market and User Research",
          name: `Gaining deep insights into the healthcare industry's needs and pain points through research and user interviews.`,
        },
        {
          id: 2,
          start: "Design System Development",
          name: `Creating a scalable, accessible design system that supports a cohesive user experience across platforms.`,
        },
        {
          id: 31,
          start: "Collaborative Workshops",
          name: `Facilitating stakeholder workshops to align on product strategy and ensure that the design process was user-centric and business-driven.`,
        },
      ],
    },
  },

  {
    video: "/videos/clomr-4.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Adopting Atomic Design Principles",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Building a design system from the ground up in a fast-paced startup
          environment like Clomr requires a strategic approach. I embraced
          Atomic Design methodology to create a systematic and scalable
          framework for our user interface.
          <br />
          <br />
          The Atomic Design approach allowed me to break down the interface into
          fundamental components — atoms, molecules, and organisms. This
          hierarchical structure facilitated the creation of reusable elements
          that could be easily managed and maintained. By focusing on the
          smallest elements first, I ensured that each component was
          thoughtfully designed and functionally robust, laying a strong
          foundation for more complex structures.
          <br />
          <br />
          Additionally, this methodology promoted consistency across all
          platforms by standardizing how components interacted and behaved. It
          enabled our product and engineering team to work collaboratively,
          leveraging shared components to build cohesive and intuitive user
          interfaces. This approach not only accelerated the design process but
          also ensured that our platform remained uniform and reliable as we
          scaled.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Atomic Design Framework",
          name: "Broke down the interface into atoms, molecules, and organisms for modularity. Enabled the creation of reusable components, promoting consistency and efficiency. Simplified the development process by providing a clear structure for component organization.",
        },
        {
          id: 2,
          start: "Reusable Components",
          name: "Developed a library of reusable UI elements to maintain consistency across platforms. Facilitated rapid design iterations and updates without redundant work. Ensured that all components adhered to the same design standards and accessibility guidelines.",
        },
        {
          id: 31,
          start: "Consistent User Experience",
          name: "Maintained a unified look and feel across different sections of the platform. Reduced the cognitive load for users by providing familiar and predictable interface elements. Enhanced overall usability and satisfaction through cohesive design practices.",
        },
      ],
    },
  },

  {
    image: "/assets/clomr/clomr-34.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Integrating Material UI and React Components",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To streamline our design and development processes, I integrated
          Material UI with React and React Native components. This integration
          was pivotal in ensuring that our design system was both efficient and
          scalable across different platforms.
          <br />
          <br />
          Material UI provides a robust library of pre-built components to
          customize Clomr's unique branding and functional requirements. By
          leveraging these components, we significantly reduced the time and
          effort needed to develop new features, allowing our team to focus on
          innovation and user-centric design enhancements. The customization
          capabilities of Material UI also ensured that our components remained
          consistent with our design language while providing the flexibility to
          adapt to specific use cases.
          <br />
          <br />
          Furthermore, using React and React Native enabled us to create a
          shared component library that could be used seamlessly across web and
          mobile applications. By centralizing our components, we minimized
          duplication of effort and ensured that any changes made to the design
          system were instantly reflected across all platforms, enhancing our
          ability to deliver a unified user experience.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Material UI Customization",
          name: "Leveraged Material UI's comprehensive library to accelerate component development.",
        },
        {
          id: 2,
          start: "Shared Component Library",
          name: "Created a centralized repository of components accessible to both designers and developers.",
        },
        {
          id: 31,
          start: "Cross-Platform Consistency",
          name: "Maintained uniform functionality and appearance across web and mobile applications and simplified the development process by using a unified technology stack for both platforms.",
        },
      ],
    },
  },
  {
    image: "/assets/clomr/clomr-35.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Utilizing Design Tokens and Variables", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To ensure that our design system was both flexible and maintainable, I
          implemented design tokens and variables. These foundational elements
          defined our core design attributes, such as colors, typography,
          spacing, and other stylistic properties, allowing for easy updates and
          theming across the entire platform.
          <br />
          <br />
          Design tokens served as the single source of truth for our design
          decisions, encapsulating the essential styles that could be referenced
          throughout our components. By defining these tokens, we eliminated
          inconsistencies and made it straightforward to implement changes
          across the board. This approach also facilitated theming, enabling us
          to quickly adapt our design to different contexts or user preferences
          without extensive redesign work.
          <br />
          <br />
          Additionally, we utilized variables to manage dynamic aspects of our
          design, such as responsive layouts and state-based styles. Coupled
          with auto layouts, our design system could automatically adjust to
          various screen sizes and orientations, ensuring a seamless and
          responsive experience for all users. This strategic use of tokens and
          variables not only enhanced the scalability of our design system but
          also significantly improved our ability to maintain a consistent and
          accessible user interface.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Foundation Tokens", // Updated start
          name: "Defined primary, secondary, and accent colors as design tokens for easy theming and standardized typography settings, including font sizes, weights, and styles.", // Updated name
        },
        {
          id: 2,
          start: "Layout Variables", // Updated start
          name: "Standardized spacing units and layout grids to maintain visual harmony, enabling responsive designs and easy adjustments to layout structures without redesigning components.", // Updated name
        },
        {
          id: 3,
          start: "Theming and Flexibility", // Updated start
          name: "Allowed for quick theme changes by modifying design tokens and variables, enhancing flexibility of the design system, making it easier to introduce new styles and adapt to evolving requirements.", // Updated name
        },
      ],
    },
  },
  {
    image: "/assets/clomr/clomr-36.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Creating a Comprehensive Components Inventory", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          With the design system framework in place, the next step was to
          develop a detailed inventory of UI components that would form the
          building blocks of the platform. This inventory was essential for
          maintaining consistency and efficiency as our product expanded.
          <br />
          <br />
          I began by cataloging all the UI elements used across the platform,
          from basic buttons and forms to more complex data tables and
          interactive modules. Each component was meticulously documented,
          including its variations, states, and intended use cases. This
          comprehensive approach ensured that every team member had a clear
          understanding of the available components and how to apply them
          effectively.
          <br />
          <br />
          To facilitate easy access and reuse, I organized the components into a
          centralized library. This library became the go-to resource for
          designers and developers, streamlining the design and development
          process by eliminating the need to recreate common elements from
          scratch. Additionally, by defining clear guidelines for each
          component, I reduced the likelihood of inconsistencies and ensured
          that all elements adhered to our established design principles and
          accessibility standards.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Cataloged UI Elements", // Updated start
          name: "Documented all atoms, molecules, and organisms with usage guidelines, including variations and states for each component to cover different scenarios.", // Updated name
        },
        {
          id: 2,
          start: "Defined Interaction States", // Updated start
          name: "Specified hover, active, disabled, and focus states for interactive components and provided clear visual indicators for different user interactions.", // Updated name
        },
        {
          id: 3,
          start: "Centralized Component Library", // Updated start
          name: "Created a single source of truth for all UI components, accessible to the entire team.", // Updated name
        },
      ],
    },
  },
  {
    image: "/assets/clomr/clomr-37.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Establishing a Single Source of Truth", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To ensure that our design system was accessible and usable by the
          entire team, I established a centralized repository that served as the
          single source of truth for all design-related assets and guidelines.
          This repository was crucial for maintaining consistency and enabling
          seamless collaboration between designers and developers.
          <br />
          <br />
          Regular updates and maintenance of the repository were essential to
          keep the design system current and relevant. I implemented version
          control practices to track changes and ensure that all team members
          were working with the latest versions of components. This approach
          minimized discrepancies and facilitated smooth hand-offs between
          design and development, ultimately enhancing the overall efficiency
          and effectiveness of our workflow.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Design System Documentation", // Updated start
          name: "Created detailed documentation accessible to all team, including guidelines, usage examples, and best practices for each component.", // Updated name
        },
        {
          id: 2,
          start: "Collaboration Tools", // Updated start
          name: "Figma for collaborative design and real-time updates to the design system, enabling seamless communication and coordination between team members.", // Updated name
        },
        {
          id: 3,
          start: "Updates & Maintenance", // Updated start
          name: "Implemented version control to track changes and manage updates efficiently, with periodic reviews to ensure the design system remains aligned with project goals.", // Updated name
        },
      ],
    },
  },
  {
    image: "/assets/clomr/clomr-38.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Collaboration with Engineering", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          A successful design system relies heavily on effective collaboration
          between design and engineering teams. Recognizing this, I worked
          directly with my engineering counterparts to ensure that the design
          vision was accurately translated into code.
          <br />
          <br />
          Regular sync meetings were established to discuss design
          specifications, address technical constraints, and gather feedback
          from developers. These meetings provided a platform for open dialogue,
          allowing us to identify potential challenges early and collaboratively
          develop solutions that aligned with both design and technical
          requirements.
          <br />
          <br />
          Additionally, I provided developers with detailed design
          documentation, including annotations and style guides, to facilitate
          the accurate implementation of components. By maintaining a continuous
          feedback loop and fostering a culture of mutual respect and
          understanding, we ensured that our design system was not only visually
          consistent but also functionally robust and technically feasible.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Technical Feasibility Discussions", // Updated start
          name: "Regular meetings with engineers to align design with technical capabilities and address any constraints or challenges in real-time, ensuring smooth implementation.", // Updated name
        },
        {
          id: 2,
          start: "Detailed Design Documentation", // Updated start
          name: "Provided comprehensive specs, annotations, and style guides to developers, ensuring that all design elements were clearly defined and easy to implement.", // Updated name
        },
        {
          id: 3,
          start: "Continuous Feedback Loop", // Updated start
          name: "Encouraged ongoing feedback from engineers to refine and improve components.", // Updated name
        },
      ],
    },
  },

  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Strategic Design Practices and Leadership",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          {/* My work at Crunchyroll demonstrates the power of thoughtful,
          user-centric design in transforming complex challenges into seamless,
          engaging user experiences. By focusing on the needs and behaviors of
          our diverse audience, we were able to deliver innovative solutions
          that not only delighted users but also supported the company's
          strategic goals during a critical period of growth and change. */}
          Implementing a comprehensive design system at Clomr provided
          invaluable insights and reinforced the importance of strategic design
          practices in a fast-paced startup environment. The robust design
          system significantly improved our internal workflows and communication
          processes, and played a pivotal role in boosting the efficiency of our
          design and development processes. Adhering to WCAG guidelines was a
          cornerstone of our design system, ensuring that our platform was
          accessible to all users.
          <br />
          <br />
          In this role, I enhanced my leadership skills by effectively managing
          complex projects and coordinating cross-functional teams. I developed
          strategies to balance a strategic vision with day-to-day operational
          demands, fostering a collaborative and growth-oriented environment..
          <br />
          <br />I deepened my expertise in building and implementing
          comprehensive design systems using Material UI, React, React Native,
          and improved my understanding of accessibility standards and best
          practices, ensuring that our designs were both inclusive and
          compliant.
          <br />
          <br />
          Furthermore, my strategic thinking was significantly strengthened as I
          learned to align design initiatives with broader business objectives
          and user needs. I became adept at identifying and prioritizing
          high-impact features and components that drove efficiency and user
          satisfaction.
        </div>
      ),
    },
  },
];

export const urban = [
  {
    video: "/videos/case-studies/URBAN HALAL/urban-halal-4.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Elevating SMB restaurants with SaaS marketplace, headless commerce & last-mile delivery",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I founded Urban Foody with a simple yet ambitious vision: to bring the
          delectable global halal flavors to local neighborhoods. As a food
          enthusiast and tech entrepreneur, I wanted to create a platform that
          not only satisfied appetites but also connects cultures through the
          shared love of food. The journey, however, was far from easy.
          <br />
          <br />
          I designed the restaurant SaaS platform from the ground up, including
          both iOS and Android apps. Initially, the goal was to create a
          discovery app that would help users find halal restaurants and diverse
          cuisines in their area. Despite my passion and dedication, the MVP
          launch faced limited engagement, and user adoption was slower than
          expected. Users desired more than just discovering restaurants — they
          wanted convenience and a seamless experience.
          <br />
          <br />
          Listening to their feedback, I made the pivotal decision to expand
          into a last-mile delivery marketplace, enhancing the platform to meet
          user demands. This transformation wasn't easy. It required reimagining
          the platform's capabilities, redesigning the user experience, and
          overcoming logistical hurdles. Yet, these struggles enriched the
          journey, making Urban Foody not just a platform but a movement to
          connect people through the universal language of food.
        </div>
      ),
    },
  },

  {
    // video: "/videos/case-studies/URBAN HALAL/urban-halal-4.mp4",
    image: "/assets/urban-halal/urban-halal-4.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "The Changing Landscape",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The advent of food delivery apps such as DoorDash and UberEats
          completely changed the way consumers discover and engage with a
          diverse array of cuisines. These platforms revolutionized the dining
          experience by providing unparalleled convenience, allowing users to
          explore new restaurants and order their favorite meals with just a few
          taps on their smartphones. By aggregating a wide range of culinary
          options in one place, these apps made it easier than ever for
          consumers to experiment with different flavors and dining
          establishments without leaving the comfort of their homes.
          <br />
          <br />
          Moreover, these food delivery giants significantly impacted local
          businesses by expanding their reach beyond traditional dine-in
          customers. Restaurants, both large and small, could now tap into a
          broader customer base, increasing their visibility and revenue
          streams. This symbiotic relationship between delivery apps and
          eateries fostered a dynamic food ecosystem where innovation and
          variety thrived. Consumers benefited from this expanded selection,
          enjoying access to an extensive menu of options that catered to
          various tastes and dietary preferences.
          <br />
          <br />
          However, despite their success, DoorDash and UberEats primarily
          focused on mainstream markets, often overlooking niche segments with
          specific cultural and dietary needs. This gap presented an opportunity
          for platforms like Urban Foody to emerge, addressing the unique
          requirements of communities seeking halal-certified and culturally
          authentic dining options.
          <br />
          <br />
          Urban Foody was designed to bridge this gap by connecting users with
          trusted halal restaurants in their local neighborhoods, ensuring that
          cultural and dietary preferences are seamlessly integrated into the
          modern food delivery landscape. By prioritizing inclusivity and
          cultural sensitivity, Urban Foody not only enhances the food discovery
          experience but also fosters a sense of community and trust among its
          diverse user base.
          <br />
          <br />
        </div>
      ),
    },
  },

  {
    // video: "/videos/case-studies/URBAN HALAL/urban-halal-4.mp4",
    image: "/assets/urban-halal/urban-halal-22.png",

    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Designing for Cultural Inclusivity",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Launching Urban Foody presented several significant challenges that
          required innovative solutions. First, appealing to a culturally
          diverse audience was complex. I needed to create a platform that not
          only included halal food options but was also sensitive to various
          cultural nuances and dietary preferences. This was crucial to ensure
          inclusivity and attract a broad user base.
          <br />
          <br />
          Second, the initial discovery app faced limited user engagement. Users
          wanted more than just discovering restaurants; they desired an
          engaging experience that motivated them to explore new cuisines and
          share their discoveries with others. Recognizing this, I needed to
          enhance the platform to better meet user expectations and increase
          engagement.
          <br />
          <br />
          Lastly, building a robust technical infrastructure was essential.
          Designing a scalable and flexible API that could handle complex
          queries and integrate various services was critical for the platform's
          success. We needed to ensure seamless performance as we expanded our
          offerings to include last-mile delivery, similar to how Uber Eats
          manages complex logistics.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Culturally Diverse Audience",
          name: "The platform needed to appeal to a broad spectrum of users, including those seeking halal food options, while being sensitive to cultural nuances and dietary preferences",
        },
        {
          id: 2,
          start: "Enhanced Engagement",
          name: "The goal was to design an engaging experience that motivated users to explore new cuisines and share their discoveries",
        },
        {
          id: 31,
          start: "Complex API Interactions",
          name: " Designing a robust API interface was essential to support the platform's capability and scalability, ensuring seamless integration of various services and data sources.",
        },
      ],
    },
  },
  {
    image: "/assets/urban-halal/urban-halal-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "From Vision to Reality",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          As the founder and principal product designer, I was responsible for
          shaping every aspect of Urban Foody's design and development. From
          conceptualizing the initial design to leading the technical
          implementation, my role required a harmonious blend of creative vision
          and technical expertise. I spearheaded the hiring process, assembling
          a talented team that included a motion designer, data specialists,
          researchers, and engineers.
          <br />
          <br />
          I conducted comprehensive user research, organizing interviews and
          surveys to gain deep insights into our target audience's motivations
          and pain points. This research was pivotal in developing detailed user
          personas, which guided our design strategy and ensured that the
          platform addressed the real needs of our users. By immersing myself in
          the community, I gathered firsthand feedback that informed both the
          aesthetic and functional aspects of the app, ensuring a user-centric
          approach throughout the development process.
          <br />
          <br />
          I led the design of the platform, focusing on creating an intuitive
          and culturally sensitive user experience. This included developing a
          global design system that ensured consistency across all touchpoints.
          Incorporating diverse cultural elements into the design ensured that
          the platform resonated with a wide audience.
          <br />
          <br />
          Collaborating with developers, I ensured that the technical aspects of
          the platform aligned with our goals. This involved designing a
          scalable API and integrating complex features like real-time delivery
          tracking. Creating a flexible and robust API structure was crucial, as
          was implementing advanced functionalities seamlessly. Working closely
          with the development team allowed us to overcome technical challenges
          effectively.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    video: "/videos/case-studies/URBAN HALAL/urban-halal-9.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "User Research and Persona Development",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Understanding our customers required me to immerse deeply into their
          worlds. I ventured into local neighborhoods, engaged with restaurant
          owners, spent time inside their kitchens, and even followed delivery
          drivers on their routes.
          <br />
          <br />
          Visiting restaurants in various cities to experience their food
          workplace cultures helped me understand the unique challenges and
          preferences in each location. Building relationships with local
          eateries and partnerships with service teams in the delivery ecosystem
          allowed me to identify pain points and areas for improvement.
          <br />
          <br />
          By observing users interacting with the platform in real-life
          settings, I gained insights into how they used Urban Foody and where
          enhancements were needed. Watching users navigate the app while
          ordering food, seeing how restaurant staff interacted with our system
          during peak times, and gathering immediate reactions and suggestions
          from users were invaluable to the development process.
          <br />
          <br />
          Additionally, mapping out the user journey allowed me to identify key
          emotional touchpoints and optimize the experience accordingly. From
          the initial discovery of a halal restaurant to the seamless completion
          of a delivery, every step was designed to be intuitive and satisfying.
          This approach ensured that Urban Foody provided a meaningful and
          enjoyable experience, fostering long-term loyalty and trust among our
          diverse user base.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "/assets/urban-halal/urban-halal-20.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Cultural Sensitivity in Design",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Cultural inclusivity was at the core of Urban Foody's design
          philosophy. Recognizing that food is a significant aspect of cultural
          identity, I ensured that the platform celebrated and respected the
          diverse backgrounds of our users. This involved integrating a wide
          range of global cuisines and providing halal-certified options to
          cater to various dietary requirements. By prioritizing cultural
          sensitivity, Urban Foody became a trusted platform for users seeking
          authentic and reliable dining experiences.
          <br />
          <br />
          I implemented a trustworthy and easy-to-use filtered search, ensuring
          that information was clear and consistent, providing them with
          confidence in their dining choices. Additionally, I incorporated
          diverse cultural elements into the design, using culturally relevant
          imagery, icons, and language to create a welcoming and inclusive
          environment for all users.
          <br />
          <br />
          To enhance user engagement, I focused on the social aspects of dining.
          I integrated features that allowed users to share their dining
          experiences which not just improved user satisfaction but also
          reinforced the communal aspect of food.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Halal Food Discovery",
          name: "Users struggled with finding reliable halal food options due to inconsistent information and unclear certifications. URBAN FOODY aimed to bridge this gap by offering a trustworthy and easy-to-use filtered search.",
        },
        {
          id: 2,
          start: "Social Dynamics",
          name: "Users frequently used social media and group chats to discover and plan dining experiences. The platform needed to facilitate easy sharing and collaboration among users.",
        },
      ],
    },
  },

  {
    image: "/assets/urban-halal/urban-halal-21.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Designing the Platform",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Building a robust API was critical to support Urban Foody's expanded
          features, including last-mile delivery. I collaborated closely with
          our engineering team to design an API capable of handling complex
          queries and integrating seamlessly with various services. This
          involved creating a flexible and scalable API structure that could
          accommodate future growth and additional functionalities, ensuring the
          platform could adapt to evolving user needs and market trends.
          <br />
          <br />
          To facilitate smooth integration, I presented detailed mockups and API
          schemas, including response bodies in JSON format. This collaborative
          design process fostered meaningful conversations between our teams,
          ensuring that the API not only met technical requirements but also
          enhanced the overall user experience. The API was designed to support
          real-time delivery tracking, dynamic restaurant listings, and
          personalized recommendations, positioning Urban Foody as a competitive
          player in the market.
          <br />
          <br />
          Our API design focused on flexibility and scalability, enabling
          seamless performance as we expanded our offerings. By ensuring that
          the API could handle filtered searches based on cuisine types, halal
          certification, and user preferences, we provided users with a highly
          personalized and efficient food discovery and delivery experience.
          This technical foundation was essential for Urban Foody's ability to
          grow and innovate continuously.
        </div>
      ),
    },
  },
  {
    image: "/assets/urban-halal/urban-halal-22.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Developing the Design System",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Creating a consistent and engaging user experience requires the
          establishment of a comprehensive design system, which I developed
          using Figma as our primary design tool. Leveraging Figma's powerful
          features, I built a library of reusable components that ensured
          uniformity across all platforms. By utilizing variables and design
          tokens, such as color palettes, typography scales, and spacing
          guidelines, I maintained consistency in visual elements while allowing
          for easy scalability and customization. This structured approach not
          only streamlined the design process but also facilitated seamless
          collaboration between our teams, ensuring that every element adhered
          to our established standards.
          <br />
          <br />
          I meticulously crafted each component with Figma's auto layout
          features, enabling responsive and adaptable designs that performed
          flawlessly across various devices and screen sizes. This included
          designing interactive elements like buttons, forms, and navigation
          menus that were both visually appealing and highly functional.
          <br />
          <br />
          Additionally, I integrated motion graphics and transition animations
          to enhance user interactions, reducing cognitive load and making the
          platform more intuitive. By implementing design tokens, I ensured that
          any updates to the design system could be efficiently propagated
          throughout the entire application, maintaining a cohesive aesthetic
          and improving overall user experience.
          <br />
          <br />
          Flexibility and adaptability were key considerations in developing the
          design system. I incorporated design tokens and variables that allowed
          for rapid iterations based on user feedback and evolving market
          trends. This agility was crucial during the pivot to a last-mile
          delivery marketplace, as it enabled quick adjustments to the user
          interface and user experience without compromising design integrity.
          <br />
          <br />
          By maintaining a cohesive and visually appealing design across all
          touchpoints through Figma's centralized system, Urban Foody provided a
          seamless and intuitive user experience. This approach not only
          fostered trust and engagement among users from diverse cultural
          backgrounds but also ensured that the platform could swiftly adapt to
          meet new challenges and opportunities.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Visual and Interaction Design",
          name: "Creating custom visuals and motion graphics that conveyed meaning and reduced cognitive load. The design elements were tailored to support a variety of global cuisines and user appetites.",
        },
        {
          id: 2,
          start: "Color Science and Emoji Integration",
          name: " Leveraging color psychology, we used colors like red, yellow, and orange to evoke appetite and excitement. Emojis were incorporated to humanize the brand and add a layer of emotion to the content, aligning with the platform's values of happiness, inclusion, transparency, and accountability.",
        },
      ],
    },
  },
  {
    image: "/assets/urban-halal/urban-halal-33.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Implementing the Octalysis Framework",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To drive user engagement and adoption, I applied the Octalysis
          framework, focusing on the core drives of human motivation. This
          strategic approach enabled the design of features that resonated with
          users on a psychological level, encouraging deeper interaction with
          the platform.
          <br />
          <br />
          I introduced elements of social influence by highlighting trending
          restaurants and enabling users to share their dining experiences with
          friends and family. Scarcity and exclusivity were leveraged through
          limited-time offers and exclusive deals, creating a sense of urgency
          and desire among users. Unpredictability was incorporated with
          surprise recommendations and personalized suggestions, keeping the
          user experience fresh and exciting.
          <br />
          <br />
          By providing users with a sense of accomplishment through progress
          tracking, reviews, and ratings, we fostered loyalty and repeat usage.
          This gamification of the platform made the user experience more
          enjoyable and engaging, encouraging users to actively participate and
          contribute to the community. The integration of these motivational
          elements ensured sustained user engagement and enhanced the overall
          appeal of Urban Foody.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Social Influence and Scarcity",
          name: "Encouraging users to explore trending restaurants and exclusive dining experiences.",
        },
        {
          id: 2,
          start: "Unpredictability and Curiosity",
          name: " Keeping users engaged with features like random restaurant suggestions and surprise offers.",
        },
        {
          id: 3,
          start: "Accomplishment and Empowerment",
          name: "  Providing users with a sense of achievement through features like progress tracking, reviews, and ratings.",
        },
      ],
    },
  },
  {
    video: "/videos/case-studies/URBAN%20HALAL/urban-halal-6.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Competitive Edge in the Food Delivery",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Speed and agility were essential in refining Urban Foody to meet user
          expectations and stay ahead in the competitive food delivery market. I
          adopted a rapid iteration approach, continuously testing designs in
          real-world settings and making swift improvements based on user
          feedback. This methodology allowed us to identify and address issues
          early, ensuring that the platform evolved in line with user needs and
          preferences.
          <br />
          <br />
          Developing quick prototypes was a cornerstone of our iterative
          process. By creating mock-ups and wireframes, we could visualize new
          ideas and gather immediate feedback from users through user testing
          sessions. These sessions provided valuable insights into what worked
          well and what needed improvement, enabling us to refine features and
          enhance the overall user experience efficiently. This responsiveness
          ensured that we stayed aligned with user expectations and maintained
          high levels of satisfaction. <br />
          <br />
          <br />
          Implementing A/B testing further optimized our design decisions by
          allowing us to compare different versions of features and determine
          which resonated best with users. Analyzing performance metrics and
          making data-driven decisions ensured that we consistently rolled out
          the most effective solutions. Additionally, working in agile cycles
          facilitated continuous improvement, with sprint planning,
          collaborative workflows, and continuous deployment enabling the team
          to adapt quickly to changes and deliver updates regularly. This
          approach not only accelerated development but also fostered a culture
          of innovation and excellence within the team.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    video: "/videos/case-studies/URBAN%20HALAL/urban-halal-5.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Culturally Inclusive Delivery Marketplace ",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Innovation was the driving force behind Urban Foody's evolution,
          enabling us to redefine the traditional food delivery model with
          unique features and services. Transitioning from a discovery app to a
          delivery marketplace opened new avenues for growth and user
          engagement. Implementing our own last-mile delivery system provided
          greater control over the delivery process, ensuring reliability and
          efficiency for our users.
          <br />
          <br />
          I integrated advanced technologies to enhance functionality and user
          satisfaction. Personalization was employed through cuisine options
          based on user preferences and past behaviors, making the discovery
          process more intuitive and tailored. Interactive maps were developed
          to help users and delivery partners navigate more effectively,
          reducing delivery times and improving overall efficiency. Seamless
          payment integrations simplified transactions, providing a smooth and
          secure experience for users.
          <br />
          <br />
          Fostering a sense of community among users, restaurants, and delivery
          partners was key to our success. Features that enabled users to share
          reviews, connect with others, and collaborate with local businesses
          helped build a vibrant and engaged community around Urban Foody.
          Additionally, hosting activities that celebrate diversity and food
          further reinforced our commitment to cultural inclusivity and
          community building. These continuous innovations not only enhanced the
          user experience but also positioned Urban Foody as a leader in the
          niche market of halal food discovery and delivery.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    video: "/videos/case-studies/URBAN%20HALAL/urban-halal-7.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Rigorous Testing and Continuous Improvement",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Ensuring that Urban Foody met the highest standards of usability and
          functionality required rigorous testing and validation processes. I
          prioritized collecting comprehensive feedback from users and making
          data-driven improvements to refine the platform continuously. This
          commitment to quality assurance was essential in building a reliable
          and trustworthy app that users could depend on for their food
          discovery and delivery needs.
          <br />
          <br />
          Extensive user testing was conducted to evaluate every aspect of the
          app. Beta launches allowed us to release features to a select group of
          users for initial feedback, while surveys and polls gathered valuable
          opinions on specific functionalities. Usability studies provided
          real-time observations of how users interacted with the platform,
          highlighting areas for improvement and guiding subsequent design
          iterations. This multifaceted testing approach ensured that we
          addressed user concerns effectively and enhanced the overall user
          experience.
          <br />
          <br />
          Performance monitoring was another critical component of our
          validation strategy. Utilizing analytics tools, I tracked key
          performance indicators such as user engagement rates, cart volumes,
          and repeat orders. Load testing ensured that the platform could handle
          increasing traffic without compromising performance, while error
          tracking enabled the swift identification and resolution of technical
          issues. These measures maintained high service quality and
          reliability, fostering trust and confidence among our users.
          Continuous improvement through feedback loops allowed us to adapt the
          platform to evolving user needs, ensuring that Urban Foody remained
          relevant and effective.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Driving Growth and Cultural Impact",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The culmination of our dedicated efforts led to significant growth and
          a positive impact on both users and communities. The strategic pivot
          to a last-mile delivery marketplace, coupled with the integration of
          interactive and engaging features, resulted in a substantial boost in
          user engagement and satisfaction. Users began returning to the
          platform more frequently, and we received increased ratings and
          favorable reviews, reflecting the enhanced user experience.
          <br />
          <br />
          Urban Foody successfully bridged diverse cultures through food,
          fostering mutual understanding and appreciation among our user base.
          By offering a wide range of cuisines we ensured that users from
          various backgrounds felt represented and valued. This cultural
          integration not only strengthened user loyalty but also positioned
          Urban Foody as a trusted and inclusive platform within the community.
          <br />
          <br />
          The platform's evolution also drove sustainable business growth.
          Higher revenues from delivery services and strategic partnerships
          enabled us to expand our offerings and enter new markets. More
          restaurants joined Urban Foody, broadening our culinary selection and
          enhancing the platform's appeal. The scalable and flexible API
          supported this growth, allowing us to seamlessly integrate new
          features and data sources as we expanded into additional cities and
          regions. These achievements underscored Urban Foody's role as a
          pioneering force in the niche market of halal food discovery and
          delivery.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Enhanced Cultural Relevance",
          name: "The platform successfully catered to a diverse audience by providing reliable halal food options and promoting culturally inclusive dining experiences.",
        },
        {
          id: 2,
          start: "Increased User Engagement",
          name: "  The integration of digital psychology and gamification led to higher user participation and satisfaction, with significant growth in user-generated content and social sharing.",
        },
        {
          id: 3,
          start: "Scalable and Flexible API",
          name: "  The well-designed API supported the platform's growth, enabling seamless integration of new features and data sources as the platform expanded.",
        },
      ],
    },
  },
];

export const blessed = [
  {
    image: "/assets/feeling blessed/feeling-blessed-1.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Revolutionizing Charitable Giving for Faith-based Communities",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          At Feeling Blessed, I took on the role of Principal Product Designer
          and Mobile App Developer with a mission to transform private
          charitable giving for eight million Muslims in the United States.
          Recognizing the challenges inherent in traditional methods of
          donating, I led the design and development of innovative mobile
          applications aimed at simplifying and enhancing the charitable
          experience, particularly during significant periods like Ramadan,
          Hajj, and end-of-year donations. My goal was to create a platform that
          not only made giving efficient and transparent but also respected user
          privacy while fostering a sense of community and healthy competition
          to maximize charitable contributions.
          <br />
          <br />
          From the outset, the project demanded a deep understanding of the
          cultural and religious nuances that guide charitable practices within
          the Muslim community. Designing both iOS and Android applications from
          scratch, I focused on integrating modern technology with the spiritual
          principles of Zakat, one of the Five Pillars of Islam. The journey was
          fraught with challenges, from ensuring accurate Zakat calculations to
          building trust in the platform's transparency. However, my commitment
          to empowering donors and facilitating impactful giving kept me
          motivated to overcome these obstacles and drive meaningful change.
          <br />
          <br />
          Feeling Blessed was envisioned not just as an app but as a movement to
          reimagine charitable giving. By leveraging cutting-edge design
          strategies and user-centric development, I aimed to create a platform
          that connected donors with trustworthy charities, streamlined the
          donation process, and maintained high levels of user engagement
          throughout the year. This holistic approach ensured that Feeling
          Blessed stood out as a leading fintech solution dedicated to Muslim
          philanthropic innovation.
        </div>
      ),
      // spmList: [
      //   {
      //     id: 1,
      //     start: "New tvOS Player:",
      //     name: `The need to introduce a new tvOS player with greater control and more options for users posed both design and technical challenges.`,
      //   },
      //   {
      //     id: 2,
      //     start: "M&A Transition:",
      //     name: `Integrating Crunchyroll's teams and platform with Funimation required a seamless user experience to support the broader business objectives of Sony's acquisition.`,
      //   },
      //   {
      //     id: 31,
      //     start: "Distributed Teams:",
      //     name: `Collaboration across geographically dispersed teams required a unified design approach and clear communication channels.`,
      //   },
      // ],
    },
  },

  {
    image: "/assets/feeling blessed/feeling-blessed-2.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "The Complexity of Giving",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Developing a platform tailored to the unique needs of the Muslim
          community presented several significant challenges. One of the primary
          hurdles was the complexity of giving. Many users found it difficult to
          identify trustworthy charities and accurately calculate their Zakat,
          leading to frustration and sometimes discouraging regular donations.
          Simplifying these processes without compromising their integrity was
          essential to ensure widespread adoption and sustained use of the
          platform.
          <br />
          <br />
          Transparency and trust were paramount concerns. Donors needed
          assurance that their contributions were reaching the intended
          beneficiaries and being utilized effectively. Building a system that
          provided detailed donation tracking and direct connections between
          donors and charities was crucial to address these concerns. Without
          this level of accountability, even well-intentioned donors might
          hesitate to fully engage with the platform, undermining its
          effectiveness and mission.
          <br />
          <br />
          Engagement and motivation throughout the year also posed a challenge.
          While the desire to give was particularly strong during Ramadan and
          Hajj, maintaining donor engagement during other times required
          innovative solutions. Users sought more than just a transactional
          experience; they wanted an engaging platform that fostered a sense of
          community and offered incentives to encourage continued participation.
          Designing features that could sustain interest and motivate ongoing
          contributions was essential to the platform's long-term success.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Complexity of Giving",
          name: "Identifying trustworthy charities and calculating Zakat accurately can be cumbersome for many users.",
        },
        {
          id: 2,
          start: "Transparency and Trust",
          name: "Ensuring donations reach the intended beneficiaries and are used effectively is a significant concern for donors.",
        },
        {
          id: 31,
          start: "Engagement and Motivation",
          name: "While the intent to give is strong, maintaining donor engagement and motivating sustained giving throughout the year required innovative solutions.",
        },
      ],
    },
  },

  {
    image: "/assets/feeling blessed/feeling-blessed-3.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Inspiring Charitable Giving",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          As the Principal Product Designer and Mobile App Developer at Feeling
          Blessed, I was at the forefront of shaping the platform's vision and
          execution. My responsibilities spanned the entire design and
          development lifecycle, from conducting user research to crafting
          intuitive user interfaces and leading a team of engineers to bring the
          app to life. I was dedicated to ensuring that every aspect of the
          platform met the highest standards of usability, functionality, and
          cultural sensitivity.
          <br />
          <br />
          Conducting extensive user research was a cornerstone of my role. I
          organized and led interviews and surveys to gain deep insights into
          the motivations and pain points of potential donors. This research was
          instrumental in developing detailed user personas, which guided the
          design strategy and ensured that the platform addressed the real needs
          of our target audience. Understanding the spiritual and emotional
          drivers behind charitable giving allowed me to create features that
          resonated deeply with users.
          <br />
          <br />
          In addition to research, I spearheaded the design strategy by creating
          wireframes, visual designs, and a comprehensive transactional design
          system. These elements worked together to streamline the user
          experience, making it straightforward for users to calculate Zakat,
          select charities, and complete donations with ease. Collaborating with
          stakeholders, I facilitated workshops to define the brand identity and
          leverage motivational frameworks like Octalysis and Dhikr Vision to
          drive user engagement. This holistic approach ensured that Feeling
          Blessed was not only functional but also inspiring and engaging for
          its users.
        </div>
      ),
    },
  },
  {
    image: "/assets/feeling blessed/feeling-blessed-4.png",
    caption: "Streamlining Email Templates", // Updated caption
    intro: {
      listNumber: "04", // Adjust list number as needed
      title: "Empathy in Action", // Updated title
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To create a platform that truly resonated with our users, I immersed
          myself deeply in their experiences and communities. This hands-on
          approach involved attending religious gatherings, participating in
          charity events, and engaging directly with donors and charity
          organizations. By being present in the environments where our users
          lived and gave, I gained invaluable insights into their needs,
          preferences, and the emotional significance of their charitable
          actions.
          <br />
          <br />
          Through this immersion, I mapped out the user journey, identifying the
          emotional highs and lows that donors experienced throughout the giving
          process. For many, the act of giving was not just a duty but a
          spiritually rewarding experience, especially during sacred times like
          Ramadan and Hajj. By visualizing these touchpoints, I was able to
          design solutions that enhanced positive experiences while mitigating
          the challenges users faced.
          <br />
          <br />
          Engaging with the community also highlighted the importance of privacy
          and simplicity in the donation process. Users valued discreet and
          secure transactions, desiring an interface that was both user-friendly
          and respectful of their privacy. These insights guided the development
          of features that ensured ease of use, such as a straightforward Zakat
          calculator and secure donation mechanisms. Additionally, fostering a
          sense of community through features that allowed users to share their
          giving experiences and track their contributions helped build a loyal
          and engaged user base.
        </div>
      ),
    },
  },
  {
    image: "/assets/feeling blessed/feeling-blessed-5.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Defining the Giving Ecosystem",
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I mapped out the user journey, identifying the emotional highs and
          lows across different touchpoints in the giving process. This helped
          in visualizing the overall experience and ensuring that the app
          catered to the specific needs of each persona.
          <br />
          <br />
          Through workshops with stakeholders, we defined the brand values and
          attributes and positioned Feeling Blessed as a leading fintech
          institution dedicated to Muslim philanthropic innovation. This strong
          brand identity was essential in establishing trust and credibility
          among donors. Through interviews and surveys, key insights into how
          users approached charitable giving were identified.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Emotional Motivation",
          name: "Donors are deeply motivated by the spiritual rewards associated with giving, particularly during Ramadan and Hajj.",
        },
        {
          id: 2,
          start: "Need for Simplicity",
          name: "Users sought a straightforward, user-friendly interface that made it easy to calculate Zakat, select charities, and send their donations.",
        },
        {
          id: 31,
          start: "Desire for Privacy",
          name: "While transparency was essential, users also valued privacy in their charitable activities, preferring discreet and secure transactions.",
        },
      ],
    },
  },
  {
    image: "/assets/feeling blessed/feeling-blessed-6.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Creating a Seamless Transactional System",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I started with sketches to define and streamline the user flow,
          mapping each step of the donation process. These sketches were then
          developed into detailed wireframes, and various fidelity stages from
          low to high. A key part of the project was the creation of a
          transactional design system that ensured consistency across the app,
          making the experience smooth and visually cohesive.
          <br />
          <br />
          The visual design focused on clarity and simplicity, using a calming
          color palette and prescriptive iconography to guide users through the
          app. Interaction designs were crafted to minimize friction, allowing
          users to increase donations with a single tap, and complete donations
          quickly and easily.
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Increased Donation Efficiency",
          name: "The app streamlined the donation process, making it easier for users to fulfill their Zakat obligations and contribute to other charitable causes",
        },
        {
          id: 2,
          start: "Enhanced User Engagement",
          name: "The integration of motivational frameworks led to higher user engagement, particularly during Ramadan, with a significant increase in repeat donations",
        },
        {
          id: 31,
          start: "Transparency and Trust",
          name: "By connecting users directly with charities and providing detailed donation tracking, the app built trust and fostered long-term relationships between donors and charities",
        },
      ],
    },
  },
  {
    video: "/videos/case-studies/FEELING BLESSED/feeling-blessed-2.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Rapid Iteration and Continuous Improvement",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          In the fast-paced world of app development, speed and agility were
          crucial to refining Feeling Blessed and meeting user expectations. I
          adopted a rapid iteration approach, continuously testing designs in
          real-world settings and making swift improvements based on user
          feedback. This method allowed us to identify and address issues early,
          ensuring that the platform evolved in line with user needs and
          preferences.
          <br />
          <br />
          Developing quick prototypes was a key aspect of this iterative
          process. By creating mock-ups and wireframes, I was able to visualize
          new ideas and gather immediate feedback from users. User testing
          sessions provided insights into what worked well and what needed
          improvement, enabling us to refine features and enhance the overall
          user experience efficiently. This responsiveness ensured that we
          stayed aligned with user expectations and maintained a high level of
          satisfaction.
          <br />
          <br />
          Agile development methodologies further supported our ability to
          iterate quickly. Working in short sprints, the team could adapt to
          changes and incorporate feedback seamlessly. Collaborative workflows
          ensured that designers and developers were always in sync, while
          continuous deployment allowed us to release updates regularly. This
          approach not only accelerated the development process but also
          fostered a culture of continuous improvement, ensuring that Feeling
          Blessed remained relevant and effective in meeting its mission.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    video: "/videos/case-studies/FEELING BLESSED/feeling-blessed-3.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Innovating with Purpose",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Innovation was the driving force behind Feeling Blessed's evolution. I
          sought to redefine traditional charitable giving by integrating modern
          technologies and motivational frameworks that set our platform apart.
          Leveraging the Octalysis Framework, I incorporated game-like elements
          such as progress tracking, leaderboards, and achievement badges to
          increase user engagement and foster a sense of competition and
          accomplishment among donors.
          <br />
          <br />
          The integration of Dhikr Vision further aligned the app's features
          with spiritual practices, enhancing the emotional connection users
          felt with their charitable actions. By reminding users of the
          spiritual rewards associated with giving, especially during key
          periods like Ramadan, the platform motivated sustained engagement and
          increased contributions. This blend of technology and spirituality
          created a unique and compelling user experience that resonated deeply
          with our audience, and positioned Feeling Blessed as a leader in
          fintech solutions for Muslim philanthropic activities.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    video: "/videos/case-studies/FEELING BLESSED/feeling-blessed-4.mp4",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Building Trust through Quality",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Ensuring that Feeling Blessed met the highest standards of usability
          and functionality required rigorous testing and validation processes.
          I prioritized collecting comprehensive feedback from users and making
          data-driven improvements to refine the platform continuously. This
          commitment to quality assurance was essential in building a reliable
          and trustworthy app that users could depend on for their charitable
          needs.
          <br />
          <br />
          Extensive user testing was conducted to evaluate every aspect of the
          app. Beta launches allowed us to release features to a select group of
          users for initial feedback, while surveys and polls gathered valuable
          opinions on specific functionalities. Usability studies provided
          real-time observations of how users interacted with the platform,
          highlighting areas for improvement and guiding subsequent design
          iterations. This multifaceted testing approach ensured that we
          addressed user concerns effectively and enhanced the overall user
          experience.
          <br />
          <br />
          Performance monitoring was another critical component of our
          validation strategy. Utilizing analytics tools, I tracked key
          performance indicators such as user engagement rates, donation
          volumes, and repeat usage. Load testing ensured that the platform
          could handle increasing traffic without compromising performance,
          while error tracking enabled the swift identification and resolution
          of technical issues. These measures maintained high service quality
          and reliability, fostering trust and confidence among our users.
          Continuous improvement through feedback loops allowed us to adapt the
          platform to evolving user needs, ensuring that Feeling Blessed
          remained a valuable and effective tool for charitable giving.
        </div>
      ),
    },
  },
  {
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Empowering Generosity and Community Engagement",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The culmination of our efforts at Feeling Blessed led to significant
          positive outcomes for both users and the broader community. The app
          streamlined the donation process, making it easier for users to
          fulfill their Zakat obligations and contribute to various charitable
          causes with confidence. By simplifying complex tasks like calculating
          Zakat and selecting trustworthy charities, we removed barriers that
          previously hindered regular giving, resulting in higher donation
          efficiency and user satisfaction.
          <br />
          <br />
          User engagement saw a marked increase, particularly during Ramadan,
          with a significant rise in repeat donations. The integration of
          motivational frameworks and community-building features fostered a
          sense of belonging and encouraged sustained participation throughout
          the year. Users appreciated the app's ease of use and the emotional
          connection it facilitated with their charitable actions, leading to a
          loyal and active user base.
          <br />
          <br />
          Transparency and trust were greatly enhanced through detailed donation
          tracking and direct connections between donors and charities. This
          transparency built long-term relationships and increased donor
          confidence in the impact of their contributions. Feeling Blessed not
          only transformed how people gave but also strengthened the
          philanthropic ecosystem within the Muslim community, fostering greater
          mutual understanding and support among donors and beneficiaries alike.
        </div>
      ),
    },
  },
];

export const cair = [
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Enhancing the living room experience for 120 million fans with streaming apps",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          At Crunchyroll, I played a pivotal role in enhancing the streaming
          experience and content discoverability for TV players across
          platforms, as well as setting the foundation for the flagship tvOS
          player app.
          <br />
          <br />
          As the Senior Product Designer, I led the design and development of
          the voice UI for hardware remote and software applications for TV
          apps. These efforts not only improved user engagement but also
          supported Sony's $1.2 billion acquisition of Crunchyroll and merger
          with Funimation.
          <br />
          <br />
          Crunchyroll encountered a series of challenges as it aimed to unify
          its platform and elevate the overall user experience. These challenges
          were multifaceted, encompassing technical, design, and operational
          aspects:
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "New tvOS Player",
          name: `The need to introduce a new tvOS player with greater control and more options for users posed both design and technical challenges.`,
        },
        {
          id: 2,
          start: "M&A Transition",
          name: `Integrating Crunchyroll's teams and platform with Funimation required a seamless user experience to support the broader business objectives of Sony's acquisition.`,
        },
        {
          id: 31,
          start: "Distributed Teams",
          name: `Collaboration across geographically dispersed teams required a unified design approach and clear communication channels.`,
        },
      ],
    },
  },

  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Designing Intuitive Voice Interactions",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The design of the Voice User Interface (VUI) focused on creating
          seamless and intuitive interactions guided by three key factors:
          intent, utterance, and slot. By understanding user intent and crafting
          voice commands that aligned with natural speech patterns, we made it
          easier for users to discover content and control their viewing
          experience.
          <br />
          <br />I meticulously mapped out the end-to-end user experience for
          voice interactions, from launching the app to completing tasks using
          voice commands. Detailed screen flows and state diagrams were created
          to visualize each interaction step, ensuring a logical and intuitive
          progression that was easy for users to follow.
          <br />
          <br />I created the user flows, wireframes, visual designs, and
          animations. I worked with the interaction designer and principal
          designer to align on brand guidelines and create new animation
          patterns. To maintain consistency across all platforms, I contributed
          new styles, components, and interaction patterns to Crunchyroll's
          design system. This unified approach ensured that the design language
          was coherent across the entire product ecosystem, making it easier for
          users to navigate and enjoy the platform.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "End-to-End Experience Design",
          name: "Designing every aspect of the user experience, from the initial interface to detailed interaction elements.",
        },
        {
          id: 2,
          start: "Asset Creation and Documentation",
          name: "Ensuring that all visual assets were consistent with the design system and thoroughly documented for the development team.",
        },
        {
          id: 31,
          start: "Engineering Collaboration",
          name: "Providing detailed engineering documentation and specs to guide the implementation of the design, ensuring that the final product accurately reflected the design vision.",
        },
      ],
    },
  },

  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Testing,  Validation and Iterative Feedback Loop",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          User testing played a crucial role in refining the design of both the
          voice UI and the tvOS player. We conducted extensive evaluations to
          ensure that the interfaces met user needs and expectations, balancing
          powerful capabilities with an intuitive and accessible design.
          <br />
          <br />
          Throughout the development process, I worked closely with stakeholders
          to gather feedback and iterate on the design. This agile approach
          allowed me to continuously refine the feature, addressing any issues
          and enhancing the overall user experience.
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Imprioved Content Discoverability",
          name: "The new voice UI contributed to a 5% improvement in content discoverability across platforms, making it easier for users to find and enjoy their favorite shows.",
        },
        {
          id: 2,
          start: "Enhanced User Engagement",
          name: "The redesigned tvOS player and voice UI significantly improved user engagement, leading to higher satisfaction rates among Crunchyroll's vast user base.",
        },
        {
          id: 31,
          start: "Supporting Strategic Goals",
          name: "The successful implementation of these designs played a key role in supporting Sony's $1.2 billion acquisition of Crunchyroll and Funimation, showcasing the value of user-centric design in achieving business objectives.",
        },
      ],
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Bridging Technology and Human Experience",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          My work at Crunchyroll demonstrates the power of thoughtful,
          user-centric design in transforming complex challenges into seamless,
          engaging user experiences. By focusing on the needs and behaviors of
          our diverse audience, we were able to deliver innovative solutions
          that not only delighted users but also supported the company's
          strategic goals during a critical period of growth and change.
        </div>
      ),
    },
  },
];

export const serpapiData = [
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title:
        "Redesigning the Status Page for Enhanced Usability and Brand Engagement",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          At SERPAPI, I led the redesign of the status page, focusing on
          improving usability, streamlining API engine discoverability, and
          enhancing the overall user experience. My work was instrumental in
          transforming the status page into a more intuitive, informative, and
          brand-aligned interface that met the needs of developers, marketers,
          and researchers. These efforts not only improved the clarity and
          accessibility of key information but also strengthened SERPAPI's brand
          identity, contributing to a more engaging and delightful user
          experience.
          <br />
          <br />
        </div>
      ),
    },
  },

  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "The Challenge",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          SERPAPI faced several significant challenges with its existing status
          page, which hindered user experience and brand engagement:
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Usability Issues",
          name: "The status page was not user-friendly, making it difficult for users to find and understand critical information, such as selecting an API engine and interpreting the 'Ludicrous Speed' feature.",
        },
        {
          id: 2,
          start: "Information Clarity",
          name: " Key metrics like 'full request' were not clearly defined, leading to confusion about what the status metrics represented.",
        },
        {
          id: 31,
          start: "Brand Identity",
          name: "The status page lacked a distinctive 'vibe' or recognizable style, missing an opportunity to strengthen brand recognition and user engagement.",
        },
      ],
    },
  },

  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "My Role",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          As the Product Designer, I was responsible for leading the redesign of
          the SERPAPI status page. My responsibilities included:
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "User Research and Competitive Analysis",
          name: " Conducting in-depth research to understand user needs and evaluate competitor status pages.",
        },
        {
          id: 2,
          start: "Design Execution",
          name: "Creating user flows, wireframes, visual designs, and engineering documentation to guide the development of the new status page.",
        },
        {
          id: 31,
          start: "Design System Contribution",
          name: "Enhancing the existing design system with new components, styles, and patterns to ensure consistency across all SERPAPI platforms.",
        },
        {
          id: 4,
          start: "Cross-Functional Collaboration",
          name: "Working closely with engineers, product managers, and other stakeholders in an agile development environment to deliver the project on time and to specification.",
        },
      ],
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Understanding User Needs",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To ensure the new status page met the needs of SERPAPI's diverse user
          base, I conducted extensive research focusing on how users interact
          with status pages and what information they prioritize. This research
          involved:
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "User Interviews",
          name: "Engaging with developers, marketers, and researchers to gather insights on their pain points and preferences when using status pages.",
        },
        {
          id: 2,
          start: "Contextual Inquiries",
          name: "Observing how users navigated the existing status page and identifying areas where they encountered difficulties.",
        },
      ],
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Competitive Analysis",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I also conducted a thorough audit of status pages offered by 5-7
          competitors, including leading tech companies known for their
          innovative UI designs. This analysis helped us identify best practices
          and areas where SERPAPI could differentiate itself by offering a
          superior user experience.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Designing an Intuitive and Informative Interface",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The redesign of the SERPAPI status page focused on creating a seamless
          and intuitive interface that provided users with clear, real-time
          information about system availability, performance, and health. Key
          design decisions included:
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Enhanced Discoverability",
          name: "The engine selection dropdown was made more prominent and user-friendly, allowing users to quickly access the status of specific engines.",
        },
        {
          id: 2,
          start: "Information Clarity",
          name: "Definitions for complex metrics like 'full request' were clarified, and tooltips were added to provide users with additional context for better understanding.",
        },
      ],
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Mapping User Flows and Data Presentation",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          I meticulously mapped out the user flows and data presentation for the
          status page, ensuring that critical information was easily accessible
          and logically organized. Detailed wireframes and data visualization
          elements were created to ensure a consistent and coherent user
          experience.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Engaging and Consistent Visual Design",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          The visual design of the status page was updated to reflect SERPAPI's
          brand identity, balancing a modern, playful aesthetic with a
          professional tone. This included:
          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Aesthetic Refresh",
          name: "The page was given a fresh, modern look with consistent use of colors, typography, and iconography that aligned with SERPAPI's brand.",
        },
        {
          id: 2,
          start: "Micro-Interactions",
          name: "Subtle animations and micro-interactions were introduced to make the user experience more engaging and responsive.",
        },
      ],
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Real-Time Transparency and Alerts",
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          To build trust and keep users informed, real-time alerts and
          notifications were integrated into the status page. This ensured that
          users were immediately aware of any issues or updates, enhancing
          transparency and reliability.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Rigorous User Testing",
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          User testing played a crucial role in refining the design of the new
          status page. We conducted extensive evaluations to ensure that the
          interface met user needs and expectations, focusing on usability,
          information clarity, and overall engagement.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "/assets/case-studies/case-studies/crunchy-roll/CR10.png",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Iterative Feedback Loop",
      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          Throughout the development process, I worked closely with stakeholders
          to gather feedback and iterate on the design. This agile approach
          allowed us to continuously refine the product, addressing any issues
          and enhancing the overall user experience.
          <br />
          <br />
        </div>
      ),
    },
  },
  {
    image: "",
    caption: "lorem ipsum dolor sit amet consectetuer adipiscing elit",
    intro: {
      listNumber: "01",
      title: "Outcomes and Impact",

      content: (
        <div style={{ fontFamily: "Montserrat", fontWeight: 400 }}>
          {/* The visual design of the status page was updated to reflect SERPAPI's brand identity, balancing a modern, playful aesthetic with a professional tone. This included: */}

          <br />
          <br />
        </div>
      ),
      spmList: [
        {
          id: 1,
          start: "Improved Usability",
          name: "The redesigned status page significantly enhanced the user experience by making critical information more accessible and easier to understand.",
        },
        {
          id: 2,
          start: "Increased User Engagement",
          name: "The introduction of micro-interactions and a cohesive visual design led to higher user engagement and satisfaction.",
        },
        {
          id: 1,
          start: "Stronger Brand Identity",
          name: "The aesthetic refresh aligned the status page with SERPAPI's brand, helping to reinforce the company's identity and build customer loyalty.",
        },
        {
          id: 2,
          start: "Enhanced Information Clarity",
          name: "Clearer definitions and better organization of metrics improved users' understanding of the system status, reducing confusion and support queries.",
        },
      ],
    },
  },
];

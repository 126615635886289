import React from "react";
import { useMediaQuery } from "react-responsive";
import { InlineWidget } from "react-calendly";
import lottieJson from "../../../data/sections/World-Class DEI Talent.json";
import Lottie from "react-lottie-player";
const CallToAction = ({ img }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  return (
    <section
      className="call-action section-padding sub-bg bg-img "
      style={{
        padding: isMobile ? "120px 0 120px 0" : "0px 0 120px 0",
        backgroundImage: `url(${img ? img : "/assets/patrn.svg"})`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-12 mb-80">
            <div className="content text-center sm-mb30">
              <div
                style={{ margin: "0px" }}
                className="sec-head  text-center nomobile"
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Lottie
                    style={{ width: "60%" }}
                    loop
                    animationData={lottieJson}
                    play
                  />
                </div>

                <h6
                  style={{
                    color: "#FF6690",
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: "20px",
                    fontFamily: "machina",
                  }}
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                >
                  {"{ let's.connect }"}
                </h6>
                <h3
                  style={{ color: "#a4a7b1", fontFamily: "machina bold" }}
                  className="wow "
                >
                  Book a Meeting
                </h3>
              </div>
              <div className="sec-head  text-center nodesktop">
                <h6
                  style={{
                    color: "#FF6690",
                    textTransform: "none",
                    fontWeight: "500",
                    fontSize: "18px",
                    marginBottom: "10px",
                    fontFamily: "machina",
                  }}
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                >
                  {"{ let's.connect }"}
                </h6>
                <h3
                  style={{
                    color: "#a4a7b1",
                    fontSize: "42px",
                    lineHeight: "1.2em",
                    margin: "0px",
                    fontFamily: "machina bold",
                  }}
                  className="wow "
                >
                  Book a
                </h3>
                <h3
                  style={{
                    color: "#a4a7b1",
                    fontSize: "42px",
                    lineHeight: "1.2em",
                    margin: "0px",
                    fontFamily: "machina bold",
                  }}
                  className="wow "
                >
                  Meeting
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row pt-10 pb-0 justify-content-center"></div>
        <InlineWidget
          styles={{ height: "700px" }}
          url="https://calendly.com/meet-xdstudios/discovery?hide_event_type_details=1&hide_gdpr_banner=1"
        />
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-2  p-0 text-center">
            <div className="copy-right">
              <p
                style={{
                  color: "#FF6690",
                  textTransform: "none",
                  fontWeight: "400",
                  wordSpacing: "5px",
                  fontFamily: "machina",
                }}
              >
                {"{ © JAVED.IO 2024 }"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

import React, { useEffect, useRef } from "react";

const ScrollableVideo = () => {
  // Create references for the video element and the container to set its height
  const videoRef = useRef(null);
  const setHeightRef = useRef(null);

  useEffect(() => {
    const vid = videoRef.current; // Reference to the video element
    const setHeight = setHeightRef.current; // Reference to the container element

    const playbackConst = 500; // Constant to control the playback speed

    // Function to set the height of the container dynamically based on the video duration
    const handleMetadataLoaded = () => {
      setHeight.style.height = Math.floor(vid.duration) * playbackConst + "px";
    };

    // Function to control video playback based on scroll position
    const scrollPlay = () => {
      const frameNumber = window.pageYOffset / playbackConst; // Calculate the current frame number based on scroll position
      vid.currentTime = frameNumber; // Set the video's current time to the calculated frame number
      window.requestAnimationFrame(scrollPlay); // Request the next animation frame to continue the playback
    };

    // Add event listener for when the video's metadata is loaded
    vid.addEventListener("loadedmetadata", handleMetadataLoaded);

    // Start the scroll-based playback
    window.requestAnimationFrame(scrollPlay);

    // Cleanup event listeners on component unmount
    return () => {
      vid.removeEventListener("loadedmetadata", handleMetadataLoaded);
    };
  }, []);

  return (
    <div style={{ height: "100%", zIndex: "-1" }}>
      {/* Container to dynamically set the height based on video duration */}
      <div id="set-height" ref={setHeightRef}></div>
      {/* Placeholder for displaying time (not used in the current implementation) */}
      <p id="time" style={{ fontFamily: "Helvetica", fontSize: "24px" }}></p>
      {/* Video element with fixed position */}
      <video
        id="v0"
        ref={videoRef}
        tabIndex="0"
        autobuffer="autobuffer"
        preload="preload"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
        }}
      >
        {/* Source of the video */}
        <source
          type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          src="https://case-studies-ecru.vercel.app/videos/project/vmware.mp4"
        />
      </video>
    </div>
  );
};

export default ScrollableVideo;

import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery, Typography } from "@mui/material";

const UnifiedStyles = ({
  mode,
  title,
  description,
  subtitle,
  subtitleColor,
  children,
  bgColor,
  containerWidth,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
        rootMargin: "0px",
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  const fadeInAnimation = `
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `;

  return (
    <div
      ref={componentRef}
      style={{
        paddingTop: isMobile ? "60px" : "120px",
        paddingBottom: isMobile ? "60px" : "120px",
        position: "relative",
        backgroundColor: bgColor,
      }}
    >
      <style>{fadeInAnimation}</style>
      <div
        style={{
          maxWidth: containerWidth ? containerWidth : "",
        }}
        className="container"
      >
        <h6
          style={{
            color: "#FF6690",
            textTransform: "none",
            fontWeight: "500",
            fontSize: "20px",
            fontFamily: "machina",
            letterSpacing: "5px",
            textAlign: "center",
            opacity: 0,
            animation: isVisible ? "fadeInUp 0.8s ease-out forwards" : "none",
            animationDelay: "0.2s",
          }}
        >
          {`{ ${title} }`}
        </h6>
        <h3
          style={{
            color: "#a4a7b1",
            fontFamily: "machina bold",
            fontSize: "48px",
            marginBottom: "20px",
            textAlign: "center",
            opacity: 0,
            animation: isVisible ? "fadeInUp 0.8s ease-out forwards" : "none",
            animationDelay: "0.4s",
          }}
        >
          {description}
        </h3>
        <Typography
          variant="subtitle1"
          sx={{
            mb: 4,
            color: subtitleColor
              ? subtitleColor
              : mode
              ? "a4a7b1 !important"
              : "#4a4a4a !important",
            fontFamily: "Montserrat",
            textAlign: "center",
            width: isMobile ? "100%" : "70%",
            margin: "0 auto",
            lineHeight: "1.5em",
            fontSize: isMobile ? "0.9rem" : "20px",
            opacity: 0,
            animation: isVisible ? "fadeInUp 0.8s ease-out forwards" : "none",
            animationDelay: "0.6s",
          }}
        >
          {subtitle}
        </Typography>
        <div
          style={{
            marginTop: isMobile ? "40px" : "80px",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default UnifiedStyles;

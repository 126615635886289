import React, { useState, useEffect, useRef } from "react";
import appData from "../../../data/app.json";
import { handleDropdown, handleMobileDropdown } from "../../../common/navbar";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { IconButton } from "@mui/material";

const buttonStyles = {
  position: "relative",
  zIndex: 1,
  fontFamily: "montserrat !important",
  appearance: "none",
  border: "none",
  background: "linear-gradient(to right, #FF6690,  #00CDFF)",
  outline: "none",
  cursor: "pointer",
  display: "inline-block",
  textDecoration: "none",
  padding: "15px 50px",
  color: "#ffffff",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "14px",
  transition: "all 0.3s ease-in-out",
  borderRadius: "50px",

  lineHeight: "normal",
  textTransform: "uppercase",
  animation: "pulseanime 3s infinite",
  boxShadow: "0 0 0 rgba(229, 71, 80, 0.4)",
  "@media (max-width: 768px)": {
    padding: "10px",
    fontSize: "14px",
  },
  marginTop: "10px",
  height: "50px",
};

const Logo = ({ darkMode, lr }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <a href={`/`} className="logo cursor-pointer" style={{ marginLeft: "0px" }}>
      <img
        ref={lr}
        src={darkMode ? appData.darkLogo : appData.lightLogo}
        style={{
          width: isMobile ? "37px" : "57px",
          height: isMobile ? "37px" : "57px",
        }}
        alt="logo"
        priority="true"
      />
    </a>
  );
};

const Navbar = ({ lr, nr, dark, isHomePage = false }) => {
  const isLocalStorageAvailable = typeof localStorage !== "undefined";

  const [darkMode, setDarkMode] = useState(() => {
    if (isLocalStorageAvailable) {
      const savedMode = localStorage.getItem("darkMode");
      return savedMode !== null ? JSON.parse(savedMode) : false;
    }
    return false;
  });

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  // In the navbar component, add a new state to track scroll position
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // Set loading to false after component mounts
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (isLocalStorageAvailable) {
      localStorage.setItem("darkMode", JSON.stringify(darkMode));
    }
    dark(darkMode); // Synchronize with parent component
  }, [darkMode, dark, isLocalStorageAvailable]);

  useEffect(() => {
    dark(darkMode); // Ensure initial synchronization with parent component
  }, [darkMode, dark]);

  useEffect(() => {
    // Apply cursor-pointer effect to dropdown, logo, and mode icon
    const dropdownToggle = document.querySelector(".nav-item.dropdown");
    const dropdownMenu = document.querySelector(".dropdown-menu");
    const logo = document.querySelector(".logo");
    const modeIcon = document.querySelector(".mode-icon");

    const addCursorPointer = (elements) => {
      elements.forEach((el) => el?.classList?.add("cursor-pointer"));
    };

    const removeCursorPointer = (elements) => {
      elements.forEach((el) => el?.classList?.remove("cursor-pointer"));
    };

    const handleDropdownToggle = () => {
      setTimeout(() => {
        const dropdownItems = document.querySelectorAll(".dropdown-item");
        if (dropdownMenu?.classList?.contains("show")) {
          addCursorPointer(
            [...dropdownItems, dropdownToggle, logo, modeIcon].filter(Boolean)
          );
        } else {
          removeCursorPointer([...dropdownItems].filter(Boolean));
        }
      }, 0);
    };

    // Initial application of cursor-pointer
    addCursorPointer([dropdownToggle, logo, modeIcon].filter(Boolean));

    if (dropdownToggle) {
      dropdownToggle.addEventListener("click", handleDropdownToggle);
    }

    return () => {
      if (dropdownToggle) {
        dropdownToggle.removeEventListener("click", handleDropdownToggle);
      }
      removeCursorPointer([dropdownToggle, logo, modeIcon].filter(Boolean));
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Close dropdown if click is outside
        const dropdownMenu = document.querySelector(".dropdown-menu");
        if (dropdownMenu) {
          dropdownMenu.classList.remove("show");
        }
        setIsDropdownOpen(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggle = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
    handleDropdown(e); // Original dropdown handler
  };

  // Modify the scroll effect to only apply when not on homepage
  useEffect(() => {
    const handleScroll = () => {
      if (!isHomePage) {
        // Only apply scroll effect if not homepage
        if (window.pageYOffset > 300) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isHomePage]);

  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg ${darkMode ? "dark" : "light"} ${
        isLoading ? "" : ""
      }`}
    >
      <div style={{ minWidth: "100%" }} className="container nomobile">
        <div
          className="row justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div
            className="col-lg-1"
            style={{ textAlign: "left", display: "flex" }}
          >
            <Logo darkMode={darkMode} lr={lr} />
          </div>
          <div
            className="col-lg-9"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent1"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ul
                className="navbar-nav  pl-5"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  // Only apply opacity/visibility styles if not homepage
                  opacity: isHomePage ? 1 : isScrolled ? 1 : 0,
                  transition: isHomePage ? "none" : "opacity 0.3s ease",
                  visibility: isHomePage
                    ? "visible"
                    : isScrolled
                    ? "visible"
                    : "hidden",
                }}
              >
                <IconButton
                  className="modeIcon cursor-pointer"
                  style={{
                    outline: "none",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "none",
                    color: "#9a9a9a",
                    marginLeft: "10px",
                  }}
                  onClick={handleToggle}
                  color="inherit"
                >
                  {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <li
                  ref={dropdownRef}
                  className="nav-item dropdown cursor-pointer"
                  onClick={handleDropdownClick}
                  style={{ position: "relative" }}
                >
                  <span
                    className="nav-link dropdown-toggle pulse_bt cursor-pointer"
                    data-toggle="dropdown"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={buttonStyles}
                  >
                    Get in Touch
                  </span>
                  <div
                    style={{
                      backgroundColor: "#0a0a0a",
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      width: "200px",
                      top: "100%",
                      marginTop: "5px",
                    }}
                    className="dropdown-menu"
                  >
                    <a
                      href="https://calendly.com/meet-xdstudios/discovery"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a
                        style={{ fontWeight: "500px" }}
                        className="dropdown-item cursor-pointer"
                      >
                        BOOK A MEETING
                      </a>
                    </a>
                    <a href="/deck" target="_blank" rel="noopener noreferrer">
                      <a
                        style={{ fontWeight: "500px" }}
                        className="dropdown-item"
                      >
                        INTRO DECK
                      </a>
                    </a>
                    <a href="/cv" target="_blank" rel="noopener noreferrer">
                      <a
                        style={{ fontWeight: "500px" }}
                        className="dropdown-item"
                      >
                        DOWNLOAD CV
                      </a>
                    </a>
                    <a
                      href="https://www.linkedin.com/in/javed-a-ali/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <a
                        style={{ fontWeight: "500px" }}
                        className="dropdown-item"
                      >
                        LINKEDIN CONNECT
                      </a>
                    </a>
                    {/* <Link href={`/indexcopypage`}>
                      <a
                        style={{ fontWeight: "500px" }}
                        className="dropdown-item"
                      >
                        INDEX COPY PAGE
                      </a>
                    </Link> */}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container nodesktop"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          gap: "70px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            // gap: "10px",
          }}
        >
          <Logo darkMode={darkMode} lr={lr} />

          <IconButton
            className="cursor-pointer"
            style={{
              outline: "none",
              width: "50px",
              height: "50px",
              backgroundColor: "none",
            }}
            onClick={handleToggle}
            color="inherit"
          >
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </div>

        <li
          ref={dropdownRef}
          className="nav-item dropdown cursor-pointer"
          onClick={handleDropdownClick}
          style={{
            listStyle: "none",
            margin: 0,
            padding: 0,
            display: "inline-block",
          }}
        >
          <span
            className="nav-link dropdown-toggle pulse_bt cursor-pointer"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            style={{
              ...buttonStyles,
              // Mobile-specific adjustments
              padding: "7px 20px",
              fontSize: "12px",
              width: "auto",
              marginRight: "20px !important",
              height: "32px",
            }}
          >
            Get in Touch
          </span>
          <div
            style={{
              backgroundColor: "#0a0a0a",
              position: "absolute",
              left: "25%",
              transform: "translateX(-50%)",
              width: "200px",
              top: "100%",
              marginTop: "5px",
              zIndex: 1000,
            }}
            className="dropdown-menu"
          >
            <a
              href="https://calendly.com/meet-xdstudios/discovery"
              target="_blank"
              rel="noopener noreferrer"
            >
              <a
                style={{ fontWeight: "500px" }}
                className="dropdown-item cursor-pointer"
              >
                BOOK A MEETING
              </a>
            </a>
            <a href="/deck" target="_blank" rel="noopener noreferrer">
              <a style={{ fontWeight: "500px" }} className="dropdown-item">
                INTRO DECK
              </a>
            </a>
            <a href="/cv" target="_blank" rel="noopener noreferrer">
              <a style={{ fontWeight: "500px" }} className="dropdown-item">
                DOWNLOAD CV
              </a>
            </a>
            <a
              href="https://www.linkedin.com/in/javed-a-ali/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <a style={{ fontWeight: "500px" }} className="dropdown-item">
                LINKEDIN CONNECT
              </a>
            </a>
          </div>
        </li>
      </div>
    </nav>
  );
};

export default Navbar;

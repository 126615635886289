import React from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import { dockerData } from "../../../data/case-studies/data";
import ProjectIntro from "../../Components/case-studies/project-intro";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import HomeServices4 from "../../Components/Services4/product-design2";
import MobileCarousel from "../../Components/carousel/mobileCarousel";
import CompareSlider from "../../Components/carousel/comparisonCarousel";
import Screenshots from "../../Components/Screenshots/screenshots";
const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const videoMobileGallery = [
    {
      type: "img",
      alt: "01",
      src: "/assets/case-studies/case-studies/docker/new/600-1.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "02",
      src: "/assets/case-studies/case-studies/docker/new/600-3.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "03",
      src: "/assets/case-studies/case-studies/docker/new/600-5.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "04",
      src: "/assets/case-studies/case-studies/docker/new/600-7.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "05",
      src: "/assets/case-studies/case-studies/docker/new/600-8.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "06",
      src: "/assets/case-studies/case-studies/docker/new/600-9.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "07",
      src: "/assets/case-studies/case-studies/docker/new/600-10.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "08",
      src: "/assets/case-studies/case-studies/docker/new/600-11.png",
      width: "1000px",
      height: "auto",
    },
    {
      type: "img",
      alt: "09",
      src: "/assets/case-studies/case-studies/docker/new/600-12.png",
      width: "1000px",
      height: "auto",
    },
  ];

  const role = ["Senior Product /", <br key="role-br" />, "Brand  Designer"];
  const team = [
    "2 Designers",
    <br key="team-br1" />,
    "3 Marketing",
    <br key="team-br2" />,
    "1 PMs",
    <br key="team-br3" />,
    "2 Directors",
    <br key="team-br2" />,
    "1 Admin",
    <br key="team-br3" />,
  ];
  const products = [
    "Accessible Design System /",
    <br key="products-br1" />,
    "Responsive Web Apps /",
    <br key="products-br3" />,
    "Partner Portals /",
    <br key="products-br4" />,
    "Developer Portals",
    <br key="products-br5" />,
  ];
  const skills = [
    "User Research /",
    <br key="skills-br1" />,
    "Information Architecture /",
    <br key="skills-br2" />,
    "Prototyping /",
    <br key="skills-br3" />,
    "Visual Design /",
    <br key="skills-br4" />,
    "Interaction Design /",
    <br key="skills-br5" />,
    "Pattern Library /",
    <br key="skills-br6" />,
    "Design Styleguide /",
    <br key="skills-br7" />,
    "Front-end Development /",
    <br key="skills-br8" />,
  ];
  const tools = [
    "Adobe CC",
    <br key="tools-br1" />,
    "Invision",
    <br key="tools-br2" />,
    "Paper and Pencil",
    <br key="tools-br3" />,
    "Sketch",
  ];

  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/1.gif",
      title: "WHAT",
      content: [
        "Decreased Revenue",
        <br key={`br-3-1`} />,
        "Increased Costs",
        <br key={`br-3-2`} />,
        "High Support Cases",
        <br key={`br-3-3`} />,
        "Design Debt",
        <br key={`br-3-4`} />,
        "Technical Debt",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/2.gif",
      title: "WHO",
      content: [
        "Design Silos",
        <br key="br1-1" />,
        "Distributed Teams",
        <br key="br1-2" />,
        "Lack of Governance",
        <br key="br1-3" />,
        "Lack of Resources",
        <br key="br1-4" />,
        "Major Backlogs",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/3.gif",
      title: "WTF",
      content: [
        "Sub-par User Experience",
        <br key="br2-1" />,
        "Fragmented Channels",
        <br key="br2-2" />,
        "Manual Processes",
        <br key="br2-3" />,
        "Lower Productivity",
        <br key="br2-4" />,
        "Missed Opportunities",
      ],
    },
  ];

  const gallery = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/docker/new/lp-1.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/docker/new/lp-2.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/docker/new/lp-3.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/docker/new/lp-4.png",
      width: "1000px",
      height: "auto",
    },
  ];
  const gallery2 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/docker/new/600-1.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/docker/new/600-3.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/docker/new/600-5.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/docker/new/600-7.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/docker/new/600-8.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/docker/new/600-9.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/docker/new/600-10.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/docker/new/600-11.png",
      width: "1000px",
      height: "auto",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/docker/new/600-12.png",
      width: "1000px",
      height: "auto",
    },
  ];

  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "2M+",
      content: ["Docker Technical Partners"],
    },
    {
      id: 1,

      title: "$M",
      content: ["Revenue boost"],
    },
    {
      id: 2,

      title: "GTM",
      content: ["Increased conversion"],
    },
  ];

  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#1a1a1a"}
          projectHeaderData={ProjectDate}
          headerVideo={"/videos/case-studies/DOCKER/docker.mp4"}
          company="DOCKER, INC."
          role={role}
          // {/* role="Senior UI / UX  Designer"*/}
          tenure="2017"
          team={team}
          products={products}
          skills={skills}
          tools={tools}
          mode={mode}
        />
        {/* <HomeServices4 data={threeBoxData} /> */}

        {/* <Services4 style="4item" /> */}
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#252532" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Brand Communications"
          videoSrc="/videos/case-studies/DOCKER/docker-1.mp4"
        />

        {/* <HomeServices4
          header="{ recognition }"
          subheader="Industry Honors"
          data={threeBoxData2}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          cardHeight="530px"
        /> */}
        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Landing Pages"
          gallery={gallery}
          size="1000px"
        />

        <MobileCarousel
          heading="DELIVERABLES"
          subheading="Email Campaigns"
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          items={videoMobileGallery}
          color={mode == true ? "#252532" : "#f4f4f4"}
        />
        <CompareSlider
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Delightful Enhancements"
          img1="/assets/case-studies/case-studies/docker/new/before.jpg"
          img2="/assets/case-studies/case-studies/docker/new/after.jpg"
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#252532" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Iconography"
          videoSrc="/videos/case-studies/DOCKER/docker-2.mp4"
        />
        {dockerData.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#1b1d21" : "white"}
            color2={mode == true ? "#252532" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            video={item.video}
            image={item.image}
            imageCaption={item.caption}
          />
        ))}

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ProjectDetails2Header = ({
  projectHeaderData,
  headerVideo,
  company,
  role,
  tenure,
  team,
  products,
  skills,
  tools,
  color,
  textColor,
  videobg,
  mode,
  hideProjectDetails,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const headerRef = useRef(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null; // or return a loading placeholder
  }

  return (
    <React.Fragment>
      <section
        ref={headerRef}
        style={{ backgroundColor: "transparent" }}
        className="blog-pg single"
      >
        {headerVideo && (
          <div className="container-fluid" style={{ padding: 0 }}>
            <div
              style={{ margin: "0px" }}
              className="row justify-content-center"
            >
              <div className="col-lg-12" style={{ padding: 0 }}>
                <div className="post">
                  <div
                    className="img"
                    style={{
                      position: "relative",
                      width: "100%",
                      marginBottom: "-7px",
                    }}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      className="vdo"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <source src={headerVideo} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {!hideProjectDetails && (
        <section
          style={{ backgroundColor: color }}
          className="proj-det bg-img parallaxie valign"
        >
          <div className="container">
            <div style={{ margin: "120px 0" }} className="col">
              <div className="row justify-content-center">
                <div className="col-lg-10 col-md-10">
                  <div className="sec-head text-center">
                    <h6
                      style={{
                        color: "#FF6690",
                        textTransform: "none",
                        fontFamily: "machina",
                        fontSize: "18px",
                        marginBottom: "10px",
                        textTransform: "none",
                        fontWeight: "500",
                      }}
                      className="wow fadeIn"
                      data-wow-delay=".5s"
                    >
                      {"{ company }"}
                    </h6>
                    <h3
                      style={{
                        fontFamily: "machina bold",
                        color: mode ? "white" : "black",
                      }} // Use mode to set text color
                      className="wow "
                    >
                      {company}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="row nomobile">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      ROLE
                    </h6>
                    <p>
                      <Link href={"#"}>
                        <a
                          style={{
                            color: mode ? "white" : "black",
                            fontWeight: "500",
                          }}
                        >
                          {role}
                        </a>
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TENURE
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {tenure}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TEAM
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {team}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      PRODUCTS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {products}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      SKILLS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {skills}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TOOLS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {tools}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "center" }} className="row nodesktop">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      ROLE
                    </h6>
                    <p>
                      <Link href={"#"}>
                        <a
                          style={{
                            color: mode ? "white" : "black",
                            fontWeight: "500",
                          }}
                        >
                          {role}
                        </a>
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TENURE
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {tenure}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TEAM
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {team}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      PRODUCTS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {products}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      SKILLS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {skills}
                    </p>
                  </div>
                </div>
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <div className="item mt-30">
                    <h6
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        color: "#6a6a6a",
                      }}
                    >
                      TOOLS
                    </h6>
                    <p
                      style={{
                        color: mode ? "white" : "black",
                        fontWeight: "500",
                      }}
                    >
                      {tools}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div
        style={{
          marginBottom: "100px",
          backgroundColor: mode ? "black" : "white",
          position: "absolute",
        }}
      />
    </React.Fragment>
  );
};

export default ProjectDetails2Header;

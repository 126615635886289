import { createBrowserRouter } from "react-router-dom";
import "./index.css";
import HomeCopyPage from "./pages/home";
import CairCaseStudy from "./pages/home/case-studies/cair";
import SerpapiCaseStudy from "./pages/home/case-studies/serpapi";
import UrbanFoodyCaseStudy from "./pages/home/case-studies/urban-foody";
import ClomrCaseStudy from "./pages/home/case-studies/clomr";
import LunchboxCaseStudy from "./pages/home/case-studies/lunchbox";
import VmwareCaseStudy from "./pages/home/case-studies/vmware";
import FeelingBlessedCaseStudy from "./pages/home/case-studies/feeling-blessed";
import CrunchyrollCaseStudy from "./pages/home/case-studies/crunchyroll";
import DockerCaseStudy from "./pages/home/case-studies/docker";
import VideoPage2 from "./pages/home/lottie";
import VideoPage from "./pages/home/videos";
import Deck from "./pages/home/case-studies/deck";
import CV from "./pages/home/case-studies/cv";

const Router = createBrowserRouter([
  {
    path: "/",
    element: <HomeCopyPage />,
  },
  {
    path: "/cair",
    element: <CairCaseStudy />,
  },
  {
    path: "/serpapi",
    element: <SerpapiCaseStudy />,
  },
  {
    path: "/urban-foody",
    element: <UrbanFoodyCaseStudy />,
  },
  {
    path: "/clomr",
    element: <ClomrCaseStudy />,
  },
  {
    path: "/lunchbox",
    element: <LunchboxCaseStudy />,
  },
  {
    path: "/vmware",
    element: <VmwareCaseStudy />,
  },
  {
    path: "/feeling-blessed",
    element: <FeelingBlessedCaseStudy />,
  },
  {
    path: "/crunchyroll",
    element: <CrunchyrollCaseStudy />,
  },
  {
    path: "/docker",
    element: <DockerCaseStudy />,
  },
  {
    path: "/deck",
    element: <Deck />,
  },
  {
    path: "/cv",
    element: <CV />,
  },
  {
    path: "/lottie",
    element: <VideoPage2 />,
  },
  {
    path: "/videos",
    element: <VideoPage />,
  },
]);

export default Router;

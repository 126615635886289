import React, { useEffect } from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import { clomr } from "../../../data/case-studies/data";
import ProjectIntro from "../../Components/case-studies/project-intro";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import HomeServices4 from "../../Components/Services4/product-design2";
import MobileCarousel from "../../Components/carousel/mobileCarousel";
import Screenshots from "../../Components/Screenshots/screenshots";
import Cursor from "../../Components/cursor";
const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const gallery = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/clomr/new/web-get-started.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/clomr/new/cases-preloader.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/clomr/new/cases-list.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/clomr/new/case-patient-detail.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/clomr/new/patient-preloader.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/clomr/new/patient-new.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/clomr/new/upload-pdf.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/clomr/new/upload-2.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/clomr/new/upload-3.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/clomr/new/upload-review.png",
    },
    {
      alt: "11",
      src: "/assets/case-studies/case-studies/clomr/new/upload-bulk.png",
    },
  ];
  const gallery2 = [
    {
      type: "img",
      alt: "01",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-get-started.png",
    },
    {
      type: "img",
      alt: "02",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-preloader.png",
    },
    {
      type: "img",
      alt: "03",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-home.png",
    },
    {
      type: "img",
      alt: "04",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-add-patient.png",
    },
    {
      type: "img",
      alt: "05",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-review-profile.png",
    },
    {
      type: "img",
      alt: "06",
      src: "/assets/case-studies/case-studies/clomr/new/mobile-cases-list.png",
    },
  ];

  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/1.gif",
      title: "WHAT",
      content: [
        "Decreased Revenue",
        <br key={`br-3-1`} />,
        "Increased Costs",
        <br key={`br-3-2`} />,
        "High Support Cases",
        <br key={`br-3-3`} />,
        "Design Debt",
        <br key={`br-3-4`} />,
        "Technical Debt",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/2.gif",
      title: "WHO",
      content: [
        "Design Silos",
        <br key="br1-1" />,
        "Distributed Teams",
        <br key="br1-2" />,
        "Lack of Governance",
        <br key="br1-3" />,
        "Lack of Resources",
        <br key="br1-4" />,
        "Major Backlogs",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/3.gif",
      title: "WTF",
      content: [
        "Sub-par User Experience",
        <br key="br2-1" />,
        "Fragmented Channels",
        <br key="br2-2" />,
        "Manual Processes",
        <br key="br2-3" />,
        "Lower Productivity",
        <br key="br2-4" />,
        "Missed Opportunities",
      ],
    },
  ];

  const team = [
    "Founders",
    <br key="team-br1" />,
    "Executive Leadership",
    <br key="team-br1" />,
    "Design",
    <br key="team-br2" />,
    "Engineering",
    <br key="team-br3" />,
    "Marketing",
  ];
  const products = [
    "Accessible Design System",
    <br key="skill-br1" />,
    "React Web App",
    <br key="skill-br2" />,
    "React Native Mobile Apps",
  ];

  const skills = [
    "Research",
    <br key="skills-br1" />,
    "Early Concepting",
    <br key="skills-br2" />,
    "UX Design",
    <br key="skills-br3" />,
    "Visual Design",
    <br key="skills-br4" />,
    "Interaction Design",
    <br key="skills-br4" />,
    "Prototyping",
    <br key="skills-br4" />,
    "Motion Design",
    <br key="skills-br4" />,
    "Front-end Development",
  ];
  const tools = [
    "Figma",
    <br key="tools-br1" />,
    "Adobe CC",
    <br key="tools-br1" />,
    "Illustrator",
    <br key="tools-br3" />,
    "Premier Pro",
    <br key="tools-br4" />,
    "After Effects",
    <br key="tools-br5" />,
    "Lottie",
    <br key="tools-br6" />,
  ];

  const videoMobileGallery = [
    {
      type: "img",
      alt: "01",
      src: "/assets/case-studies/case-studies/clomr/mobile/1.png",
    },
    {
      type: "img",
      alt: "02",
      src: "/assets/case-studies/case-studies/clomr/mobile/2.png",
    },
    {
      type: "img",
      alt: "03",
      src: "/assets/case-studies/case-studies/clomr/mobile/3.png",
    },
    {
      type: "img",
      alt: "04",
      src: "/assets/case-studies/case-studies/clomr/mobile/4.png",
    },
    {
      type: "img",
      alt: "05",
      src: "/assets/case-studies/case-studies/clomr/mobile/5.png",
    },
    {
      type: "img",
      alt: "06",
      src: "/assets/case-studies/case-studies/clomr/mobile/6.png",
    },
  ];

  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "7x",
      content: ["Improved business efficiency"],
    },
    {
      id: 1,

      title: "45%",
      content: ["Acceleration of new features"],
    },
    {
      id: 2,

      title: "33%",
      content: ["Reduced development time"],
    },
  ];
  return (
    <DarkTheme>
      <Cursor />

      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#000000"}
          projectHeaderData={ProjectDate}
          headerVideo={"/videos/case-studies/CLOMR/clomr.mp4"}
          company="CLOMR, INC."
          role="Head of Product Design"
          tenure="2022 - 2024  "
          team={team}
          products={products}
          skills={skills}
          tools={tools}
          mode={mode}
        />
        {/* <Video2 link="/videos/case-studies/CLOMR/clomr-web.mp4"/> */}

        {/* <HomeServices4 data={threeBoxData} /> */}
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="AI-powered React Web App"
          gallery={gallery}
        />
        <MobileCarousel
          heading="deliverables"
          subheading="React Native Mobile App"
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          items={gallery2}
          color={mode == true ? "#252532" : "#eaeaea"}
        />

        {/* <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          color={mode == true ? "#252532" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="campaigns"
          gallery={gallery2}
          size="320px"
        /> */}

        {/* <Services4 style="4item" /> */}
        {/* <Video2 videoID={"ADDQ-trAJ3o"} /> */}
        {/* <VideoCarousel items={videoGallery} /> */}
        {/* <NextProject /> */}
        <MobileCarousel
          heading="deliverables"
          subheading="Brand Communications"
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          items={videoMobileGallery}
          color={mode == true ? "#252532" : "#f4f4f4"}
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/CLOMR/clomr-2.mp4"
        />
        {clomr.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#1b1d21" : "white"}
            color2={mode == true ? "#252532" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            image={item.image}
            video={item.video}
            imageCaption={item.caption}
          />
        ))}
        {/* <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/CLOMR/clomr-app update.mp4"
        /> */}

        {/* <HomeServices4
          header="{ recognition }"
          subheader="Industry Honors"
          data={threeBoxData2}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          cardHeight="530px"
        /> */}

        {/* <MobileCarousel items={videoMobileGallery2} color="#1b1d21" /> */}

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

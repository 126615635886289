import React from "react";
import ProjectIntroduction from "../Project-introduction/project-introduction";
import ScrollableVideo from "../carousel/scrollableVideo";

const ProjectIntro = ({
  projectIntroductionData,
  spanStyle,
  image,
  imageCaption,
  image2,
  color,
  color2,
  textColor,
  video,
}) => {
  return (
    <React.Fragment>
      <div style={{ padding: "0 0 0 0", backgroundColor: color }}>
        <ProjectIntroduction
          // color={color}
          textColor={textColor}
          projectIntroductionData={projectIntroductionData}
        />
      </div>
      {/* <ScrollableVideo /> */}
      <div
        style={{
          // paddingTop: "120px",
          // paddingBottom: "120px",
          backgroundColor: color2,
        }}
      >
        {video && (
          <video
            style={{
              width: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            src={video}
            autoPlay
            muted
            loop
            playsInline
            controls={false}
          />
        )}
        {image && (
          <React.Fragment>
            <img
              style={{
                width: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                // borderRadius:"40px"
              }}
              src={image}
              alt=""
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* {imageCaption ? <span style={spanStyle}>{imageCaption}</span> : null} */}
            </div>
          </React.Fragment>
        )}
        {image2 && (
          <React.Fragment>
            <img
              style={{
                width: "1000px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              src={image2}
              alt=""
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* {imageCaption ? <span style={spanStyle}>{imageCaption}</span> : null} */}
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ProjectIntro;

import React from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import { vmwareData } from "../../../data/case-studies/data";
import ProjectIntro from "../../Components/case-studies/project-intro";
import HomeServices4 from "../../Components/Services4/product-design2";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import Screenshots from "../../Components/Screenshots/screenshots";
const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const gallery1 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/VMWARE/New/12.jpg",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/VMWARE/New/13.jpg",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/VMWARE/New/15.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/VMWARE/New/library.png",
    },
  ];

  const gallery2 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/1.jpg",
      width: "1000px;",
    },

    {
      alt: "03",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/3.jpg",
    },

    {
      alt: "07",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/7.jpg",
    },

    {
      alt: "10",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/10.jpg",
    },

    {
      alt: "13",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/13.jpg",
    },
    {
      alt: "14",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/14.jpg",
    },

    {
      alt: "22",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/22.jpg",
    },

    {
      alt: "24",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/24.jpg",
    },
    {
      alt: "25",
      src: "/assets/case-studies/case-studies/VMWARE/New/1/25.jpg",
    },
  ];

  const gallery3 = [
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/VMWARE/New/3/4a.jpg",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/VMWARE/New/3/4b.jpg",
    },
  ];

  const videoMobileGallery = [
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
  ];

  const videoGallery = [
    {
      alt: "01",
      src: "/videos/case-studies/VMWARE/vmware wall.mp4",
    },
    {
      alt: "02",
      src: "/videos/case-studies/VMWARE/vmware.mp4",
    },
    {
      alt: "03",
      src: "/videos/case-studies/VMWARE/vmware1.mp4",
    },
    {
      alt: "04",
      src: "/videos/case-studies/VMWARE/vmware2.mp4",
    },
  ];

  const role = ["Senior UI /", <br key="role-br" />, "UX  Designer"];
  const team = [
    "7 Design",
    <br key="team-br1" />,
    "4 Marketing",
    <br key="team-br2" />,
    "3 Sales",
    <br key="team-br3" />,
    "7 Programs",
    <br key="team-br4" />,
    "3 Solutions",
    <br key="team-br5" />,
    "2 Incentives",
    <br key="team-br6" />,
    "7 Readiness",
    <br key="team-br7" />,
    "3 Support",
    <br key="team-br8" />,
    "7 Engineering",
    <br key="team-br9" />,
    "3 IT",
    <br key="team-br10" />,
  ];
  const products = [
    "B2B SaaS /",
    <br key="products-br1" />,
    "Accessible Design System /",
    <br key="products-br2" />,
    "Responsive Web Apps /",
    <br key="products-br3" />,
    "Mobile Apps /",
    <br key="products-br4" />,
    "LMS + CMS /",
    <br key="products-br5" />,
    "Partner Portals /",
    <br key="products-br6" />,
    "Developer Portals /",
  ];
  const skills = [
    "Research /",
    <br key="skills-br1" />,
    "Early Concepting /",
    <br key="skills-br2" />,
    "UX Design /",
    <br key="skills-br3" />,
    "Visual Design /",
    <br key="skills-br4" />,
    "Interaction Design /",
    <br key="skills-br4" />,
    "Prototyping /",
    <br key="skills-br4" />,
    "Gamification /",
    <br key="skills-br4" />,
    "Motion Design /",
  ];
  const tools = [
    "Adobe CC",
    <br key="tools-br1" />,
    "Photoshop",
    <br key="tools-br2" />,
    "Illustrator",
    <br key="tools-br3" />,
    "Axure",
    <br key="tools-br4" />,
    "Omnigraffle",
    <br key="tools-br5" />,
    "Sketch",
    <br key="tools-br6" />,
  ];

  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/1.gif",
      title: "WHAT",
      content: [
        "Decreased Revenue",
        <br key={`br-3-1`} />,
        "Increased Costs",
        <br key={`br-3-2`} />,
        "High Support Cases",
        <br key={`br-3-3`} />,
        "Design Debt",
        <br key={`br-3-4`} />,
        "Technical Debt",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/2.gif",
      title: "WHY",
      content: [
        "Design Silos",
        <br key="br1-1" />,
        "Distributed Teams",
        <br key="br1-2" />,
        "Lack of Governance",
        <br key="br1-3" />,
        "Lack of Resources",
        <br key="br1-4" />,
        "Major Backlogs",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/3.gif",
      title: "WTF",
      content: [
        "Sub-par User Experience",
        <br key="br2-1" />,
        "Fragmented Channels",
        <br key="br2-2" />,
        "Manual Processes",
        <br key="br2-3" />,
        "Lower Productivity",
        <br key="br2-4" />,
        "Missed Opportunities",
      ],
    },
  ];

  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "80%",
      content: ["Increased CSAT score"],
    },
    {
      id: 1,

      title: "50%",
      content: ["Increased Q/Q sales"],
    },
    {
      id: 2,

      title: "57 days",
      content: ["Decreased time to transact"],
    },
    {
      id: 3,

      title: "65%",
      content: ["Increased click-through rate"],
    },
    {
      id: 1,

      title: "26%",
      content: ["Increased click counts"],
    },
    {
      id: 2,

      title: "21%",
      content: ["Increased search relevancy"],
    },
    {
      id: 3,

      title: "10,000+",
      content: ["Increased training per quarter"],
    },
    {
      id: 1,

      title: "50%",
      content: ["Decreased support cases"],
    },
    {
      id: 2,

      title: "$33,600",
      content: [" Savings per pattern"],
    },
  ];
  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#1a1a1a"}
          projectHeaderData={ProjectDate}
          headerVideo={"https://javed.io/videos/case-studies/VMWARE/vmware.mp4"}
          company="VMWARE, INC."
          role={role}
          // {/* role="Senior UI / UX  Designer"*/}
          tenure="2012 - 2016"
          team={team}
          products={products}
          skills={skills}
          tools={tools}
          mode={mode}
        />
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#252532" : "white"}
          cardColor={mode == true ? "#1b1d21" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <HomeServices4
          data={threeBoxData}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          cardColor={mode == true ? "#252532" : "white"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />

        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#252532" : "white"}
          heading="DELIVERABLES"
          subheading="Partner Portals"
          gallery={gallery1}
          size="1000px"
        />
        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Developer Portal"
          gallery={gallery2}
          size="1000px"
        />
        <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#252532" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Renewals Center"
          gallery={gallery3}
          size="1000px"
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Supporting Assets"
          videoSrc="/videos/case-studies/VMWARE/vmware-1.mp4"
        />

        {/* <Video videoSrc="/videos/javed.io-demo.mp4" /> */}

        {vmwareData.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#252532" : "white"}
            color2={mode == true ? "#1b1d21" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            image={item.image}
            video={item.video}
            imageCaption={item.caption}
          />
        ))}
        <HomeServices4
          header="{ recognition }"
          subheader="Industry Honors"
          data={threeBoxData2}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          cardColor={mode == true ? "#252532" : "#ffffff"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          cardHeight="530px"
        />

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

import React, { useRef, useState, useEffect, memo } from "react";
import Lottie from "react-lottie-player";
import define from "../../../data/slow/define.json";
import design from "../../../data/slow/design.json";
import discover from "../../../data/slow/discover.json";
import UnifiedStyles from "../home/unifiedstyles";

// Updated Animation styles
const fadeInAnimation = `
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
`;

// Updated Styles object
const styles = {
  serviceCard: (mode, isVisible, index) => ({
    backgroundColor: mode ? "#252532" : "#f4f4f4",
    height: "500px",
    borderRadius: "20px",
    paddingBottom: "40px",
    opacity: 0,
    animation: isVisible
      ? `fadeInUp 0.8s ease-out forwards ${0.3 + index * 0.2}s`
      : "none",
  }),
  title: (mode) => ({
    fontSize: "28px",
    fontFamily: "machina",
    marginTop: "30px",
    color: mode ? "white" : "black",
  }),
  description: (mode) => ({
    fontSize: "19px",
    textAlign: "center",
    fontWeight: "500",
    lineHeight: "1.7em",
    color: mode ? "#a4a7b1" : "#1a1a1a",
    padding: "5px 20px 30px 20px",
    display: "-webkit-box",
    WebkitLineClamp: 4,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    height: "200px",
  }),
  lottieContainer: (isVisible, index) => ({
    opacity: 0,
    animation: isVisible
      ? `fadeInUp 0.8s ease-out forwards ${0.4 + index * 0.2}s`
      : "none",
  }),
  titleContainer: (isVisible, index) => ({
    opacity: 0,
    animation: isVisible
      ? `fadeInUp 0.8s ease-out forwards ${0.5 + index * 0.2}s`
      : "none",
  }),
  descriptionContainer: (isVisible, index) => ({
    opacity: 0,
    animation: isVisible
      ? `fadeInUp 0.8s ease-out forwards ${0.6 + index * 0.2}s`
      : "none",
  }),
};

// Services data
const servicesData = [
  {
    animation: discover,
    title: "Innovation",
    description: "I push boundaries and experiment with new technologies.",
  },
  {
    animation: define,
    title: "Inclusion",
    description:
      "I create equitable and accessible solutions, celebrating diversity. ",
  },
  {
    animation: design,
    title: "Impact",
    description:
      "I aim to make a tangible, positive difference in people's lives.",
  },
];

// Updated ServiceCard component
const ServiceCard = memo(({ service, mode, isVisible, index }) => (
  <div
    style={styles.serviceCard(mode, isVisible, index)}
    className="item-box no-curve"
  >
    <span className="icon1" style={styles.lottieContainer(isVisible, index)}>
      <Lottie
        loop
        animationData={service.animation}
        play={isVisible}
        rendererSettings={{
          preserveAspectRatio: "xMidYMid slice",
          progressiveLoad: true,
        }}
        className="col-8 m-auto"
      />
    </span>
    <br />
    <div style={styles.titleContainer(isVisible, index)}>
      <h6 style={styles.title(mode)}>{service.title}</h6>
    </div>
    <div style={styles.descriptionContainer(isVisible, index)}>
      <p style={styles.description(mode)}>{service.description}</p>
    </div>
  </div>
));

ServiceCard.displayName = "ServiceCard";

const Services4 = memo(({ mode }) => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <UnifiedStyles
      mode={mode}
      bgColor={mode ? "black" : "white"}
      title="creative.process"
      subtitle="Rooted in Innovation, Inclusion, and Impact"
      description="Design Philosophy"
    >
      <style>{fadeInAnimation}</style>
      <section ref={sectionRef} className="services hbox">
        <div className="container">
          <div className="row">
            {servicesData.map((service, index) => (
              <div key={index} className="col-lg-4">
                <ServiceCard
                  service={service}
                  mode={mode}
                  isVisible={isVisible}
                  index={index}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </UnifiedStyles>
  );
});

Services4.displayName = "Services4";

export default Services4;

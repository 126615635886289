import React from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import ProjectIntro from "../../Components/case-studies/project-intro";
import { cair } from "../../../data/case-studies/data";
import Screenshots from "../../Components/Screenshots/screenshots";
import Carousel from "../../Components/carousel/carousel";
import VideoCarousel from "../../Components/carousel/videocarousel";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import HomeServices4 from "../../Components/Services4/product-design2";
import MobileCarousel from "../../Components/carousel/mobileCarousel";

const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    const loadAdobeSDK = () => {
      document.addEventListener("adobe_dc_view_sdk.ready", function () {
        console.log("AdobeDC View SDK is ready");
        var adobeDCView = new window.AdobeDC.View({
          clientId: "89d082c22e3d4dabaab43f87fe1a238f",
          divId: "adobe-dc-view",
        });
        adobeDCView
          .previewFile(
            {
              content: {
                location: { url: "/2024-javed-cv.pdf.pdf" },
              },
              metaData: { fileName: "javed-cv-2024" },
            },
            { embedMode: "IN_LINE" }
          )
          .then(() => {
            console.log("PDF preview successful");
          })
          .catch((error) => {
            console.error("Error displaying PDF", error);
          });
      });
    };

    if (!document.getElementById("adobe-dc-view-sdk")) {
      const script = document.createElement("script");
      script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
      script.id = "adobe-dc-view-sdk";
      script.onload = loadAdobeSDK;
      document.body.appendChild(script);
    } else {
      loadAdobeSDK();
    }
  }, [navbarRef]);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const team = [
    "1 Executive Director",
    <br key="team-br1" />,
    "2 PMs",
    <br key="team-br2" />,
    "3 Lead Counsels",
  ];

  const tools = [
    "Adobe CC",
    <br key="tools-br1" />,
    "Invision",
    <br key="tools-br2" />,
    "Sketch",
    <br key="tools-br3" />,
    "Paper and Pencil",
  ];

  const skills = [
    "User Research",
    <br key="skills-br1" />,
    "Storyboarding",
    <br key="skills-br2" />,
    "Visual Design",
    <br key="skills-br3" />,
    "Editorial Styleguide",
    <br key="skills-br4" />,
    "Motion Design",
    <br key="skills-br5" />,
    "Print Design",
    <br key="skills-br6" />,
  ];

  const products = [
    "Responsive Websites /",
    <br key="sproducts-br1" />,
    "Interactive Charts /",
    <br key="products-br2" />,
    "Print PDFs /",
    <br key="products-br3" />,
    "Campaign Assets /",
    <br key="products-br4" />,
    "Animated Graphic Novel",
  ];
  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/cair/new/cair1.png",
      title: "Lorem ipsum",
      content: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/cair/new/cair2.png",
      title: "Lorem ipsum",
      content: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/cair/new/cair3.png",
      title: "Lorem ipsum",
      content: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed ",
      ],
    },
  ];

  const videoMobileGallery = [
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
  ];
  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "80%",
      content: ["Increased CSAT score"],
    },
    {
      id: 1,

      title: "50%",
      content: ["Increased Q/Q sales"],
    },
    {
      id: 2,

      title: "57 days",
      content: ["Decreased time to transact"],
    },
  ];

  const standardImageStyle = {
    width: "800px",
    height: "600px",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  };

  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        s
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#1a1a1a"}
          projectHeaderData={ProjectDate}
          headerVideo={"/videos/case-studies/CAIR/cair wall2.mp4"}
          company="CAIR CALIFORNIA"
          role="Product Design Consultant"
          tenure="2019 - 2022"
          team={team}
          products={products}
          tools={tools}
          mode={mode}
          skills={skills}
        />
        {/* <Video2 link="/videos/case-studies/CAIR/cair-vid.mp4"/> */}
        <HomeServices4
          data={threeBoxData}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          color={mode == true ? "#1b1d21" : "white"}
        />
        {cair.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#252532" : "white"}
            color2={mode == true ? "#1b1d21" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            image={item.image}
            image2={item.image2 ? item.image2 : null}
            imageCaption={item.caption}
            video={item.video}
          />
        ))}
        {/* <Video videoSrc="/videos/javed.io-demo.mp4" /> */}
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <img
          style={standardImageStyle}
          src="/assets/case-studies/case-studies/VMWARE/VM1.png"
          alt=""
        />
        <HomeServices4
          header="{ recognition }"
          subheader="Industry Honors"
          data={threeBoxData2}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          cardHeight="530px"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: mode == true ? "#252532" : "white",
          }}
        >
          <div id="adobe-dc-view" style={standardImageStyle}></div>
          <br />
          <br />
          <br />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: mode == true ? "#1b1d21" : "white",
          }}
        >
          <div id="adobe-dc-view" style={standardImageStyle}></div>
          <br />
          <br />
          <br />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: mode == true ? "#252532" : "white",
          }}
        >
          <div id="adobe-dc-view" style={standardImageStyle}></div>
          <br />
          <br />
          <br />
        </div>
        <MobileCarousel
          heading="Campaigns"
          subheading="Campaigns"
          items={videoMobileGallery}
          textColor={mode == true ? "white" : "#4a4a4a"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
        />
        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

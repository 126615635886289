import React from "react";
import { Link } from "react-router-dom";

const PageHeader = ({ title, paragraph, className, link, theme }) => {
  return (
    <section
      style={{ paddingTop: "90px" }}
      className={`page-header ${className && className}`}
    >
      <div className="container pt-0">
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9">
            <div className="text-center">
              <h1
                style={{
                  fontFamily: "machina bold",
                  textTransform: "capitalize",
                  color: theme ? "#fff" : "#1a1a1a",
                }}
                className="mb-10 color-font"
              >
                {title.toLowerCase()}
              </h1>
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: theme ? "#fff" : "#1a1a1a",
                }}
              >
                {paragraph}
              </p>
            </div>
            {link && (
              <div className="row">
                <div className="col-md-12 col-lg-12 pt-60">
                  <div className="content text-center sm-mb30">
                    <h2 className="wow words chars">
                      <Link to={link} target="_blank" rel="noopener noreferrer">
                        <b className="back-color">READ FULL CASE STUDY</b>
                      </Link>
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PageHeader;

import React, { useEffect } from "react";
import featuresData from "../../../data/sections/home.json";

import { useInView } from "react-intersection-observer";
import "animate.css";

const Services4 = ({
  serviceMB50,
  data,
  color,
  cardColor,
  textColor,
  textColor2,
  header,
  subheader,
  cardHeight,
}) => {
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set initial value
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      style={{
        backgroundColor: color,
        // margin: "120px 0",
        // paddingBottom: "0px",
      }}
      className="services hbox section-padding"
    >
      <div className="container">
        {header && (
          <div className="row justify-content-center">
            <div className="col-lg-12 col-md-10">
              <div className="sec-head  text-center">
                <h6
                  style={{
                    color: "#ff6690",
                    textTransform: "lowercase",
                    wordSpacing: "5px",
                    fontFamily: "machina",
                  }}
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                >
                  {header}
                </h6>
                <h3
                  style={{ color: "#a4a7b1", fontFamily: "machina bold" }}
                  className="wow "
                >
                  {subheader}
                </h3>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          {data.map((item, index) => (
            <Card
              key={item.id}
              item={item}
              index={index}
              serviceMB50={serviceMB50}
              cardColor={cardColor}
              isMobile={isMobile}
              cardHeight={cardHeight}
              textColor={textColor}
              textColor2={textColor2}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const Card = ({
  item,
  index,
  serviceMB50,
  cardColor,
  isMobile,
  cardHeight,
  textColor,
  textColor2,
}) => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const translateY = inView ? "0px" : `${index * 20}px`;
  const opacity = inView ? 1 : 0;

  return (
    <div
      ref={ref}
      className="col-lg-4 wow fadeInLeft"
      data-wow-delay={index == 0 ? ".5s" : index == 1 ? ".7s" : ".9s"}
      style={{
        margin: "10px 0",
        transform: `translateY(${translateY})`,
        opacity: opacity,
        transition: "transform 0.6s ease, opacity 0.6s ease",
      }}
    >
      <div
        style={{
          backgroundColor: cardColor,
          height: isMobile ? "auto" : cardHeight ? cardHeight : "auto",
          margin: "10px 0",
        }}
        className={`item-box no-curve ${
          serviceMB50 && index + 1 != featuresData.length - 1 ? "mb-50" : ""
        }`}
      >
        {item.icon && (
          <>
            <img style={{ width: "60%" }} src={item.icon} alt={item.title} />
            <br />
            <br />
            <br />
          </>
        )}
        <h6
          style={{
            fontSize: "28px",
            color: textColor,
            fontFamily: "machina bold",
            lineHeight: "1.4",
          }}
        >
          {item.title}
        </h6>
        {!item.icon && (
          <>
            <br />
          </>
        )}
        <p
          style={{
            color: textColor2,
            fontSize: "19px",
            fontWeight: "500",
          }}
        >
          {item.content}
        </p>
      </div>
    </div>
  );
};

export default Services4;

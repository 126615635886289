import React, { useState, useEffect } from "react";

const Video2 = ({
  videoSrc,
  heading,
  subheading,
  color,
  textColor,
  isHomePage,
}) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    console.clear();
  }, []);

  const videoStyle = {
    width: "100%",
    height: "100%",
  };

  const sectionStyle = {
    // minHeight: "80vh",
    marginBottom: "40px",
  };

  const videoType = "video/mp4";

  return (
    <section
      style={{ backgroundColor: color ? color : "#1b1d21", padding: "0" }}
      className="secreen-shots section-padding"
    >
      {isHomePage != true ? (
        <div style={{ padding: "120px 0" }} className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-10">
              <div className="sec-head text-center nomobile">
                <h6
                  style={{
                    fontFamily: "machina",
                    color: "#FF6690",
                    textTransform: "lowercase",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                >
                  {heading ? `{ ${heading} }` : "{ test }"}
                </h6>
                <h3
                  style={{ fontFamily: "machina bold", color: textColor }}
                  className="wow "
                >
                  {subheading ? subheading : "test2"}
                </h3>
              </div>
              <div
                style={{ marginBottom: "80px" }}
                className="sec-head text-center nodesktop"
              >
                <h6
                  style={{
                    fontFamily: "machina",
                    color: "#FF6690",
                    textTransform: "lowercase",
                    fontSize: "20px",
                    fontWeight: "500",
                  }}
                  className="wow fadeIn"
                  data-wow-delay=".5s"
                >
                  {heading ? `{ ${heading} }` : "{ test }"}
                </h6>
                <h3
                  style={{ fontFamily: "machina bold", color: "#4a4a4a" }}
                  className="wow "
                >
                  {subheading ? subheading : "test2"}
                </h3>
              </div>
            </div>
          </div>

          <section
            style={sectionStyle}
            className="circle-bg parallaxie nomobile"
          >
            <div className="video-modal">
              <video style={videoStyle} controls autoPlay muted loop>
                <source src={videoSrc} type={videoType} />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
          <section className="circle-bg parallaxie nodesktop">
            <div className="video-modal">
              <video style={videoStyle} controls autoPlay muted loop>
                <source src={videoSrc} type={videoType} />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
        </div>
      ) : (
        <>
          <section
            style={sectionStyle}
            className="circle-bg parallaxie nomobile"
          >
            <div className="video-modal">
              <video style={videoStyle} controls autoPlay muted loop>
                <source src={videoSrc} type={videoType} />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
          <section className="circle-bg parallaxie nodesktop">
            <div className="video-modal">
              <video style={videoStyle} controls autoPlay muted loop>
                <source src={videoSrc} type={videoType} />
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
        </>
      )}
    </section>
  );
};

export default Video2;

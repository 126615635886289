import React, { useState, useEffect, useRef } from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import VideoCarousel from "../../Components/carousel/videocarousel";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import { urban } from "../../../data/case-studies/data";
import ProjectIntro from "../../Components/case-studies/project-intro";
import HomeServices4 from "../../Components/Services4/product-design2";
import MobileCarousel from "../../Components/carousel/mobileCarousel";
const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const gallery = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/01.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/02.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/03.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/04.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/05.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/06.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/07.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/08.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/09.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/10.png",
    },
  ];
  const gallery2 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/1.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/2.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/3.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/4.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/5.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/6.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/7.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/8.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/9.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/10.png",
    },
  ];
  const videoGallery = [
    {
      alt: "01",
      src: "/videos/case-studies/URBAN HALAL/uh-discovery.mp4",
    },
    {
      alt: "02",
      src: "/videos/case-studies/URBAN HALAL/uh-delivery.mp4",
    },
  ];

  const team = [
    "3 Design",
    <br key="team-br1" />,
    "2 PM",
    <br key="team-br2" />,
    "2 Director",
  ];

  const products = [
    "Accessible Design System",
    <br key="team-br1" />,
    "iOS TV Player",
    <br key="team-br2" />,
    "Amazon FireTV",
    <br key="team-br3" />,
    "SamsungTV",
    <br key="team-br4" />,
    "GoogleTV",
    <br key="team-br5" />,
    "Remote Hardware",
  ];

  const tools = [
    "Paper and Pencil",
    <br key="skills-br1" />,
    "Sketch",
    <br key="skills-br2" />,
    "Adobe CC",
    <br key="skills-br6" />,
    "Lottie",
  ];

  const skills = [
    "  User Research",
    <br key="skills-br1" />,
    "Information Architecture",
    <br key="skills-br2" />,
    "Wireframe / Prototyping",
    <br key="skills-br3" />,
    "Visual / Interaction Design",
    <br key="skills-br4" />,
    "Design System",
    <br key="skills-br5" />,
    "Iconography / Illustrations",
    <br key="skills-br6" />,
    "Motion Graphics",
  ];

  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/1.gif",
      title: "WHAT",
      content: [
        "Decreased Revenue",
        <br key={`br-3-1`} />,
        "Increased Costs",
        <br key={`br-3-2`} />,
        "High Support Cases",
        <br key={`br-3-3`} />,
        "Design Debt",
        <br key={`br-3-4`} />,
        "Technical Debt",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/2.gif",
      title: "WHO",
      content: [
        "Design Silos",
        <br key="br1-1" />,
        "Distributed Teams",
        <br key="br1-2" />,
        "Lack of Governance",
        <br key="br1-3" />,
        "Lack of Resources",
        <br key="br1-4" />,
        "Major Backlogs",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/3.gif",
      title: "WTF",
      content: [
        "Sub-par User Experience",
        <br key="br2-1" />,
        "Fragmented Channels",
        <br key="br2-2" />,
        "Manual Processes",
        <br key="br2-3" />,
        "Lower Productivity",
        <br key="br2-4" />,
        "Missed Opportunities",
      ],
    },
  ];

  const videoMobileGallery = [
    {
      type: "img",
      alt: "01",
      src: "/assets/urban-halal/uh-1.png",
    },
    {
      type: "img",
      alt: "02",
      src: "/assets/urban-halal/uh-2.png",
    },
    {
      type: "img",
      alt: "03",
      src: "/assets/urban-halal/uh-3.png",
    },
    {
      type: "img",
      alt: "04",
      src: "/assets/urban-halal/uh-4.png",
    },
    {
      type: "img",
      alt: "05",
      src: "/assets/urban-halal/uh-5.png",
    },
    {
      type: "img",
      alt: "06",
      src: "/assets/urban-halal/uh-6.png",
    },
    {
      type: "img",
      alt: "07",
      src: "/assets/urban-halal/uh-7.png",
    },
    {
      type: "img",
      alt: "08",
      src: "/assets/urban-halal/uh-8.png",
    },
    {
      type: "img",
      alt: "09",
      src: "/assets/urban-halal/uh-9.png",
    },
    {
      type: "img",
      alt: "10",
      src: "/assets/urban-halal/uh-10.png",
    },
    {
      type: "img",
      alt: "23",
      src: "/assets/urban-halal/uh-23.png",
    },
    {
      type: "img",
      alt: "24",
      src: "/assets/urban-halal/uh-24.png",
    },
    {
      type: "img",
      alt: "11",
      src: "/assets/urban-halal/uh-11.png",
    },
    {
      type: "img",
      alt: "12",
      src: "/assets/urban-halal/uh-12.png",
    },
    {
      type: "img",
      alt: "13",
      src: "/assets/urban-halal/uh-13.png",
    },
    {
      type: "img",
      alt: "14",
      src: "/assets/urban-halal/uh-14.png",
    },
    {
      type: "img",
      alt: "15",
      src: "/assets/urban-halal/uh-15.png",
    },
    {
      type: "img",
      alt: "16",
      src: "/assets/urban-halal/uh-16.png",
    },
    {
      type: "img",
      alt: "17",
      src: "/assets/urban-halal/uh-17.png",
    },
    {
      type: "img",
      alt: "18",
      src: "/assets/urban-halal/uh-18.png",
    },
    {
      type: "img",
      alt: "19",
      src: "/assets/urban-halal/uh-19.png",
    },
    {
      type: "img",
      alt: "20",
      src: "/assets/urban-halal/uh-20.png",
    },
    {
      type: "img",
      alt: "21",
      src: "/assets/urban-halal/uh-21.png",
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "300%",
      content: ["Higher Cart Sizes Than 3PP"],
    },
    {
      id: 1,

      title: "$270.73",
      content: ["Customer Quarterly Spend"],
    },
    {
      id: 2,

      title: "$940.93",
      content: ["Customer LTV Rolling GMV"],
    },
    {
      id: 3,

      title: "$58.42",
      content: ["Average Ticket Regular Orde"],
    },
    {
      id: 1,

      title: "$79.22",
      content: ["Average Ticket DriveThru Orde"],
    },
    {
      id: 2,

      title: "6.3",
      content: ["Customer Order Frequency"],
    },
    {
      id: 3,

      title: "$0.00",
      content: ["Customer Acquisition Cost"],
    },
    {
      id: 1,

      title: "63-78%",
      content: ["Profit Margin"],
    },
    {
      id: 2,

      title: "35",
      content: ["Average Delivery Minutes"],
    },
  ];
  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];
  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#1a1a1a"}
          projectHeaderData={ProjectDate}
          headerVideo={"/videos/case-studies/URBAN HALAL/urban-halal.mp4"}
          company="UBRAN HALAL, INC."
          role="Founder / Product"
          tenure="2018 - 2023"
          team={team}
          products={products}
          skills={skills}
          tools={tools}
          mode={mode}
        />
        {/* <Video2 link="/videos/case-studies/HALAL FOODS/uh-discovery.mp4"/> */}
        {/* <HomeServices4 data={threeBoxData} /> */}
        <HomeServices4
          header="{ roi }"
          subheader="Work Impact"
          data={threeBoxData3}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
        />
        <MobileCarousel
          heading="DELIVERABLES"
          subheading="Mobile App Designs"
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          items={videoMobileGallery}
          color={mode == true ? "#252532" : "#f4f4f4"}
        />
        {/* Three Column Vertical Videos */}
        <div
          style={{
            padding: "120px 0",
            backgroundColor: mode ? "black" : "white",
          }}
        >
          <h6
            style={{
              textAlign: "center",
              fontFamily: "machina",
              color: "#FF6690",
              textTransform: "lowercase",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            {`{ DELIVERABLES }`}
          </h6>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "machina bold",
              color: mode ? "#a4a7b1" : "#1a1a1a",
              marginBottom: "80px",
              fontSize: "48px",
            }}
          >
            Product Prototypes
          </h3>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            {[
              "/videos/urban-halal-10.mp4",
              "/videos/urban-halal-11.mp4",
              "/videos/urban-halal-12.mp4",
            ].map((videoSrc, index) => (
              <div key={index} style={{ width: "30%", maxWidth: "300px" }}>
                <video
                  src={videoSrc}
                  controls
                  style={{ width: "100%", aspectRatio: "9/16" }}
                />
              </div>
            ))}
          </div>
        </div>
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="POS + Hardware Design"
          videoSrc="/videos/case-studies/URBAN HALAL/urban-halal-3.mp4"
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/URBAN HALAL/urban-halal-2.mp4"
        />
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Iconography"
          videoSrc="/videos/case-studies/URBAN HALAL/urban-halal-1.mp4"
        />
        <VideoCarousel
          mode={mode}
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#f4f4f4"}
          items={videoGallery}
        />
        {/* <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/HALAL FOODS/uh-delivery.mp4"
        /> */}

        {/* <HomeServices4
          header="{ recognition }"
          subheader="Industry Honors"
          data={threeBoxData2}
          color={mode == true ? "#1b1d21" : "white"}
          cardColor={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "black"}
          textColor2={mode == true ? "#a4a7b1" : "#1a1a1a"}
          cardHeight="530px"
        /> */}
        {/* <Video videoSrc="/videos/javed.io-demo.mp4" /> */}
        {/* <Video2 link="/videos/case-studies/HALAL FOODS/uh-delivery.mp4" /> */}

        {/* <Screenshots
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          color={mode == true ? "#1b1d21" : "#dadada"}
          heading="DELIVERABLES"
          subheading="Last-Mile Delivery Marketplace"
          gallery={gallery}
          size="1000px"
        /> */}
        {/*   <Screenshots
          color="#0a0a0a"
          heading="DELIVERABLES"
          subheading="New tvOS player Voice UI Wireframes"
          gallery={gallery2}
          size="1000px"
        /> */}

        {/* <MobileCarousel
          heading="Campaigns"
          subheading="Campaigns"
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          items={videoMobileGallery}
          color={mode == true ? "#1b1d21" : "#dadada"}
        />
        <MobileCarousel
          heading="Campaigns"
          subheading="Campaigns"
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          items={videoMobileGallery}
          color={mode == true ? "#252532" : "#eaeaea"}
        />
        <MobileCarousel
          heading="Campaigns"
          subheading="Campaigns"
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          items={videoMobileGallery}
          color={mode == true ? "#1b1d21" : "#dadada"}
        />
        <MobileCarousel
          heading="Campaigns"
          subheading="Campaigns"
          textColor={mode == true ? "#a4a7b1" : "#4a4a4a"}
          items={videoMobileGallery}
          color={mode == true ? "#252532" : "#eaeaea"}
        /> */}

        {/* <NextProject /> */}
        {urban.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#1b1d21" : "white"}
            color2={mode == true ? "#252532" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            spanStyle={spanStyle}
            image={item.image}
            image2={item.image2 ? item.image2 : null}
            video={item.video}
            imageCaption={item.caption}
          />
        ))}
        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

import React from "react";
import Video from "../../Components/Video2/video";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import ProjectDetails2Header from "../../Components/Project-details2-header/project-details2-header";
import ProjectDate from "../../../data/project-details2.json";
import ProjectIntro from "../../Components/case-studies/project-intro";
import Screenshots from "../../Components/Screenshots/screenshots";
import Carousel from "../../Components/carousel/carousel";
import VideoCarousel from "../../Components/carousel/videocarousel";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import HomeServices4 from "../../Components/Services4/product-design2";
import MobileCarousel from "../../Components/carousel/mobileCarousel";
import { serpapiData } from "../../../data/case-studies/data";

const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const spanStyle = {
    fontSize: "17px",
    fontWeight: 500,
    color: "#00CDFF",
    marginRight: "5px",
    marginTop: "-5%",
  };

  const gallery = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/01.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/02.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/03.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/04.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/05.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/06.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/07.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/08.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/09.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/1/10.png",
    },
  ];
  const gallery2 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/1.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/2.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/3.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/4.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/5.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/6.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/7.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/8.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/9.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/2/10.png",
    },
  ];
  const gallery3 = [
    {
      alt: "01",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/001.png",
    },
    {
      alt: "02",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/002.png",
    },
    {
      alt: "03",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/003.png",
    },
    {
      alt: "04",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/004.png",
    },
    {
      alt: "05",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/005.png",
    },
    {
      alt: "06",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/007.png",
    },
    {
      alt: "07",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/008.png",
    },
    {
      alt: "08",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/009.png",
    },
    {
      alt: "09",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/0091.png",
    },
    {
      alt: "10",
      src: "/assets/case-studies/case-studies/crunchy-roll/gallery/3/0092.png",
    },
  ];

  const videoGallery = [
    {
      alt: "01",
      src: "/videos/case-studies/LUNCHBOX/lunchbox.mp4",
    },
    {
      alt: "02",
      src: "/videos/case-studies/LUNCHBOX/lunchbox1.mp4",
    },
    {
      alt: "03",
      src: "/videos/case-studies/LUNCHBOX/lunchbox2.mp4",
    },
    {
      alt: "04",
      src: "/videos/case-studies/LUNCHBOX/lunchbox3.mp4",
    },
    {
      alt: "05",
      src: "/videos/case-studies/LUNCHBOX/lunchobx web.mp4",
    },
  ];
  const team = ["1 Founder", <br key="team-br1" />, "1 Design Manager"];

  const skills = [
    "Research",
    <br key="team-br1" />,
    "UX Design",
    <br key="team-br2" />,
    "Visual Design",
    <br key="team-br3" />,
    "Prototyping",
    <br key="team-br4" />,
    "Illustrations",
    <br key="team-br5" />,
    "Motion Design",
    <br key="team-br6" />,
    "Branding",
  ];
  const threeBoxData = [
    {
      id: 3,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/1.gif",
      title: "WHAT",
      content: [
        "Decreased Revenue",
        <br key={`br-3-1`} />,
        "Increased Costs",
        <br key={`br-3-2`} />,
        "High Support Cases",
        <br key={`br-3-3`} />,
        "Design Debt",
        <br key={`br-3-4`} />,
        "Technical Debt",
      ],
    },
    {
      id: 1,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/2.gif",
      title: "WHO",
      content: [
        "Design Silos",
        <br key="br1-1" />,
        "Distributed Teams",
        <br key="br1-2" />,
        "Lack of Governance",
        <br key="br1-3" />,
        "Lack of Resources",
        <br key="br1-4" />,
        "Major Backlogs",
      ],
    },
    {
      id: 2,
      icon: "/assets/case-studies/case-studies/VMWARE/img-row/3.gif",
      title: "WTF",
      content: [
        "Sub-par User Experience",
        <br key="br2-1" />,
        "Fragmented Channels",
        <br key="br2-2" />,
        "Manual Processes",
        <br key="br2-3" />,
        "Lower Productivity",
        <br key="br2-4" />,
        "Missed Opportunities",
      ],
    },
  ];

  const tools = [
    "Figma",
    <br key="tools-br1" />,
    "Adobe CC",
    <br key="tools-br2" />,
    "Lottie",
  ];

  const videoMobileGallery = [
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
    {
      type: "video",
      alt: "01",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll-wall.mp4",
    },
    {
      type: "video",
      alt: "02",
      src: "/videos/case-studies/CRUNCHY ROLL/crunchyroll1.mp4",
    },
  ];

  const threeBoxData2 = [
    {
      id: 3,

      title: "Best Portal Experience",
      content: [
        "Beat Microsoft to take #1 spot in ease of doing business, managing channel conflict, field and pre-sales support, increasing partner satisfaction and C-SAT score.",
      ],
    },
    {
      id: 1,

      title: "Best Innovative Team",
      content: [
        "Created a brand new, role-based, task-oriented user onboarding experience and self-service path to reduce business transaction time by 57 days.",
      ],
    },
    {
      id: 2,

      title: "Five Star of Excellence",
      content: [
        "Revamped VMware Partner Network with a task-oriented, responsive redesign to improve usability, information architecture and access to content while supporting a transactional framework.",
      ],
    },
  ];

  const threeBoxData3 = [
    {
      id: 3,

      title: "80%",
      content: ["Increased CSAT score"],
    },
    {
      id: 1,

      title: "50%",
      content: ["Increased Q/Q sales"],
    },
    {
      id: 2,

      title: "57 days",
      content: ["Decreased time to transact"],
    },
  ];
  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <div
        s
        style={{
          backgroundColor: mode == true ? "#dcdcdc" : "#dcdcdc",
          overflowX: "hidden",
        }}
        className="wrapper"
      >
        <ProjectDetails2Header
          color={mode == true ? "#252532" : "#f4f4f4"}
          textColor={mode == true ? "white" : "#1a1a1a"}
          projectHeaderData={ProjectDate}
          headerVideo={
            "https://www.javed.io/videos/case-studies/SERAPI%20WALL/serapi.mp4"
          }
          company="SERP API"
          role="Creative Director"
          tenure="2023"
          team={team}
          products="Status Page"
          skills={skills}
          tools={tools}
          mode={mode}
        />
        {/* <Video2 link="https://www.javed.io/videos/case-studies/SERAPI%20WALL/serapi%20wall.mp4"/> */}
        {/* <HomeServices4 data={threeBoxData} /> */}

        <div style={{ margin: "120px 0" }}>
          <iframe
            className="nomobile"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              width: "83.5%",
              height: "1090px",
              display: "block", // Added to make the iframe a block-level element
              marginLeft: "auto", // Added to center the iframe horizontally
              marginRight: "auto", // Added to center the iframe horizontally
            }}
            src="https://www.figma.com/proto/ZWN6tcMiZoaZ9m1BM85RbB/SerpApi-Status-Page-Redesign?embed_host=share&kind=proto&node-id=70-10385&page-id=0%3A1&starting-point-node-id=1%3A6769&t=taR1duwBuvWNtOhN-1"
            allowFullScreen
          />
          <iframe
            className="nodesktop"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              width: "83.5%",
              height: "1090px",
              display: "block", // Added to make the iframe a block-level element
              marginLeft: "auto", // Added to center the iframe horizontally
              marginRight: "auto", // Added to center the iframe horizontally
            }}
            src="https://www.figma.com/proto/ZWN6tcMiZoaZ9m1BM85RbB/SerpApi-Status-Page-Redesign?embed_host=share&kind=proto&node-id=70-10385&page-id=0%3A1&starting-point-node-id=1%3A6769&t=taR1duwBuvWNtOhN-1"
            allowFullScreen
          />
        </div>
        {serpapiData.map((item, index) => (
          <ProjectIntro
            color={mode == true ? "#1b1d21" : "white"}
            color2={mode == true ? "#252532" : "white"}
            textColor={mode == true ? "white" : "black"}
            key={index}
            projectIntroductionData={item.intro}
            video={item.video}
            spanStyle={spanStyle}
            image={item.image}
            imageCaption={item.caption}
          />
        ))}
        <Video
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          heading="DELIVERABLES"
          subheading="Motion Graphics"
          videoSrc="/videos/case-studies/SERAPI WALL/serapi wall.mp4"
        />

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

import React from "react";
import Navbar from "../../Components/Navbar/navbar";
import Footer from "../../Components/Footer/footer";
import DarkTheme from "../../layouts/Dark";
import CallToAction from "../../Components/Call-to-action/call-to-action";
import PageHeader from "../../Components/Page-header/page-header";
import Cursor from "../../Components/cursor";

const buttonStyles = {
  position: "relative",
  zIndex: 1,
  fontFamily: "montserrat !important",
  appearance: "none",
  border: "none",
  background: "linear-gradient(to right, #FF6690,  #00CDFF)",
  outline: "none",
  cursor: "pointer",
  display: "inline-block",
  textDecoration: "none",
  padding: "15px 50px",
  color: "#ffffff",
  fontWeight: "bold",
  textAlign: "center",
  fontSize: "14px",
  transition: "all 0.3s ease-in-out",
  borderRadius: "50px",

  lineHeight: "normal",
  textTransform: "uppercase",
  animation: "pulseanime 3s infinite",
  boxShadow: "0 0 0 rgba(229, 71, 80, 0.4)",
  "@media (max-width: 768px)": {
    padding: "10px",
    fontSize: "14px",
  },
  marginTop: "10px",
  height: "50px",
};

const ProjectDetails2Dark = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = React.useState(false);

  const handleTheme = React.useCallback((data) => {
    setMode(data);
  }, []);

  React.useEffect(() => {
    const handleScroll = () => {
      const navbar = navbarRef.current;
      if (!navbar) return;

      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    };

    handleScroll(); // Initial check
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [navbarRef]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DarkTheme>
      <Cursor />

      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} theme={mode} />
      <div
        style={{
          backgroundColor: mode ? "#0a0a0a" : "#f4f4f4",
          color: mode ? "#fff" : "#1a1a1a",
          overflowX: "hidden",
          position: "relative",
        }}
        className="wrapper"
      >
        <PageHeader
          title="INTRO DECK"
          paragraph="Over 20 years of experience building human-centered products and leading high-performing design and engineering teams"
          theme={mode}
        />
        <br />
        <br />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {/* Direct PDF embed */}
              <object
                data="/assets/2024-javed-deck.pdf"
                type="application/pdf"
                style={{
                  width: "100%",
                  height: "800px",
                  border: "1px solid rgba(255,255,255,0.1)",
                  borderRadius: "8px",
                  marginBottom: "50px",
                }}
              >
                <div className="text-center mb-50">
                  <p style={{ color: "white", marginBottom: "20px" }}>
                    PDF viewer not available. Click below to download.
                  </p>
                  <a href="/assets/2024-javed-deck.pdf" download>
                    <span
                      className="nav-link  pulse_bt cursor-pointer"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        ...buttonStyles,
                        // Mobile-specific adjustments
                        padding: "7px 20px",
                        fontSize: "12px",
                        width: "auto",
                        marginRight: "20px !important",
                        height: "32px",
                      }}
                    >
                      Download Deck
                    </span>
                  </a>
                </div>
              </object>

              {/* Always show download button */}
              <div className="text-center mb-50">
                <a href="/assets/2024-javed-deck.pdf" download>
                  <span
                    className="nav-link  pulse_bt cursor-pointer"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={buttonStyles}
                  >
                    Download Deck
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <CallToAction />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export default ProjectDetails2Dark;

import Navbar from "../Components/Navbar/navbar";
import React, { useState, useRef, useEffect } from "react";
import DarkTheme from "../layouts/Dark";
import VideoCarousel from "../Components/carousel/videocarousel";
import Lottie from "react-lottie-player";
import javed from "../../data/team/javed.json";
import sapna from "../../data/team/sapna.json";
import isa from "../../data/team/isa.json";
import awadhesh from "../../data/team/awadhesh.json";
import oz from "../../data/team/oz.json";
import firdaus from "../../data/team/firdaus.json";
import arun from "../../data/team/arun.json";
import akash from "../../data/team/akash.json";
import yulia from "../../data/team/yulia.json";
import saquib from "../../data/team/saquib.json";
import amaan from "../../data/team/amaan.json";
import rumi from "../../data/team/rumi.json";
import ProjectDetails2Header from "../Components/Project-details2-header/project-details2-header.jsx";
import { LottieData } from "../../data/case-studies/videodata";
import ProjectIntroduction from "../Components/Project-introduction/project-introduction";

import CallToAction from "../Components/Call-to-action/call-to-action";
import Footer from "../Components/Footer/footer";
// gallery image

import sativa from "../../data/new json/16_Business_meeting 4 square.json";
import indica from "../../data/new json/Content Marketing.json";
import cbd from "../../data/new json/Generative AI.json";
import happy from "../../data/new json/Product Design.json";
import hungry from "../../data/new json/Software Development.json";
import stoned from "../../data/new json/Web 3.0.json";
import energetic from "../../data/new json/World-class Talent.json";
import { Grid } from "@mui/material";
// import giggly from "../data/new json/Zero To One.json";

export const emojiLinks = [
  "https://xdold.app.muslamix.app/images/sativa-loop.gif",
  "https://xdold.app.muslamix.app/images/indica-loop.gif",
  "https://xdold.app.muslamix.app/images/cbd-loop.gif",
  "https://xdold.app.muslamix.app/images/happy-loop.gif",
  "https://xdold.app.muslamix.app/images/hungry-loop.gif",
  "https://xdold.app.muslamix.app/images/stoned-loop.gif",
  "https://xdold.app.muslamix.app/images/sleepy-loop.gif",
  "https://xdold.app.muslamix.app/images/shit-loop.gif",
  "https://xdold.app.muslamix.app/images/energetic-loop.gif",
  "https://xdold.app.muslamix.app/images/giggly-loop.gif",
  "https://xdold.app.muslamix.app/images/focused-loop.gif",
  "https://xdold.app.muslamix.app/images/creative-loop.gif",
  "https://xdold.app.muslamix.app/images/forgetful-loop.gif",
  "https://xdold.app.muslamix.app/images/euphoric-loop.gif",
  "https://xdold.app.muslamix.app/images/aroused-loop.gif",
  "https://xdold.app.muslamix.app/images/blow-kiss-loop.gif",
  "https://xdold.app.muslamix.app/images/relaxed-loop.gif",
  "https://xdold.app.muslamix.app/images/talkative-loop.gif",
  "https://xdold.app.muslamix.app/images/scream-loop.gif",
  "https://xdold.app.muslamix.app/images/sweating-loop.gif",
  "https://xdold.app.muslamix.app/images/tingly-loop.gif",
  "https://xdold.app.muslamix.app/images/uplifting-loop.gif",
  "https://xdold.app.muslamix.app/images/shh-loop.gif",
  "https://xdold.app.muslamix.app/images/zipped-loop.gif",
];

const VideoPage2 = () => {
  const navbarRef = useRef(null);
  const logoRef = useRef(null);
  const [mode, setMode] = useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  useEffect(() => {
    const navbar = navbarRef.current;
    const handleScroll = () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    // Add this inside the useEffect
    const loadAdobeSDK = () => {
      document.addEventListener("adobe_dc_view_sdk.ready", function () {
        console.log("AdobeDC View SDK is ready");
        var adobeDCView = new window.AdobeDC.View({
          clientId: "89d082c22e3d4dabaab43f87fe1a238f",
          divId: "adobe-dc-view",
        });
        adobeDCView
          .previewFile(
            {
              content: {
                location: { url: "/downloads/2024-javed-cv.pdf" },
              },
              metaData: { fileName: "2024-javed-cv" },
            },
            { embedMode: "SIZED_CONTAINER" }
          )
          .then(() => {
            console.log("PDF preview successful");
          })
          .catch((error) => {
            console.error("Error displaying PDF", error);
          });
      });
    };

    if (!document.getElementById("adobe-dc-view-sdk")) {
      const script = document.createElement("script");
      script.src = "https://acrobatservices.adobe.com/view-sdk/viewer.js";
      script.id = "adobe-dc-view-sdk";
      script.onload = loadAdobeSDK;
      document.body.appendChild(script);
    } else {
      loadAdobeSDK();
    }

    return () => window.removeEventListener("scroll", handleScroll);
  }, [navbarRef]);

  // Sample video URLs (replace with your actual URLs)
  const sampleVideoUrl = "https://www.example.com/sample-video.mp4";
  const youtubeUrl = "https://www.youtube.com/embed/dQw4w9WgXcQ";

  const lottieAnimations = [
    javed,
    sapna,
    isa,
    rumi,

    oz,
    firdaus,
    arun,
    akash,
    yulia,
    saquib,
    amaan,
    awadhesh,
  ];

  // Add this array of JSON animations
  const gridAnimations = [
    sativa,
    happy,
    energetic,
    indica,
    hungry,
    stoned,
    cbd,
  ];

  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <ProjectDetails2Header
        projectHeaderData={{
          title: "Lottie Animations",
          content:
            "A showcase of various Lottie animations and interactive elements.",
        }}
        headerVideo={"/videos/demo2.mp4"}
        mode={mode}
        hideProjectDetails={true} // Add this line to hide the details section
      />

      <div
        style={{
          backgroundColor: mode ? "#1b1d21" : "#f4f4f4",
          minHeight: "100vh",
          margin: 0,

          overflowX: "hidden",
        }}
      >
        <ProjectIntroduction
          textColor={mode == true ? "white" : "black"}
          projectIntroductionData={LottieData[0].intro}
        />
      </div>
      {/* 3rd section: 3x3 grid of Lottie animations */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "black" : "white",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          UX Animations
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridTemplateRows: "repeat(3, 1fr)",
            gap: "20px",
            maxWidth: "900px",
            margin: "0 auto",
          }}
        >
          {gridAnimations.map((animation, index) => (
            <div
              key={index}
              style={{
                aspectRatio: "1/1",
                backgroundColor: mode ? "#1E1E2A" : "#FFFFFF",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease",
                cursor: "pointer",
                gridColumn: index === 6 ? "2" : "auto",
                gridRow: index === 6 ? "3" : "auto",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.05)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              <Lottie
                loop
                animationData={animation}
                play
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* 4th section: 3x4 Lottie animation grid */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "#252532" : "#F4F4F4",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Emotive Expressions
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {lottieAnimations.map((animation, index) => (
            <div
              key={index}
              style={{
                backgroundColor: mode ? "#1E1E2A" : "#FFFFFF",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.3s ease",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
              }}
            >
              <div style={{ aspectRatio: "1/1" }}>
                <Lottie
                  loop
                  animationData={animation}
                  play
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* 1st 2nd section: video */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "black" : "white",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Brand Animations
        </h3>
        <div
          style={{
            width: "100vw",
            margin: "0 -50vw",
            left: "50%",
            position: "relative",
          }}
        >
          <video
            src="https://www.javed.io/videos/case-studies/CRUNCHY%20ROLL/crunchyroll-wall.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      {/* Feeling Blessed */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode == true ? "#252532" : "#f4f4f4",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Multicultural Inclusion
        </h3>
        <div
          style={{
            width: "100vw",
            margin: "0 -50vw",
            left: "50%",
            position: "relative",
          }}
        >
          <video
            src="https://www.javed.io/videos/case-studies/FEELING%20BLESSED/feeling-blessed-1.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      {/* 1st 5th section: video */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "black" : "white",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Niche Illustrations
        </h3>
        <div
          style={{
            width: "100vw",
            margin: "0 -50vw",
            left: "50%",
            position: "relative",
          }}
        >
          <video
            src="https://www.javed.io/videos/case-studies/CLOMR/clomr-2.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      {/* 1st 4thsection: video */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode == true ? "#252532" : "#f4f4f4",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Custom Animations
        </h3>
        <div
          style={{
            width: "100vw",
            margin: "0 -50vw",
            left: "50%",
            position: "relative",
          }}
        >
          <video
            src="https://www.javed.io/videos/case-studies/URBAN%20HALAL/urban-halal-2.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>
      {/* 1st 3rd section: video */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "black" : "white",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Micro Interactions
        </h3>
        <div
          style={{
            width: "100vw",
            margin: "0 -50vw",
            left: "50%",
            position: "relative",
          }}
        >
          <video
            src="https://www.javed.io/videos/case-studies/URBAN%20HALAL/urban-halal-1.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      </div>

      {/* 2nd section: 4x6 grid of emoji GIFs */}
      <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "#252532" : "#F4F4F4",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          Animated Emojis
        </h3>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(6, 1fr)",
            gridTemplateRows: "repeat(4, 1fr)",
            gap: "20px",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          {emojiLinks.slice(0, 24).map((link, index) => (
            <div
              key={index}
              style={{
                aspectRatio: "1/1",
                position: "relative",
                backgroundColor: mode ? "#1E1E2A" : "#FFFFFF",
                borderRadius: "12px",
                overflow: "hidden",
                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                cursor: "pointer",
                width: "calc(100% + 10px)", // Increased width
                height: "calc(100% + 10px)", // Increased height
                margin: "-5px", // Negative margin to center the increased size
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "translateY(-5px)";
                e.currentTarget.style.boxShadow =
                  "0 8px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "translateY(0)";
                e.currentTarget.style.boxShadow =
                  "0 6px 8px rgba(0, 0, 0, 0.15)";
              }}
            >
              <img
                src={link}
                alt={`Emoji ${index + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  padding: "5px", // Added padding to ensure the emoji doesn't touch the border
                }}
              />
            </div>
          ))}
        </div>
      </div>

      {/* <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "#252532" : "#F4F4F4",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          National Research
        </h3>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: "80%",
              maxWidth: "800px",
              height: "600px",
              border: "1px solid #ccc",
              borderRadius: "8px",
              overflow: "hidden",
            }}
          >
            <iframe
              src="https://static.ca.cair.com/annual-legal-report/downloads/cair-annual-legal-report-final.pdf"
              width="100%"
              height="100%"
              style={{ border: "none" }}
            ></iframe>
          </div>
        </div>
        <p
          style={{
            marginTop: "20px",
            textAlign: "center",
            fontFamily: "machina",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            maxWidth: "600px",
            margin: "20px auto 0",
          }}
        >
          This is a preview of the CAIR-CA Bullying Report 2021. For a full view
          or to download, please use the PDF viewer above.
        </p>
      </div> */}

      {/* 3x3 pdf grid   */}
      {/* <div
        style={{
          padding: "120px 0",
          backgroundColor: mode ? "black" : "white",
        }}
      >
        <h6
          style={{
            textAlign: "center",
            fontFamily: "machina",
            color: "#FF6690",
            textTransform: "lowercase",
            fontSize: "20px",
            fontWeight: "500",
            marginBottom: "10px",
          }}
        >
          {`{ DELIVERABLES }`}
        </h6>
        <h3
          style={{
            textAlign: "center",
            fontFamily: "machina bold",
            color: mode ? "#a4a7b1" : "#1a1a1a",
            marginBottom: "40px",
            fontSize: "48px !important",
            marginBottom: "80px",
          }}
        >
          National Study
        </h3>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "80%",
                  maxWidth: "800px",
                  height: "600px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <iframe
                  src="https://static.ca.cair.com/reports/islamophobia/downloads/cair-ca-bullying-report-2021.pdf"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                ></iframe>
              </div>
            </div>

            {/* </div> 
          </Grid>
          <Grid item xs={12} md={4}>
            <SquareVideo
              // textColor={mode == true ? "#a4a7b1" : "#000000"}
              // color={mode == true ? "#1b1d21" : "#f4f4f4"}
              // heading="DELIVERABLES"
              // subheading="Motion Graphics"
              videoSrc="https://static.ca.cair.com/reports/islamophobia/video/cair-bullying-animated-graphic-novel.mp4"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "80%",
                  maxWidth: "800px",
                  height: "600px",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <iframe
                  src="https://static.ca.cair.com/reports/islamophobia/downloads/cair-ca-bullying-graphic-novel-2021.pdf"
                  width="100%"
                  height="100%"
                  style={{ border: "none" }}
                ></iframe>
              </div>
            </div>

          
          </Grid>
        </Grid>
      </div> */}
      <CallToAction />
      <Footer />
    </DarkTheme>
  );
};

export default VideoPage2;

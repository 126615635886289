import React, { useState, useRef, useEffect } from "react";
import Navbar from "../Components/Navbar/navbar";
import DarkTheme from "../layouts/Dark";
import {
  videoSrc,
  twoColumnVideos,
  threeColumnVideos,
  verticalVideoGallery,
} from "../../data/video";
import Video from "../Components/Video2/video";
import VideoCarousel from "../Components/carousel/videocarousel";
import ProjectDetails2Header from "../Components/Project-details2-header/project-details2-header";
import { VideoData } from "../../data/case-studies/videodata";
import ProjectIntroduction from "../Components/Project-introduction/project-introduction";
import CallToAction from "../Components/Call-to-action/call-to-action";
import Footer from "../Components/Footer/footer";
const VideoPage = () => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  const [mode, setMode] = useState(null);

  const handleTheme = (data) => {
    setMode(data);
  };

  React.useEffect(() => {
    var navbar = navbarRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  const isMobile = typeof window !== "undefined" && window.innerWidth <= 768;

  // Define the variables here
  const team = [
    "3 Designers",
    <br key="team-br1" />,
    "2 PMs",
    <br key="team-br2" />,
    "3 Directors",
    <br key="team-br3" />,
    "5 Engineering",
    <br key="team-br4" />,
    "2 Marketing",
    <br key="team-br5" />,
    "2 Project Managers",
  ];

  const products = [
    "Accessible Design System /",
    <br key="products-br1" />,
    "tvOS Player /",
    <br key="products-br2" />,
    "Amazon FireTV /",
    <br key="products-br3" />,
    "SamsungTV /",
    <br key="products-br4" />,
    "GoogleTV /",
    <br key="products-br5" />,
    "Remote Hardware",
  ];

  const skills = [
    "Research /",
    <br key="skills-br1" />,
    "Information Architecture /",
    <br key="skills-br2" />,
    "Wireframe / Prototyping /",
    <br key="skills-br3" />,
    "Visual Design /",
    <br key="skills-br4" />,
    "Interaction Design /",
    <br key="skills-br5" />,
    "Design Systems /",
    <br key="skills-br6" />,
    "Iconography / Illustrations /",
    <br key="skills-br7" />,
    "Motion Graphics",
  ];

  const tools = [
    "Paper and Pencil",
    <br key="tools-br1" />,
    "Sketch",
    <br key="tools-br2" />,
    "Adobe CC",
    <br key="tools-br3" />,
    "Abstract",
    <br key="tools-br4" />,
    "Zeplin",
    <br key="tools-br5" />,
    "Miro",
    <br key="tools-br6" />,
    "Lottie",
  ];
  const role = ["Senior UI /", <br key="role-br" />, "UX  Designer"];

  const videoGallery = [
    {
      alt: "01",
      src: "/videos/case-studies/URBAN HALAL/uh-discovery.mp4",
    },
    {
      alt: "02",
      src: "/videos/case-studies/URBAN HALAL/uh-delivery.mp4",
    },
  ];

  return (
    <DarkTheme>
      <Navbar dark={handleTheme} nr={navbarRef} lr={logoRef} />
      <ProjectDetails2Header
        projectHeaderData={{
          title: "Lottie Animations",
          content:
            "A showcase of various Lottie animations and interactive elements.",
        }}
        headerVideo={"/videos/demo2.mp4"}
        mode={mode}
        hideProjectDetails={true} // Add this line to hide the details section
      />

      <div
        style={{
          padding: "0 0 0 0",
          backgroundColor: mode == true ? "#252532" : "white",
        }}
      >
        <ProjectIntroduction
          // color={color}
          textColor={mode == true ? "white" : "black"}
          projectIntroductionData={VideoData[0].intro}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <div style={{ width: "100%" }}>
          <Video
            textColor={mode == true ? "#a4a7b1" : "#000000"}
            color={mode == true ? "#1b1d21" : "#eaeaea"}
            heading="DELIVERABLES"
            subheading="Product Explainer"
            videoSrc="/videos/atg.mp4"
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: mode ? "black" : "white",
          minHeight: "100vh",
          margin: "0px",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            padding: "120px 0",
            backgroundColor: mode ? "#252532" : "#F4F4F4",
          }}
        >
          <h6
            style={{
              textAlign: "center",
              fontFamily: "machina",
              color: "#FF6690",
              textTransform: "lowercase",
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            {`{ DELIVERABLES }`}
          </h6>
          <h3
            style={{
              textAlign: "center",
              fontFamily: "machina bold",
              color: mode ? "#a4a7b1" : "#1a1a1a",
              marginBottom: "80px",
              fontSize: "48px",
            }}
          >
            Product UI
          </h3>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            {[
              "/videos/urban-halal-10.mp4",
              "/videos/urban-halal-11.mp4",
              "/videos/urban-halal-12.mp4",
            ].map((videoSrc, index) => (
              <div key={index} style={{ width: "30%", maxWidth: "300px" }}>
                <video
                  src={videoSrc}
                  controls
                  style={{ width: "100%", aspectRatio: "9/16" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            // padding: "120px 0",
            backgroundColor: mode ? "#252532" : "#F4F4F4",
          }}
        >
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <div style={{ width: "100%" }}>
              <Video
                textColor={mode == true ? "#a4a7b1" : "#000000"}
                color={mode == true ? "#1b1d21" : "#eaeaea"}
                heading="DELIVERABLES"
                subheading="Product Concept"
                videoSrc="/videos/case-studies/URBAN HALAL/urban-halal-3.mp4"
              />
            </div>
          </div>
        </div>

        <VideoCarousel
          mode={mode}
          subheading="Product Marketing"
          bgcolor={mode == true ? "#252532" : "#F4F4F4"}
          textColor={mode == true ? "#a4a7b1" : "#000000"}
          color={mode == true ? "#1b1d21" : "#eaeaea"}
          items={videoGallery}
        />
      </div>
      <CallToAction />
      <Footer />
    </DarkTheme>
  );
};

export default VideoPage;
